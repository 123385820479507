import {VehicleFormDependenciesState} from "../index";
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {Catalog} from "../../../../../core-react/src/modules/api/utils/xts/city/modeling/catalog";
import {businesscustomerCityCatalogsSearch} from "../../../../../core-react/src/modules/api/utils/xts/city/postCatalogSearch";
import {currentCity} from "../../../../../configuration/cities/_city";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";

type RequestActionCreatorPayload = void;

const actions = newRequestActions<RequestActionCreatorPayload, VehicleFormDependenciesState, Pagination<Catalog<'energy'>>>(
    "/vehicle-form-dependencies/fetch-energies",
    payload => businesscustomerCityCatalogsSearch({
        city: currentCity().id,
        type: "energy"
    }),
    (action, state) => ({
        ...state,
        energies: {
            loading: true,
            data: []
        }
    }),
    (action, state) => ({
        ...state,
        energies: {
            loading: false,
            data: action.apiCallResult.data.items.map(item => ({
                label: item.datum,
                value: item.datum,
            }))
        }
    }),
    (action, state) => ({
        ...state,
        energies: {
            loading: false,
            data: [],
        }
    })
);

export const VehicleFormDependenciesFetchEnergiesRequestAction = actions.request;
export const VehicleFormDependenciesFetchEnergiesSuccessAction = actions.success;
export const VehicleFormDependenciesFetchEnergiesFailureAction = actions.failure;
