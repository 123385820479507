/**
 * Created by priano on 14/10/2020 at 14:00.
 */
import {useDispatch, useSelector} from "react-redux";
import {UiAccountEmailChange, AccountEmailChangeFormData} from "./index";

const useForm = () => {
    const dispatch = useDispatch();
    const state = useSelector(UiAccountEmailChange.selectors.state);

    return {
        changed: (payload: { field: keyof AccountEmailChangeFormData, value: any }) => dispatch(UiAccountEmailChange.actions.changed(payload)),
        submit: () => dispatch(UiAccountEmailChange.actions.submit()),
        reset: () => dispatch(UiAccountEmailChange.actions.reset()),
        ...state,
        data: state.dto,
    };
};

export default {
    useForm,
}
