/**
 * Created by priano on 14/08/2020 at 10:38.
 * Edited by Nicolas some day
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {apiPersonToPerson, Person} from "./modeling/person";
import {Address} from "./modeling/address";
import {CustomerTitleIdentifier} from "./modeling/customer-contracts-city";

const path = "/person";

type Payload = {
    lastname: string;
    firstname: string;
    gender: CustomerTitleIdentifier;
    email: string;
    landphone: string;
    mobilephone: string;
    contactChannel: string;
    language: string;
    city: string;
    accountIds: string[];
    householdId?: string;
    externalId?: string;
    organization?: string;
    organizationId?: string;

    // customer type date
    studentNumber?: string,
    internalAccommodation?: string,
    staffNumber?: string,
    abn?: string,
    workphone?: string,

    addressesAtCreation: Array<Address>,
};

export const customerContractsPostPerson = (payload: Payload): ApiCall<Person> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: apiPersonToPerson,
    tokenRepository: "xts-or-xts-account"
});
