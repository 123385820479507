import {coreConfiguration} from "../../../internal/coreConfiguration";
import jwt_decode from "jwt-decode";

const DATE_PREFIX = "_JEAN_$$DATE$$_NAEJ_";

const tokenValid = (exp: number) => {
    const now = Math.ceil((new Date()).valueOf() / 1000);

    return (exp || 0) > now;
}

export const retrieveState = () => {
    const rawStore = localStorage.getItem(coreConfiguration.persistance.localStorageKey);

    return rawStore ? JSON.parse(rawStore, (key, value) => {
        // Gestion expiration token
        if (key === 'token' && value) {
            const decodedToken: any = jwt_decode(value);

            // Si token existant et non expiré on le retourne
            if ((decodedToken && tokenValid(decodedToken.exp))) {
                return value;
            }

            // sinon on le vide
            return;
        }

        if (typeof value !== 'string') {
            return value;
        }

        if (value.indexOf(DATE_PREFIX) !== 0) {
            return value;
        }

        return new Date(value.replace(DATE_PREFIX, ''));
    }) : undefined;
};

export const saveState = (state: any) => {
    const wantedKeys = coreConfiguration.persistance.stateKeysToStore || [];
    const wantedState: any = {};

    Object.keys(state).forEach(key => {
        if (wantedKeys.indexOf(key) > -1) {
            wantedState[key] = state[key];
        }
    });

    localStorage.setItem(coreConfiguration.persistance.localStorageKey, JSON.stringify(wantedState, function (key: string, value: any) {
        return this[key] instanceof Date
            ? DATE_PREFIX + this[key].toJSON()
            : value;
    }));
};

export const removeSave = () => {
    localStorage.clear();
};
