import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {MailConfirmationState} from "../index";

type ActionPayload = {
    type: string,
}

type ActionCreatorPayload = void;

export const MailConfirmationArrivedAction = newAction<ActionPayload, MailConfirmationState, ActionCreatorPayload>({
    type: '/mail-confirmation/arrived',
    create: (type, payload) => ({
        type
    }),
    reducer: (action, state) => state,
});
