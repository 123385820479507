import {ApiCall, ApiCallResult} from "../../modules/api";

type ReduxAction = {
    type: string
}

type ActionConfig<ActionType, StateType, CreationArgumentsType> = {
    type: string,
    create?: (type: string, payload: CreationArgumentsType) => ActionType,
    reducer?: (action: ActionType, state: StateType) => StateType
};

type ActionConfigWithoutCreator<ActionType, StateType> = {
    type: string,
    reducer?: (action: ActionType, state: StateType) => StateType
};

export type Action<ActionType, StateType, CreationArgumentsType = undefined> = {
    type: string,
    create: CreationArgumentsType extends undefined ? undefined : ((payload: CreationArgumentsType) => ActionType),
    reducer: (action: ActionType, state: StateType) => StateType,
    (payload: CreationArgumentsType extends undefined ? never : CreationArgumentsType): ActionType,
};

export function newAction<ActionPayload, State = any, CreationPayload = undefined>(
    config: CreationPayload extends undefined
        ? ActionConfigWithoutCreator<ActionPayload, State>
        : ActionConfig<ActionPayload, State, CreationPayload>
): Action<ActionPayload & ReduxAction, State, CreationPayload> {
    const creator = (config as any).create === undefined ? undefined : ((creationArguments: CreationPayload) => {
        return (((config as any).create) as ((type: string, creationArguments: CreationPayload) => ActionPayload))(config.type, creationArguments);
    });

    const reducer = config.reducer === undefined ? ((action: ActionPayload, state: State) => state) : config.reducer;

    const result: any = {
        type: config.type,
        create: creator as (CreationPayload extends undefined ? undefined : ((creationArguments: CreationPayload) => ActionPayload)),
        reducer,
        toString: () => config.type
    };

    return Object.assign(
        creator === undefined ? (any: any) => {
            throw new Error(`Action ${config.type} has no creator defined`)
        } : creator,
        result
    );
}

export function actionInheritsCreationPayload<ActionType, CreationArgumentsType>() {
    return (type: string, payload: CreationArgumentsType): ActionType => {
        return ({
            type,
            ...payload
        }) as any as ActionType;
    }
}

type RequestActionPayload<Success> = { type: string, apiCall: ApiCall<Success> };
type SuccessActionPayload<Success> = { type: string, apiCallResult: ApiCallResult<Success> };
type FailureActionPayload<Success> = { type: string, apiCallResult: ApiCallResult<Success> };

export function newRequestActions<RequestActionCreatorPayload, State, SuccessCallType>(
    prefix: string,
    callBuilder: (payload: RequestActionCreatorPayload) => ApiCall<SuccessCallType>,
    requestReducer: (action: RequestActionPayload<SuccessCallType>, state: State) => State,
    successReducer: (action: SuccessActionPayload<SuccessCallType>, state: State) => State,
    failureReducer: (action: FailureActionPayload<SuccessCallType>, state: State) => State,
) {
    // @ts-ignore
    const request = newAction<RequestActionPayload<SuccessCallType>, State, RequestActionCreatorPayload>({
        type: prefix + '/request',
        create: (type, payload) => ({
            type, apiCall: callBuilder(payload)
        }),
        reducer: requestReducer
    });

    const success = newAction<SuccessActionPayload<SuccessCallType>, State>({
        type: prefix + '/success',
        reducer: successReducer
    });

    const failure = newAction<FailureActionPayload<SuccessCallType>, State>({
        type: prefix + '/failure',
        reducer: failureReducer
    });

    return {request, success, failure};
}
