import {ConnectedRouter} from "connected-react-router";
import {Route, Switch} from "react-router";
import React from "react";
import {CoreRouterProps} from "./coreRouterContainer";

export const CoreRouter = ({routes, history}: CoreRouterProps) => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                {routes.map(route => (
                    <Route
                        path={route.path}
                        component={route.component}
                        render={route.render}
                        key={route.key}
                        exact={route.exact}
                        sensitive={route.sensitive}
                        strict={route.strict}
                    />
                ))}
            </Switch>
        </ConnectedRouter>
    )
};
