import {UiRightCreationWorkflowStateData, UiVehicle} from "../index";
import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {formatVehicles} from "../utils";
import {assignIn} from "lodash";
import vehicles from "../../../../../components/content/domain/home-content/blocks/Vehicles";

type ActionPayload = {
    type: string,
    vehicle: UiVehicle,
};

type ActionCreatorPayload = {
    vehicle: UiVehicle,
};

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/add-vehicle",
    create: (type, { vehicle }) => ({ type, vehicle }),
    reducer: (action, state) => {
        const vehicleFormatted = formatVehicles([action.vehicle]);

        // On ajoute le vehicule créé dans la property vehicles du state
        return {
            ...state,
            vehicles: {
                ...state.vehicles,
                ...vehicleFormatted,
            }
        }
    }
});
