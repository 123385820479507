/**
 * Created by priano on 26/08/2020 at 16:38.
 */
import {ApiCall} from "../../../index";
import {Right} from "../customer-contracts/modeling/right";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/invoice/:invoiceId/print";

type Payload = {
    invoiceId: number | string,
}

export const orderPrintInvoice = (payload: Payload): ApiCall<any> => ({
    url: coreConfiguration.api.xts.orderUrl + path,
    urlParameters: payload,
    body: payload,
    method: 'GET',
    responseType: 'blob',
    formater: r => r,
    tokenRepository: 'xts',
});
