import * as lodash from 'lodash';
import { newRequestActions } from '../../../../../core-react/src/utils/redux/actionBuilder';
import {UiRightCreationWorkflowStateData} from "../index";
import {customerContractsPostProof} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postProof";

export type RequestActionCreatorPayload = {
    type: string,
    label: string,
    attachement: {
        fileName: string,
        base64EncodedContent: string,
    },
    personId: number | string,
    vehicleId: number | string,
    city: string,
}

export default newRequestActions<RequestActionCreatorPayload, UiRightCreationWorkflowStateData, any>(
    "/ui/right-creation/upload_proof",
    customerContractsPostProof,
    (action, state) => ({
        ...state,
    }),
    (action, state) => ({
        ...state,
    }),
    (action, state) => ({
        ...state,
    })
);
