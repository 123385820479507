/**
 * Created by priano on 26/08/2020 at 16:22.
 */
import {ApiCall} from "../../../index";

import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {Pagination} from "../../common/modeling";
import {Invoice} from "./modeling/invoice";
import {Payment} from "./modeling/payment";

const path = "/payment/search";

type Payload = {
    limit?: string,
    offset?: string,
    sortUpdated?: string,
    sortCreated?: string,
    updatedSinceMax?: string,
    invoiceId?: string,
    referenceStartsWith?: string,
    minDate?: Date | string,
    maxDate?: Date | string,
    channel?: string,
    method?: string,
    methodContains?: string,
    agent?: string,
    amount?: string,
    id?: number,
    city: string,
    cities?: string[],
    updatedSince?: Date | string,
};

export const orderPaymentsSearch = (payload: Payload): ApiCall<Pagination<Payment>> => ({
    url: coreConfiguration.api.xts.orderUrl + path,
    body: payload,
    method: 'POST',
    formater: r => ({
        ...r,
        lines: r.lines || []
    }),
    tokenRepository: 'xts',
});
