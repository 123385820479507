/**
 * Created by priano on 09/09/2020 at 09:15.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/proof";

type Payload = {
    type: string,
    label: string,
    attachement: {
        fileName: string,
        base64EncodedContent: string,
    },
    personId: number | string,
    vehicleId: number | string,
    city: string,
}

export const customerContractsPostProof = (payload: Payload): ApiCall<any> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: r => r,
    tokenRepository: "xts"
});
