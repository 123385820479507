/**
 * Created by Nicolas on 06/09/2020
 * Nicolas was born at Versailles, on 01/12/1993 and is the fruit of the union between a colombian woman and a french man.
 * Nicolas has had a funny life, with downs and ups, as everyone. But he did manage to find the way in. He shines by his philosophy and the way he always questions himself about what he did, about how the world is, how it will be, can Nicolas impact its changes
 */
import {DbPersonState, PersonStateData} from "./index";
import {Person} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/person";

const select = (state: any): PersonStateData => state[DbPersonState.stateKey];

export default {
    state: select,
    isEmpty: (state: any) => select(state).data === undefined,
    loading: (state: any) => select(state).loading,
    person: (state: any) => select(state).data as Person, // You assume person is set.
    invoiceAddress: (state: any) => (select(state).data?.addresses || []).filter(a => a.usage === "INVOICE")[0],
};
