import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {UiVehiclesStateData} from "./types";

export default {
    edit: newAction<{id: string}, UiVehiclesStateData, {id: string|number}>({
        type: 'vehicle-edit',
        create: (type, {id}) => ({
            type,
            id: id.toString()
        })
    })
}