import React, {useState} from 'react';
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {CoreNavigationPageChanged} from "../../redux/navigation/actions";

let i = 0;

const PageWrapper = (props: any) => {
    /*
    if (i++ > 5) {
        throw new Error();
    }
     */
    /*
    const [canShow, setCanShow] = useState(false);

    // Sortie de l'effect car il faut à tout prix que ce soit le premier dispatch avant de celui de ses enfants, sinon ils auront pas la route.
    // On pourrait aussi fix en les faisant utiliser la route dans leur useEffect(). Mais plus flexible comme ca
    // --> finalement ils doivent use effect aussi (ccar warning update en mm tps que render)
    // ----> finalement non leur use effect se fait avant
    // -----> finalement pour rm le warning, on use effect mais on delay le render de 50ms pour être sur le state s'est update avec la route
    React.useEffect(() => {
        const {history, location, match, onRender} = props;

        const payload = {
            history, location, match
        };

        onRender(payload);
        setTimeout(() => {
            setCanShow(true);
        }, 50);
    }, [props.history, props.location, props.match]);

    const Component = props.Component;

    return canShow ? <Component/> : <></>
    //return <Component/>
     */

    // --> Cancelled car le delais de 50ms fait un blink moche

    React.useEffect(() => {
        const {history, location, match, onRender} = props;

        const payload = {
            history, location, match
        };

        onRender(payload);
    }, [props.history, props.location, props.match]);

    const Component = props.Component;

    return <Component/>
};

export const Page = (Component: any): any => {
    const mapDispatchToProps = (dispatch: Dispatch) => ({
        onRender: (routingProps: any) => dispatch(CoreNavigationPageChanged({routerProps: routingProps}))
    });

    const mapStateToProps = () => ({
        Component
    });

    return connect(mapStateToProps, mapDispatchToProps, null, {
        // props.match est tjrs un nouveau object avec mm valeurs qu'avant. On passera alors en strictEqual au lieu de shallowEqual sur "props"
        areOwnPropsEqual: (a:any,b:any) => a !== b, //areXEqual('wrapper', 'areOwnPropsEqual'),
        shouldHandleStateChanges: false,
    })(PageWrapper);
};
