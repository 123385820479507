import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData} from "../index";

type ActionPayload = {
    type: string,
    rightCatalogId?: string,
    profileId: string,
};

type ActionCreatorPayload = {
    rightCatalogId?: string,
    profileId: string,
};

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/choose-right-catalog",
    create: (type, { rightCatalogId, profileId}) => ({ type, rightCatalogId, profileId }),
    reducer: (action, state) => {
        return {
            ...state,

            // Choose right catalog and profile
            choosedRightCatalogId: action?.rightCatalogId,
            choosedProfileId: action.profileId,

            // Reset All values on right catalog selection
            completedIndexes: [],
            validityPeriodStatus: undefined,
            validityPeriod: undefined,
            requiredProofs: {},
            requiredProofsStatus: undefined,
            vehicles: {},
            choosedArea: undefined,
            providedProofs: {},

            // reset specific fields
            specificFieldsFormIsValid: false,
            specificFieldsValues: {},
        }
    }
});
