import React from "react";
import {CityPages, currentCity, currentPrefix} from "./cities/_city";
import {Page} from "../core-react/src/modules/routing/components/page/Page";
import {Route} from "../core-react/src/modules/routing";

const isDevelopment = process.env.NODE_ENV === 'development';

const buildRoutes: () => {
    [key in keyof CityPages]: Route
} = () => ({
    // Route public
    PublicHome:                     publicRoute('PublicHome', '/'),
    Register:                       publicRoute('Register', '/register'),
    AskAccountValidation:           publicRoute('AskAccountValidation', '/ask-account-validation'),
    AccountValidation:              publicRoute('AccountValidation', '/account-validation'),
    CustomerCreate:                 publicRoute('CustomerCreate', '/customer-new'),
    ChooseCustomer:                 publicRoute('ChooseCustomer', '/customer-choose'),
    AskChangePassword:              publicRoute('AskChangePassword', '/ask-change-password'),
    ChangePassword:                 publicRoute('ChangePassword', '/change-password'),
    TermsOfSale:                    publicRoute('TermsOfSale', '/terms-of-sale'),
    TermsOfService:                 publicRoute('TermsOfService', '/terms-of-service'),
    LegalNotices:                   publicRoute('LegalNotices', '/legal-notices'),
    FrequentlyAskedQuestions:       publicRoute('FrequentlyAskedQuestions', '/frequently-asked-questions'),
    FranceConnectCallback:          publicRoute('FranceConnectCallback', isDevelopment ? '/callback' : '/france-connect'),
    CompleteProfile:                publicRoute('CompleteProfile', '/complete-your-profile'),

    // Route privée
    PrivateHome:                    privateRoute('PrivateHome', '/my-home'),
    CreateVehicle:                  privateRoute('CreateVehicle', '/vehicle-new'),
    EditVehicle:                    privateRoute('EditVehicle', '/vehicle-edit'),
    CreateRight:                    privateRoute('CreateRight', '/right-new'),
    CustomerEdit:                   privateRoute('CustomerEdit', '/profile'),
    InvoicesList:                   privateRoute('InvoicesList', '/invoices'),
    Invoice:                        privateRoute('Invoice', '/invoice'),
    PermitsList:                    privateRoute('PermitsList', '/permits'),
    Account:                        privateRoute('Account', '/account'),
    PaymentResult:                  privateRoute('PaymentResult', '/payment-result'),
    PaymentAliasList:               privateRoute('PaymentAliasList', '/payment-alias'),
    VehiclesList:                   privateRoute('VehiclesList', '/vehicles'),

    // Dans l'url on part sur supporting-documents car c'est ce que le client voit.
    // En "interne" dans le code on utilisera proof toujours
    ProofsList:                     privateRoute('ProofsList', '/supporting-documents'),
    PrepaidAccountPayment:          privateRoute('PrepaidAccountPayment', '/prepaid-account-payment'),
    BPayMessage:                    privateRoute('BPayMessage', '/bpay-payment')

});

const publicRoute = (name: keyof CityPages, path: string): Route => ({
    name,
    path: currentPrefix() + path,
    component: Page(currentCity()[name]),
});

const privateRoute = (name: keyof CityPages, path: string): Route => ({
    ...publicRoute(name, path),
    private: true,
});

let builtRoutes: ReturnType<typeof buildRoutes>;

export const routes = () => {
    if (! builtRoutes) {
        builtRoutes = buildRoutes();
    }

    return builtRoutes;
};
