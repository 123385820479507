import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiPasswordChangeDemandStateData} from "../index";
import {customerContractsPostAccountAskChangePassword} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postAccountAskChangePassword";

type RequestActionCreatorPayload = {
    email: string,
    resetUrl: string,
    city: string,
};

const actions = newRequestActions<RequestActionCreatorPayload, UiPasswordChangeDemandStateData, string>(
    "/ui/password-change-demand/send",
    (payload) => customerContractsPostAccountAskChangePassword(payload),
    (action, state) => ({
        loading: true,
        done: false,
        emailNotFound: false,
    }),
    (action, state) => ({
        loading: false,
        done: true,
        emailNotFound: false,
    }),
    (action, state) => ({
        loading: false,
        done: false,
        emailNotFound: action.apiCallResult.statusCode === 404
        // details.message = No mailing found for code PERSONACCOUNT_PASSWORD_RESET and city Koekelberg
        // details.message = No account for this email
    })
);

export default actions;
