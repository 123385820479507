/**
 * Created by priano on 02/10/2020 at 17:35.
 */
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {ApiCall} from "../../../../../core-react/src/modules/api";
import {configuration} from "../../../../../configuration/configuration";
import {DbAliasesStateData} from "../index";
import _ from "lodash";
import {promises} from "fs";

const path = "/api/alias";

type Payload = {
    id: number | string,
    city: string
}

export const orderRemoveAlias = (payload: Payload): ApiCall<any> => ({
    url: configuration().payment.url + path,
    urlParameters: payload,
    body: payload,
    method: 'DELETE',
    responseType: 'blob',
    formater: r => r,
    tokenRepository: 'xts',
});

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = {
    id: string | number,
    city: string
};

export default newRequestActions<ActionCreatorPayload, DbAliasesStateData, any>(
    '/ui/remove-alias/request',
    (payload) => orderRemoveAlias(payload),
    (action, state) => state,
    (action, state) => {
        const id = action.apiCallResult.request.data.id;
        return {
            ... state,
            byId: _.pick(state.byId, Object.keys(state.byId).filter(key => key != id))
        }
    },
    (action, state) => state
);
