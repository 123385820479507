import {DbPrepaidAccountStateData, DbPrepaidAccountState} from "./index";
import {createSelector} from "reselect";

const select = (state: any): DbPrepaidAccountStateData => state[DbPrepaidAccountState.stateKey];

const selectBalance = createSelector(
    (state: any) => select(state).account,
    account => 0||account?.computedCurrentAmount || 0,
);

export default {
    state: select,
    accountLoading: (state: any) => select(state).accountLoading,
    account: (state: any) => select(state).account,
    operationsLoading: (state: any) => select(state).operationsLoading,
    operations: (state: any) => select(state).operations,
    balance: selectBalance,
    hasFundsInBalance : createSelector(
        selectBalance,
        balance => balance > 0
    ),
    paymentStatus: (state: any) => select(state).paymentStatus,
    processingPayment: createSelector(
        (state: any) => select(state).paymentStatus,
        (paymentStatus) => paymentStatus === 'processing',
    ),
    paymentCompleted: createSelector(
        (state: any) => select(state).paymentStatus,
        (paymentStatus) => paymentStatus === 'completed',
    ),
}
