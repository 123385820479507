import React from "react";
import ReduxToastr from "react-redux-toastr";

export const CoreNotificationsProvider = ({children}: {children: any}) => (
    <>
        {children}
        <ReduxToastr confirmOptions={{
            okText: 'Oui',
            cancelText: 'Annuler'
        }}/>
    </>
);
