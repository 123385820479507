import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/account/ask/validationEmail";

type Payload = {
    confirmationUrl: string,
    email: string,
    city: string
};

export const customerContractsPostAccountAskValidationEmail = (payload: Payload): ApiCall<string> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: (s: string) => s
});
