import {addLocale} from "primereact/api";

const en = {
    closeText: 'Close',
    prevText: 'Previous',
    nextText: 'Next',
    monthNames: [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['S', 'M', 'T', 'W ', 'T', 'F ', 'S'],
    weekHeader: 'Week',
    weekNumberTitle: 'W',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: '',
    timeOnlyTitle: 'Only Time',
    timeText: 'Time',
    hourText: 'Time',
    minuteText: 'Minute',
    secondText: 'Second',
    currentText: 'Current Date',
    ampm: false,
    month: 'Month',
    week: 'week',
    day: 'Day',
    allDayText: 'All Day',
    today: 'Today',
    clear: 'Clear',

    // Pour tout mettre au même endroit
    dateFormat: 'mm/dd/yy',
}

// @link https://www.primefaces.org/showcase/ui/input/datepicker/datePicker.xhtml?jfwid=750b1
// FIXME: Attention ajouter à chaque nouveau language format ici
export const locales: any = {
    fr: {
        closeText: 'Fermer',
        prevText: 'Précédent',
        nextText: 'Suivant',
        monthNames: [
            'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ],
        monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        weekHeader: 'Semaine',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        timeOnlyTitle: 'Choisir l\'heure',
        timeText: 'Heure',
        hourText: 'Heures',
        minuteText: 'Minutes',
        secondText: 'Secondes',
        currentText: 'Maintenant',
        ampm: false,
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        allDayText: 'Toute la journée',

        // Pour tout mettre au même endroit
        dateFormat: 'dd/mm/yy',
    },
    en,
    en_AU: {
        ...en,
        dateFormat: 'dd/mm/yy', // format spécifique pour l'australie
    },
    de: {
        closeText: 'Schließen',
        prevText: 'Zurück',
        nextText: 'Weiter',
        monthNames: [
            'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
        ],
        monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
        dayNamesMin: ['S', 'M', 'D', 'M ', 'D', 'F ', 'S'],
        weekHeader: 'Woche',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        timeOnlyTitle: 'Nur Zeit',
        timeText: 'Zeit',
        hourText: 'Stunde',
        minuteText: 'Minute',
        secondText: 'Sekunde',
        millisecondText: 'Millisekunde',
        currentText: 'Aktuelles Datum',
        ampm: false,
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
        allDayText: 'Ganzer Tag',
        today: 'Heute',
        clear: 'Löschen',

        // Pour tout mettre au même endroit
        dateFormat: 'dd/mm/yy',
    },
    nl: {
        closeText: 'Sluiten',
        prevText: 'Vorige',
        nextText: 'Volgende',
        monthNames: [
            'Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'
        ],
        monthNamesShort: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        dayNames: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
        dayNamesShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
        dayNamesMin: ['Z', 'M', 'D', 'W ', 'D', 'V ', 'Z'],
        weekHeader: 'Week',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        timeOnlyTitle: 'Alleen Tijd',
        timeText: 'Tijd',
        hourText: 'Uren',
        minuteText: 'Minuutje',
        secondText: 'Tweede',
        millisecondText: 'Milliseconde',
        currentText: 'Huidige Datum',
        ampm: false,
        month: 'Maand',
        week: 'Week',
        day: 'Dag',
        allDayText: 'Hele dag',
        today: 'Vandaag',
        clear: 'Verwijder',

        // Pour tout mettre au même endroit
        dateFormat: 'dd/mm/yy',
    },
};

export const addCalendarLocale = (locale: string) => {
    const localeConfig = locales[locale];

    addLocale(locale, localeConfig)
}
