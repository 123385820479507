/**
 * Created by priano on 14/08/2020 at 10:42.
 */
import {createFormSlice, FormState} from "../../../core-react/src/utils/form/redux";
import {customerContractsPostPerson} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/postPerson";
import {currentCity} from "../../../configuration/cities/_city";
import {sessionSelectors} from "../session";
import {Person} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/person";
import {CoreNavigationDemand} from "../../../core-react/src/modules/routing/redux/navigation/actions";
import {routes} from "../../../configuration/routes";
import {selectCurrentRoute} from "../../../core-react/src/modules/routing/redux/navigation/state";
import {customerContractsGetPerson} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/getPerson";
import {customerContractsPutPerson} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/putPerson";
import {DbPersonState} from "../../modules_clean/db/person";
import {
    formatAddress,
    internalAccommodationOptions,
    selectPersonAddressByUsage
} from "../../../components/content/domain/profile-form/utils";
import {CustomerContractsState} from "../../modules_clean/configuration/customerContracts";
import {
    CustomerTitle,
    CustomerTitleIdentifier
} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/customer-contracts-city";
import {getTranslation} from "../../../translations/useTranslation";
import {DbAreaState} from "../../modules_clean/db/areas";
import {isEmpty, omit} from "lodash";

// On attache __CUSTOMER_TITLES__ pcq on sait pas séléectionner ici dans ce slice le state pour faire notre validation sur notre gender
(window as any).__CUSTOMER_TITLES__ = [];

export type CustomerType = "Individual" | "Organization" | "UneStudent" | "UneStaff" | "UneContractor" | "UneDepartment" | "UneRestaurant";

export type ProfileFormData = {
    type: CustomerType | string,
    personType: 'individual' | 'business',
    lastname: string,
    firstname: string,
    gender: CustomerTitle | string,
    landphone: string,
    mobilephone: string,
    contactChannel: string,
    organization?: string,
    language: string,
    acceptedTOS: boolean,

    // UNE Customer Types
    studentNumber?: string,
    internalAccommodation?: string,
    staffNumber?: string,
    abn?: string,
    workphone?: string,
    companyName?: string,

    billingAddressIsSameAsHomeAddress: boolean,

    homeAddressText: string,
    homeAddress: any
    homeAddressDetails: string,

    billingAddressText: string,
    billingAddress: any,
    billingAddressDetails: string,

    secondaryAddressText: string,
    secondaryAddress: any
    secondaryAddressDetails: string,
}

const initialState: ProfileFormData = {
    type: "",
    personType: "individual",
    lastname: "",
    firstname: "",
    gender: "",
    landphone: "",
    mobilephone: "",
    contactChannel: "EMAIL",
    organization: undefined,
    language: "",
    acceptedTOS: false,

    // UNE Customer Types
    studentNumber: "",

    // UNE student, can "do not leave on campus" be first in the list and be selected by default
    internalAccommodation: internalAccommodationOptions[0],

    staffNumber: "",
    abn: "",
    workphone: undefined,

    billingAddressIsSameAsHomeAddress: true,

    homeAddressText: '',
    homeAddress: undefined,
    homeAddressDetails: '',

    billingAddressText: '',
    billingAddress: undefined,
    billingAddressDetails: '',

    secondaryAddressText: '',
    secondaryAddress: undefined,
    secondaryAddressDetails: '',
};

export type ProfileFormState = FormState<ProfileFormData> & {
    context?: {
        on: 'new' | 'edit',
        personId?: string|number,
        personAccountIds?: string[],
        personEmail?: string,
        incompleteProfile?: boolean,
    }
};

// @ts-ignore
export const profileFormSlice = createFormSlice<ProfileFormData, Person, ProfileFormState, Person>(
    "/profile-form",
    "profile-form",
    {
        fields: {
            type: { required: true },
            personType: { required: false },
            lastname: { required: true },
            firstname: { required: true },
            gender: {
                required: false,
                validator: dto => {

                    // Utilisation de __CUSTOMER_TITLES__ obtenu depuis la configuration pour la validation de gender
                    const customerTitles = (window as any).__CUSTOMER_TITLES__ || [];
                    const translations = getTranslation();

                    return (
                        customerTitles.length > 1 && !dto.gender
                            ?  [translations.thisFieldIsMandatory]
                            : []
                    )
                },
            },
            landphone: { required: false },
            mobilephone: { required: false },
            contactChannel: { required: true },
            organization: {
                required: false,
                validator: dto => {
                    const translations = getTranslation();

                    return !dto.organization && dto.type === 'Organization'
                        ? [translations.yourBusinessNameMustBeFilled]
                        : []

                }
            },
            language: { required: true },
            acceptedTOS: {
                required: false,
                validator: dto => {
                    const translations = getTranslation();

                    return !dto.acceptedTOS
                        ? [translations.termsOfServiceMustBeAccepted]
                        : [];
                }
            },

            studentNumber: {
                required: false,
                validator: dto => {
                    const isUNE = currentCity().id === 'University New England';
                    const translations = getTranslation();

                    return isUNE && !dto.studentNumber && dto.type === 'UneStudent'
                        ? [translations.yourStudentNumberMustBeFilled]
                        : []
                }
            },
            internalAccommodation: {
                required: false,
                validator: dto =>  {
                    const isUNE = currentCity().id === 'University New England';
                    const translations = getTranslation();

                    return isUNE && !dto.internalAccommodation && dto.type === 'UneStudent'
                        ? [translations.yourInternalAccommodationMustBeFilled]
                        : []
                }
            },
            staffNumber: {
                required: false,
                validator: dto => {
                    const isUNE = currentCity().id === 'University New England';
                    const translations = getTranslation();

                    return isUNE && !dto.staffNumber && dto.type === 'UneStaff'
                        ? [translations.yourStaffNumberMustBeFilled]
                        : []
                }
            },
            abn: {
                required: false,
                validator: dto => {
                    const isUNE = currentCity().id === 'University New England';
                    const translations = getTranslation();

                    return isUNE && !dto.abn && dto.type === 'UneContractor'
                        ? [translations.yourAbnMustBeFilled]
                        : []
                }
            },
            companyName: {
                required: false,
                validator: dto => {
                    const isUNE = currentCity().id === 'University New England';
                    const translations = getTranslation();

                    return isUNE && !dto.companyName && dto.type === 'UneContractor'
                        ? [translations.yourCompanyNameBeFilled]
                        : []
                }
            },
            workphone: { required: false, },

            billingAddressIsSameAsHomeAddress: { required: false },
            homeAddressText: { required: true },
            homeAddress: {
                required: false,

                // On fait ça pour avoir un message d'erreur plus précisé et personnalisé
                validator: dto => {
                    const translations = getTranslation();
                    const address = omit(dto.homeAddress, 'id');

                    return isEmpty(address)
                        ? [translations.youMustSelectAChoice]
                        : []
                }
            },
            homeAddressDetails: { required: false },
            billingAddressText: { required: true },
            billingAddress: {
                required: false,

                // On fait ça pour avoir un message d'erreur plus précisé et personnalisé
                validator: dto => {
                    const translations = getTranslation();
                    const address = omit(dto.billingAddress, 'id');

                    return isEmpty(address)
                        ? [translations.youMustSelectAChoice]
                        : []
                }
            },
            billingAddressDetails: { required: false },
            secondaryAddressText: { required: false },
            secondaryAddress: { required: false },
            secondaryAddressDetails: { required: false },
        }
    }, initialState, {
        // Dispatch via component à chaque render
        onArrived(dispatch, state, action) {
            const previousContext: 'new' | 'edit' | undefined = profileFormSlice.select(state).context?.on;

            const route = selectCurrentRoute(state);

            let nextContext = route.name === routes().CustomerEdit.name ? 'edit' : 'new';

            // Quand je viens de CompleteProfile c'est que le profil doit être complété
            // on change donc de context et on le met en edit
            const incompleteProfile = route.name === routes()?.CompleteProfile?.name;
            if (incompleteProfile) {
                nextContext = 'edit';
            }

            if (previousContext === nextContext) {
                return;
            }

            if (nextContext === 'new' && !sessionSelectors.accountEmail(state)) {
                dispatch(CoreNavigationDemand({
                    route: routes().PublicHome
                }));
                // dispatch(CoreNotify.failure(
                //     "Information missing. Please connect again"
                // ));
                dispatch(profileFormSlice.actions.reset());
            }

            // Pour le moment la config customer contracts est juste utilisé pour les customerTitles et customerTypes
            // On load ici la config une fois les checks passés
            dispatch(CustomerContractsState.actions.loadConfiguration.request({ city: currentCity().id }));

            // Fetch des areas pour le select de zones dans le details form
            dispatch(DbAreaState.actions.fetchAll.request({city: currentCity().id}));

            if (nextContext === 'edit') {
                const person = DbPersonState.selectors.person(state);

                dispatch(profileFormSlice.actions.initialize({
                    context: {
                        on: 'edit',
                        personId: person?.id,
                        personAccountIds: person?.accounts?.map(a => a.id),
                        personEmail: person?.email,
                        incompleteProfile, // Ajout de cette information lors de l'init du form
                    }
                }));
            } else {
                dispatch(profileFormSlice.actions.initialize({
                    context: {
                        on: 'new'
                    }
                }));
            }
        },
        // Dispatch via component
        onReset(dispatch, state, action) {
            dispatch(
                profileFormSlice.actions.initialize(
                    profileFormSlice.select(state).context
                )
            );
        },
        // Dispatch via listeners
        onInitialize(dispatch, state, action) {
            if (! action.context) {
                return;
            }

            const {on} = action.context;
            if (on === 'new') {
                return dispatch(
                    profileFormSlice.actions.initialized({
                        dto: {
                            ...initialState,
                            language: currentCity().language || "en",
                        },
                    })
                );
            }

            const id = sessionSelectors.customerId(state);

            dispatch(profileFormSlice.actions.initializeRequest({
                apiCall: customerContractsGetPerson({personId: `${id}`})
            }));
        },
        onInitializeFailure(dispatch, state, action) {
            // dispatch(CoreNotify.failure(
            //     "An error happened. You have been redirected"
            // ));
            dispatch(CoreNavigationDemand({
                route: routes().PrivateHome,
            }));
            dispatch(profileFormSlice.actions.reset());
        },
        onInitializeSuccess(dispatch, state, action) {
            const person: Person = action.apiCallResult.data;

            // Récupération des adresses selon l'usage
            const homeAddress = selectPersonAddressByUsage(person.addresses, 'DEFAULT');
            const billingAddress = selectPersonAddressByUsage(person.addresses, 'INVOICE');
            const secondaryAddress = selectPersonAddressByUsage(person.addresses, 'SECONDARY');

            // On vérifie si les adresses sont identiques pour cocher ou non la checkBox de billingAddressIsSameAsHomeAddress
            const formattedHomeAddress = homeAddress && formatAddress(homeAddress);
            const formattedBillingAddress = billingAddress && formatAddress(billingAddress);
            const isBillingAddressIsSameAsHomeAddress = formattedHomeAddress === formattedBillingAddress;

            const dto: any = {
                type: person.type,
                organization: person.organization || '',
                lastname: person.lastname,
                acceptedTOS: true,
                billingAddressIsSameAsHomeAddress: isBillingAddressIsSameAsHomeAddress,
                contactChannel: person.contactChannel,
                firstname: person.firstname,
                gender: person.gender,
                personType: person.organization ? 'business' : 'individual',
                landphone: person.landphone,
                language: person.language,
                mobilephone: person.mobilephone,

                // customer type data
                studentNumber: person?.customerTypeData?.studentNumber || '',
                internalAccommodation: person?.customerTypeData?.internalAccommodation || '',
                staffNumber: person?.customerTypeData?.staffNumber || '',
                companyName: person?.customerTypeData?.companyName || '',
                abn: person?.customerTypeData?.abn || '',
                workphone: person?.customerTypeData?.workphone || '',

                // Set des adresses récupérée
                homeAddressText: homeAddress && formatAddress(homeAddress),
                homeAddressDetails: homeAddress?.addressDetails || '',
                homeAddress,

                billingAddressText: billingAddress && formatAddress(billingAddress),
                billingAddressDetails: billingAddress?.addressDetails || '',
                billingAddress,

                secondaryAddressText: secondaryAddress && formatAddress(secondaryAddress),
                secondaryAddressDetails: secondaryAddress?.addressDetails || '',
                secondaryAddress,
            };

            dispatch(profileFormSlice.actions.initialized({
                dto
            }));
        },
        onSubmit(dispatch, state, action) {
            if (!state[profileFormSlice.stateKey].submitting) {
                return;
            }

            const {
                type,
                studentNumber,
                internalAccommodation,
                staffNumber,
                companyName,
                abn,
                workphone,
                contactChannel,
                firstname,
                gender,
                landphone,
                language,
                lastname,
                mobilephone,
                organization,

                homeAddress,
                homeAddressDetails,
                billingAddress,
                billingAddressDetails,
                secondaryAddress,
                secondaryAddressDetails
            }: ProfileFormData = state[profileFormSlice.stateKey].dto;

            const { submitRequest } = profileFormSlice.actions;

            // Si la currentCity === Pensacola, alors nous formattons le firstname pour qu il soit toujours UPPERCASE
            const firstnameFormatted = currentCity().id === "Pensacola" ? firstname.toUpperCase() : firstname;

            // Construction des dtos pour intégrer direct les addresses dans la création de person
            const addressesAtCreation = [
                {
                    ...homeAddress,
                    addressDetails: homeAddressDetails,
                    locality: homeAddress.locality.toUpperCase(),
                    usage: 'DEFAULT',
                },
                {
                    ...billingAddress,
                    addressDetails: billingAddressDetails,
                    locality: billingAddress.locality.toUpperCase(),
                    usage: 'INVOICE',
                }
            ];

            // Si existence secondary address on push dans les addresses à POST
            if (secondaryAddress) {
                addressesAtCreation.push({
                    ...secondaryAddress,
                    addressDetails: secondaryAddressDetails,
                    locality: secondaryAddress.locality.toUpperCase(),
                    usage: 'SECONDARY',
                })
            }

            // On récupères les customerTitles du state
            const customerTitles = CustomerContractsState.selectors.customerTitles(state);

            // On assigne le gender du dto ou si au moins 1 customerTitles on assigne le 1er customerTitles sinon on assigne vide
            const genderPayload = gender || (customerTitles.length > 0 ? customerTitles[0].identifier : "");

            const callPayload = {
                city: currentCity().id,
                type,
                contactChannel,
                email: sessionSelectors.accountEmail(state) as string,
                firstname: firstnameFormatted,
                gender: genderPayload as CustomerTitleIdentifier,
                studentNumber,
                internalAccommodation,
                staffNumber,
                companyName,
                abn,
                workphone,
                landphone,
                language,
                lastname: lastname.toUpperCase(),
                mobilephone,
                organization: organization || undefined,
                accountIds: [
                    sessionSelectors.accountId(state) as string,
                ]
            };

            const context = profileFormSlice.select(state).context;

            const personId = sessionSelectors.customerId(state);

            const call = context.on === 'edit'
                ? () => customerContractsPutPerson(`${personId}`, {
                    ...callPayload,
                    accountIds: context?.personAccountIds,
                    email: context?.personEmail,
                })
                : () => customerContractsPostPerson({
                    ...callPayload,
                    addressesAtCreation, // Append des adresses à créer seulement si on création
                });

            dispatch(
                submitRequest({
                    apiCall: call()
                })
            );
        },
        onSubmitSuccess(dispatch, state, action) {
            // Si incompleteProfile on redirect vers la home page en rechargeant la page avec window.location
            const {incompleteProfile} = profileFormSlice.select(state).context;

            if (incompleteProfile) {
                const url = window.origin + routes().PrivateHome.path;
                return window.location.assign(url);
            }

            // Je reforce l'initialisation car dès fois il y a des données qui ne sont pas actualisées
            const id = sessionSelectors.customerId(state);

            // Si pas d'id on ne fait rien
            if (!id) {
                return;
            }

            dispatch(DbPersonState.actions.fetch.request({ id: id! }));

            // Callback de success
            const onSubmitSuccess = profileFormSlice.select(state).onSubmitSuccess || (() => {});
            onSubmitSuccess();
        },
        onSubmitFailure(dispatch, state, action) {
            // dispatch(
            //     CoreNotify.failure("An error happened")
            // );
            const onSubmitFailure = profileFormSlice.select(state).onSubmitFailure || (() => {});
            onSubmitFailure();
        }
    }, {
        initialize: (action, state) => ({
            ...state,
            context: action.context,
        })
    }
);

