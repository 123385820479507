import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/vehicle/driver/:id";

type Payload = {
    id: string,
};

export const customerContractsDeleteVehicleDriver = (payload: Payload): ApiCall<void> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path.replace(':id', payload.id),
    method: 'DELETE',
    formater: r => r,
    tokenRepository: 'xts',
});
