import usStates from "./statesTitleCase.us.json";
import auStates from "./statesTitleCase.au.json";
import {useSelector} from "react-redux";
import {CustomerContractsState} from "../redux/modules_clean/configuration/customerContracts";
import {FALLBACK_LANGUAGE, useCurrentLanguage} from "../translations/useTranslation";
import {getBrowserLanguage} from "../core-react/src/utils/lang";
import {keyBy, sortBy} from "lodash";
import countries from 'i18n-iso-countries';

const countryWithState = ['US', 'AU'];

export const isStateCountry = (countryCode: string) => countryWithState.indexOf(countryCode) !== -1

const stateMapper = (s: { name: string, abbreviation: string }) => ({label: s.name, value: s.abbreviation});

export const availableStatesByCountry: { [key: string]: Array<{ label: string, value: string }> } = {
    US: usStates.map(stateMapper),
    AU: auStates.map(stateMapper),
};

export function useCountries() {

    // On récupère la langue de la city et du navigateur
    const lang = useCurrentLanguage();

    // On récupère les langues supporté par i18n-iso-countries
    const supportedLanguage = countries.getSupportedLanguages();

    // On vérifie si la langue de la city est supporté par i18n-iso-countries
    const isSupportedLanguage = supportedLanguage.includes(lang);

    // Si supporté on prend la langue de la city sinon on prend la FALLBACK_LANGUAGE
    const langToIsoCountries = isSupportedLanguage ? lang : FALLBACK_LANGUAGE;

    countries.registerLocale(require(`i18n-iso-countries/langs/${langToIsoCountries}.json`));

    // On récupère tous les pays dans la langue de langToIsoCountries
    const allCountries = countries.getNames(langToIsoCountries, {select: "official"});

    const countriesOptions = Object.entries(allCountries).map(
        c => ({
            label: c[1] as string,      // France, Germany, ...
            value: c[0]                 // FR, DE, .....
        }));

    // on sort alphabétiquement ASC par label
    const sorted = sortBy(countriesOptions, 'label');

    return {
        countriesOptions: sorted,
    }
}

export function useCountryValue(address?: any) {
    const { countriesOptions } = useCountries();

    // Get du country depuis la config
    const country = useSelector(CustomerContractsState.selectors.country);

    // selection des countries
    const countries = keyBy(countriesOptions, 'value');
    const countryValue = countries[address?.country || country] || {};

    return {
        countryValue,
    }
}
