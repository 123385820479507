import {payUsingBPayFailureAction, payUsingBPayRequestAction, payUsingBPaySuccessAction} from './actions/payUsingBPay'
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import sagas from './sagas'
import selectors from './selectors'

const actions = {
    payUsingBPayRequestAction,
    payUsingBPaySuccessAction,
    payUsingBPayFailureAction }

const stateKey = "ui.b-pay";

export type UiBPayPaymentStateData = {
    loading: boolean,
    disabledButton: boolean
}

const initialeState: UiBPayPaymentStateData = {
    loading: false,
    disabledButton: false,
}

const reducer = reducerFromActionsObject(initialeState, actions)

export const UiBPayPaymentState = {
    stateKey, actions, reducer, sagas, selectors
}

