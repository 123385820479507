import {reducerFromActions} from "../../../core-react/src/utils/redux/reducerBuilder";
import {SessionConnectAccountAction} from "./actions/connectAccount";
import {SessionAskAccountValidationAction} from "./actions/askAccountValidation";
import listeners from "./listeners";
import {SessionStartCustomerConnectionProcessAction} from "./actions/startCustomerConnectionProcess";
import {Person} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/person";
import {
    SessionGetAccountMatchingCustomersFailureAction,
    SessionGetAccountMatchingCustomersRequestAction,
    SessionGetAccountMatchingCustomersSuccessAction
} from "./actions/getAccountMatchingCustomers";
import {
    SessionEndCustomerConnectionProcessFailureAction,
    SessionEndCustomerConnectionProcessRequestAction,
    SessionEndCustomerConnectionProcessSuccessAction
} from "./actions/endCustomerConnectionProcess";
import {SessionDisconnectAction} from "./actions/disconnect";
import {SessionUpdateEmailAction} from "./actions/updateEmail";

export type SessionState = {
    account?: {
        token?: string,
        tokenExpirationTimestamp?: number,
        id?: string,
        email?: string,
    },
    connection?: {
        persons: Person[],
        loading: boolean,
    },
    customer?: {
        id: number,
        token: string,
        tokenExpirationTimestamp: number,
        language?: string;
    }
}

const initialState: SessionState = {};

export const sessionStateKey = 'session';

export const sessionReducer = reducerFromActions(initialState,
    SessionDisconnectAction,
    SessionConnectAccountAction,
    SessionAskAccountValidationAction,
    SessionStartCustomerConnectionProcessAction,
    SessionGetAccountMatchingCustomersRequestAction,
    SessionGetAccountMatchingCustomersSuccessAction,
    SessionGetAccountMatchingCustomersFailureAction,
    SessionEndCustomerConnectionProcessRequestAction,
    SessionEndCustomerConnectionProcessSuccessAction,
    SessionEndCustomerConnectionProcessFailureAction,
    SessionUpdateEmailAction,
);

const selectState = (state: any): SessionState => state[sessionStateKey];

export const sessionSelectors = {
    state: selectState,
    connecting: (state: any): boolean => selectState(state).connection?.loading === true,
    accountIsConnected: (state: any): boolean => {
        const sessionState = selectState(state);

        const now = Math.ceil((new Date()).valueOf() / 1000);

        return (sessionState.account?.tokenExpirationTimestamp || 0) > now;
    },
    accountId: (state: any) => selectState(state).account?.id,
    accountToken: (state: any): string|undefined => selectState(state).account?.token,
    accountEmail: (state: any): string|undefined => selectState(state).account?.email,
    accountMatchingPersons: (state: any): Person[] => selectState(state).connection?.persons || [],
    customerIsConnected: (state: any): boolean => {
        const sessionState = selectState(state);

        const now = Math.ceil((new Date()).valueOf() / 1000);

        return (sessionState.customer?.tokenExpirationTimestamp || 0) > now;
    },
    customerToken: (state: any): string|undefined => selectState(state).customer?.token,
    customerId: (state: any): number|undefined => selectState(state).customer?.id,
};

listeners();
