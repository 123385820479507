import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {SessionState} from "../index";

type ActionPayload = {
    type: string,
    email: string,
    source: 'register' | 'login',
};

type ActionCreatorPayload = {
    email: string,
    source: 'register' | 'login'
};

export const SessionAskAccountValidationAction = newAction<ActionPayload, SessionState, ActionCreatorPayload>({
    type: "/ask-account-validation",
    create: (type, payload) => ({
        type,
        ...payload,
    }),
    reducer: ({email, type}, state) => ({
        ...state,
        account: {
            ...state.account,
            email
        }
    })
});
