/**
 * Created by priano on 08/09/2020 at 10:39.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {RequiredProof} from "./modeling/required-proof";

const path = "/rightCatalog/required-proof"

type Payload = {
    deliveryChannel?: string,
    limit?: string,
    offset?: string,
    startDate?: string,
    endDate?: string,
    vehicleId?: number | string,
    personId?: number | string,
    rightCatalogId?: number | string,
    city: string
};

export const customerContractsPostRightCatalogRequiredProof = (payload: Payload): ApiCall<RequiredProof[]> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: r => r,
    tokenRepository: "xts"
});
