import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import fetchAll from './actions/fetchAll';
import selectors from "./selectors";
import writeOne from "./actions/writeOne";
import deleteOne from './actions/deleteOne';
import cleanLastCreatedDriver from "./actions/cleanLastCreatedDriver";
import {VehicleDriver} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/driver";

export type DbVehicleDriversStateData = {
    loading: boolean, // All vehicles being fetched
    byId: {
        [id: string]: {
            loading: boolean, // One vehicle being fetched
            driver?: VehicleDriver,
        }
    },
    lastCreatedDriver?: VehicleDriver,
};

const initialState: DbVehicleDriversStateData = {
    loading: false,
    byId: {}
};

const actions = { fetchAll, writeOne, deleteOne, cleanLastCreatedDriver };

const stateKey = "db.vehicleDrivers";

const reducer = reducerFromActionsObject(initialState, actions);

export const DbVehicleDriversState = {
    actions, stateKey, reducer, selectors
};
