import {OrderCityConfiguration} from "../../../../core-react/src/modules/api/utils/xts/order/modeling/city";
import loadConfiguration from './actions/loadConfiguration';
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import selectors from "./selectors";

export type OrderConfigurationStateModel = {
    configuration?: OrderCityConfiguration,
    loading: boolean,
    loaded: boolean,
}

const initialState: OrderConfigurationStateModel = {
    loading: false,
    loaded: false,
    configuration: undefined,
};

const actions = { loadConfiguration }

export const stateKey = 'configuration.order';

const reducer = reducerFromActionsObject(initialState, actions);

export const OrderConfigurationState = {
    actions, stateKey, reducer, selectors
}
