import {Formater} from "../../../common/modeling";

type ApiJwtResult = {
    token: string,
    expiresAt: string
}

export type Jwt = {
    token: string,
    expiresAt: number,
}

export const apiJwtToJwt: Formater<ApiJwtResult, Jwt> = apiJwt => ({
    token: apiJwt.token,
    expiresAt: parseInt(apiJwt.expiresAt)
});
