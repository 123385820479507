import {Store} from "redux";
import {CoreNavigationDemand} from "../../core-react/src/modules/routing/redux/navigation/actions";
import {routes} from "../../configuration/routes";

export const tokenExpiredMiddleware = (store : Store|any) => (next : Function) => async (action : any) => {
    next(action);

    if (action.type.toString().endsWith('/failure')
        && action.apiCallResult?.statusCode === 401
        && action.apiCallResult?.data?.error === "Expired token") {
        store.dispatch(
            CoreNavigationDemand({
                route: routes().PublicHome
            })
        );
    }
};
