import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData} from "../index";

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = void;

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/next",
    create: (type) => ({ type }),
    reducer: (action, state) => {
        const newState = {
            ...state,
            activeStepIndex: state.activeStepIndex + 1,
        };

        // si index déjà completed on ne fait rien
        if (state.completedIndexes.indexOf(state.activeStepIndex) !== -1) {
            return newState;
        }

        // sinon on le rajoute dans le liste des indexes complétés
        const completedIndexes = [...state.completedIndexes, state.activeStepIndex];

        return {
            ...newState,
            completedIndexes,
        }
    }
});
