import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData, UiVehicle} from "../index";

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = void;

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/vehicle-arrived",
    create: (type) => ({ type }),
});
