import {Formater} from "../../../common/modeling";
import {ApiVehicleResult, apiVehicleToVehicle, Vehicle} from "./vehicle";
import {Address} from "./address";

// RAW API RESULTS
export type ApiPersonResult = {
    type: CustomerTypeIdentifier,
    lastname: string,
    firstname: string,
    externalId: string,
    householdId: string,
    gender: 'female' | 'male' | 'other',
    email: string,
    landphone: string,
    mobilephone: string,
    contactChannel: string,
    organization: string,
    organizationId: string,
    language: string,
    city: string,
    vehicles: ApiVehicleResult[],
    addresses: Address[],
    id: number,
    created: string,
    updated: string,
    deleted: string,
    accounts: any[],
    customerTypeData?: CustomerTypeData,
};

export type CustomerTypeData = {
    studentNumber?: string,
    abn?: string,
    internalAccommodation?: string,
    companyName?: string,
    staffNumber?: string,
    workphone?: string,
}

export type CustomerTypeIdentifier = "Individual" | "Organization" | "UneStudent" | "UneStaff" | "UneContractor" | "UneDepartment" | "UneRestaurant";

// FORMATED API RESULTS
export type Person = {
    id: number,
    type: CustomerTypeIdentifier,
    lastname: string,
    firstname: string,
    designation: string,
    externalId: string,
    householdId: string,
    gender: 'female' | 'male' | 'other',
    email: string,
    landphone: string,
    mobilephone: string,
    contactChannel: string,
    organization?: string,
    organizationId: string,
    language: string,
    city: string,
    vehicles: Vehicle[],
    addresses: Address[],
    accounts: any[],
    customerTypeData?: CustomerTypeData,
    mustBeFilled?: boolean;
};

// UTILS
export const personToString = (person: Person) => {
    const fullName = `${person.firstname} ${person.lastname}`.trim();

    const result = person.organization
        ? person.organization + ' ' + (fullName ? `(${ fullName })` : '')
        : fullName;

    return result.trim();
};

// FORMATING
export const apiPersonToPerson: Formater<ApiPersonResult, Person> = apiPerson => ({
    ...apiPerson,
    designation: personToString(apiPerson as any as Person),
    vehicles: (apiPerson.vehicles || []).map(apiVehicleToVehicle),
});
