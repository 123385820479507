import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {SessionState} from "../index";

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = void;

export const SessionStartCustomerConnectionProcessAction = newAction<ActionPayload, SessionState, ActionCreatorPayload>({
    type: "/session/start-customer-connection-process",
    create: (type, payload) => ({
        type
    }),
    reducer: (action, state) => ({
        ...state,
        connection: {
            loading: true,
            persons: []
        }
    }),
});
