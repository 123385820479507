import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData} from "../index";

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = void;

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/previous",
    create: (type) => ({ type }),
    reducer: (action, state) => ({
        ...state,
        activeStepIndex: state.activeStepIndex - 1,
    })
});
