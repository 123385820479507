import {RightCreationFormState} from "../index";
import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {Right} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";
import {customerContractsPostRight} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/postRight";

export type RequestActionCreatorPayload = {
    label: string,
    reference?: string,
    validityStart: string,
    validityEnd: string,
    comments?: string,
    deliveryChannel: string, // "FRONTOFFICE"
    rightCatalogId: number | string,
    validityAreas: string[],
    profile: string,
    vehicleIds: number[],
    personId: number | string,
    allowsParking: boolean,
    priceIncludingVat: number,
    priceVatRate: number,
    pricePosition: number,
    parentRightId?: number | string,
    city: string
}

const actions = newRequestActions<RequestActionCreatorPayload, RightCreationFormState, Right>(
    "/create_right",
    customerContractsPostRight,
    (action, state) => ({
        ...state,
        options: {
            ...state.options,
            submittingRight: true,
        }
    }),
    (action, state) => ({
        ...state,
        options: {
            ...state.options,
            submittingRight: false,
        }
    }),
    (action, state) => ({
        ...state,
        options: {
            ...state.options,
            submittingRight: false,
        }
    })
);

export const CreateRightRequestAction = actions.request;
export const CreateRightSuccessAction = actions.success;
export const CreateRightFailureAction = actions.failure;
