import {RightCreationFormState} from "../index";
import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {Right} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";
import {customerContractsPutRightSubmit} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/putRightSubmit";
import * as lodash from 'lodash';

type RequestActionCreatorPayload = {
    rightId: string | number,
    date: string,
    channel: "FRONTOFFICE"
}

const actions = newRequestActions<RequestActionCreatorPayload, RightCreationFormState, Right>(
    "/right_creation/submit_right",
    (payload: RequestActionCreatorPayload) => {
        const newPayload = lodash.omit(payload, 'rightId');

        return customerContractsPutRightSubmit(payload.rightId, newPayload);
    },
    (action, state) => ({
        ...state,
    }),
    (action, state) => ({
        ...state,
    }),
    (action, state) => ({
        ...state,
    })
);

export const SubmitRightToApprobationRequestAction = actions.request;
export const SubmitRightToApprobationSuccessAction = actions.success;
export const SubmitRightToApprobationFailureAction = actions.failure;
