import {objectsAreEqual} from "../../../../utils/comparaisons";
import {CurrentRoute, initialState} from "./state";
import {coreConfiguration} from "../../../../internal/coreConfiguration";
import {Route, RouteGroup} from "../../index";

export const computeCurrentRoute = (routingProps: any, previousRouting: CurrentRoute | undefined): CurrentRoute => {
    if (!routingProps || !routingProps.location) {
        return initialState.currentRoute;
    }

    const {location, match} = routingProps;

    const query = parseQuery(location.search);

    const routeConfig = findRouteConfig(match.path, match.params, query);

    const nextRouting = {
        actualPath: location.pathname,
        definedPath: match.path,
        params: match.params,
        query: query,
        hash: location.hash,
        name: routeConfig?.name,
        private: routeConfig?.private,
    };

    const changed = !previousRouting || !objectsAreEqual(previousRouting, nextRouting);

    return changed ? nextRouting : previousRouting as CurrentRoute;
};

// https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript ANSWER 2
export function parseQuery(queryString?: string): {[key: string]: string} {
    if (! queryString) {
        return {};
    }

    var query: any = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

// On ne prend que ces infos, pcq name pour le nom de la route et on a besoin de private
type RouteConfig = {
    name: string,
    private: boolean,
};

const findRouteConfig = (definedPath: string, params: {[key: string]: string}, query: {[key: string]: string}): RouteConfig | undefined => {
    for (let key of Object.keys(coreConfiguration.routing)) {
        const routeOrRouteGroup: any = coreConfiguration.routing[key];

        if (routeOrRouteGroup.routes === undefined) {
            const route: Route = routeOrRouteGroup;

            if (route.path === definedPath) {
                return {
                    name: getRouteName(route, params, query),
                    private: routeOrRouteGroup.private,
                };
            }
        } else {
            const routeGroup: RouteGroup = routeOrRouteGroup;

            for (let key of Object.keys(routeGroup.routes)) {
                const route: Route = routeGroup.routes[key];

                if (route.path === definedPath) {
                    return {
                        name: getRouteName(route, params, query),
                        private: routeOrRouteGroup.private,
                    };
                }
            }
        }
    }

    return undefined;
};

export const getRouteName = (route: Route, params: {[key: string]: string}, query: {[key: string]: string}): string => {
    let name = route.name;

    for (let args of [params, query]) {
        for (let key of Object.keys(args)) {
            name = name.replace(':' + key, args[key]);
        }
    }

    return name;
};
