import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {askMailConfirmationSelectors, AskMailConfirmationState} from "../index";
import {selectCurrentRoute} from "../../../../core-react/src/modules/routing/redux/navigation/state";

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = void;

export const AskMailConfirmationArrivedAction = newAction<ActionPayload, AskMailConfirmationState, ActionCreatorPayload>({
    type: "/ask-mail-confirmation/arrived",
    create: (type, payload) => ({
        type
    }),
    reducer: (action, state) => {
        return state;

        /* OOPS pas possible on a pas globalState ici
        const source = selectCurrentRoute(state).query.from;
        if (source !== 'login' && source !== 'register') {
            return state;
        }

        const currentTitle = askMailConfirmationSelectors(state).title();
        const newTitle = source === 'login'
            ? 'Your e-mail needs to be confirmed'
            : 'Your account has been created';

        return currentTitle === newTitle
            ? state
            : {
                ...state,
                title: newTitle
            }

         */
    }
});
