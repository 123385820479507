import {listener} from "../../../core-react/src/utils/redux/middlewares/listeningMiddleware";
import {MailConfirmationState, registerConfirmationStateKey} from "./index";
import {SessionConnectAccountAction} from "../session/actions/connectAccount";
import {MailConfirmationArrivedAction} from "./actions/arrived";
import {MailConfirmationUndefinedParametersAction} from "./actions/undefinedParameters";
import {CoreNotify} from "../../../core-react/src/modules/notifications/redux/state";
import {
    MailConfirmationFailureAction,
    MailConfirmationRequestAction,
    MailConfirmationSuccessAction
} from "./actions/request";
import {navigator} from "../../../services/navigator";
import {sessionSelectors} from "../session";
import {getTranslation} from "../../../translations/useTranslation";

export default () => {
    listener.onActionEnd(MailConfirmationArrivedAction, (dispatch, state, action) => {
        const translations = getTranslation();
        const {error, loading, confirmed}: MailConfirmationState = state[registerConfirmationStateKey];

        // si account confirmed ou si loading ou error on ne fait rien
        if (confirmed || loading || error) {
            return;
        }

        const urlParams = readUrlParams();
        if (! urlParams) {
            dispatch(
                CoreNotify.failure(translations.confirmationDataIsAbsent)
            );
            dispatch(
                MailConfirmationUndefinedParametersAction()
            );
            return;
        }

        const {id, code} = urlParams;

        dispatch(
            MailConfirmationRequestAction({ code, id })
        );
    });

    listener.onActionEnd(MailConfirmationSuccessAction, (dispatch, state, action) => {
        const urlParams = readUrlParams();

        const {id, token} = urlParams as {id: string, token: string};

        dispatch(
            SessionConnectAccountAction({ token })
        );
    });

    listener.onActionEnd(MailConfirmationFailureAction, (dispatch, state, action) => {
        const err = action.apiCallResult.data;
        const isConnected = sessionSelectors.accountIsConnected(state);

        // Si email déjà valide rediriger vers le login page si pas connecté sinon redirigé vers private home
        if (err === "Email is already valid") {
            isConnected
                ? navigator().PrivateHome()
                : navigator().PublicHome()
            ;
        }
    });
};

const readUrlParams = () => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);

    if (! (queryParams.has('code') && queryParams.has('accountId') && queryParams.has('token'))) {
        return;
    }

    const code = queryParams.get('code') as string;
    const id = queryParams.get('accountId') as string;
    const token = queryParams.get('token') as string;

    return {
        id, code, token
    };
};
