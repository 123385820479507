import {put, takeEvery} from "redux-saga/effects";
import {UiBPayPaymentState} from "./index";
import {routes} from "../../../../configuration/routes";
import {CoreNavigationDemand} from "../../../../core-react/src/modules/routing/redux/navigation/actions";

export default function* sagas() {
    yield takeEvery(
        UiBPayPaymentState.actions.payUsingBPaySuccessAction.type,
        function* () {
            console.log('success')
            yield put(
                CoreNavigationDemand({
                    route: routes().BPayMessage as any
                })
            )
        }
    );
}
