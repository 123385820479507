import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbAliasesStateData} from "../index";
import {enduserPaymentCybersourcePutAliasMain} from "../../../../../core-react/src/modules/api/utils/xts/enduser-payment-cybersource/putAliasMain";
import {formatAliases} from "../utils";

type RequestActionCreatorPayload = {
    id: string
}

export default newRequestActions<RequestActionCreatorPayload, DbAliasesStateData, void>(
    "/db/aliases/mask-as-main",
    payload => enduserPaymentCybersourcePutAliasMain(payload.id),
    (action, state) => ({
        ...state,
    }),
    (action, state) => {
        const id = action.apiCallResult.request.data.id;
        const byId = state.byId;

        // Un"main" all aliases except target id
        const aliases = Object.keys(byId).map(
            k => {
                const aliasData = byId[k];
                const { alias } = aliasData;

                return {
                    ...alias,
                    isMain: id === alias.id.toString(),
                }
            }
        );

        return {
            ...state,
            byId: formatAliases(aliases)
        }
    },
    (action, state) => ({
        ...state,
    })
);
