import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {ApiCall} from "../../../index";
import {FranceConnectData} from "./modeling/france-connect";

const path = '/france-connect/:city/authentication-url';

export default (city: string): ApiCall<FranceConnectData> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path.replace(':city', city),
    method: 'GET',
    formater: r => r,
    tokenRepository: "xts-or-xts-account"
});
