import {reducerFromActions} from "../../../../utils/redux/reducerBuilder";
import {registerNavigationListeners} from "./listeners";
import {
    CoreNavigationDemand,
    CoreNavigationHistoryChange,
    CoreNavigationPageChanged
} from "./actions";

export type CoreNavigationState = {
    currentRoute: CurrentRoute,
    history: HistoryItem[]
};

export type HistoryItem = {
    actualPath: string,
    definedPath: string,
    args: any,
    key: string,
    confirmReplacement: boolean,
    name: string
};

export type CurrentRoute = {
    actualPath: string, // /faultForm/2
    definedPath: string, // /faultForm/:id
    query: {
        [key: string]: string
    },
    params: {
        [key: string]: string
    },
    name?: string,
    private?: boolean,
};

export const initialState: CoreNavigationState = {
    currentRoute: { actualPath: '/', definedPath: '/', query: {}, params: {} }, // On met home en dur, mais sera overwrite anyway
    history: []
};

export const coreNavigationStateKey = 'core_navigation';

const selectState = (state: any): CoreNavigationState => state[coreNavigationStateKey];
export const selectCurrentRoute = (state: any) => selectState(state).currentRoute;
export const selectHistory = (state: any) => selectState(state).history;

export const coreNavigationReducer = reducerFromActions(initialState,
    CoreNavigationPageChanged,
    CoreNavigationDemand,
    CoreNavigationHistoryChange,
);

registerNavigationListeners();
