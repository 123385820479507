/**
 * Created by priano on 31/08/2020 at 13:31.
 */
import {Person} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/person";
import {Vehicle} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/vehicle";
import {RightCatalog} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right-catalog";
import {Area} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/area";
import {Profile} from "../../../core-react/src/modules/api/utils/xts/city/modeling/profile";
import {ComputeDatesValidityRequestAction, ComputeDatesValiditySuccessAction, ComputeDatesValidityFailureAction} from "./actions/ComputeDatesValidityAction";
import {reducerFromActions} from "../../../core-react/src/utils/redux/reducerBuilder";
import {registerRightCreationFormListeners} from "./listeners";
import {RightCreationFormArrivedAction} from "./actions/RightCreationFormArrived";
import {
    FetchProfilesFailureAction,
    FetchProfilesRequestAction,
    FetchProfilesSuccessAction
} from "./actions/FetchProfilesAction";
import {
    FetchRightCatalogsFailureAction,
    FetchRightCatalogsRequestAction,
    FetchRightCatalogsSuccessAction
} from "./actions/FetchRightCatalogsAction";
import {RightCatalogValidity} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right-catalog-validity";
import {
    FetchVehicleFailureAction,
    FetchVehicleRequestAction,
    FetchVehicleSuccessAction
} from "./actions/FetchVehicleAction";
import {
    CreateRightFailureAction,
    CreateRightRequestAction,
    CreateRightSuccessAction
} from "./actions/CreateRightAction";
import {RequiredProof} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/required-proof";
import {
    FetchRequiredProofsFailureAction,
    FetchRequiredProofsRequestAction,
    FetchRequiredProofsSuccessAction
} from "./actions/FetchRequiredProofsAction";
import {
    UploadProofActionFailureAction,
    UploadProofActionRequestAction,
    UploadProofActionSuccessAction
} from "./actions/UploadProofAction";
import {RegisterProofForUploadAction} from "./actions/RegisterProofForUpload";
import {
    SubmitRightToApprobationFailureAction,
    SubmitRightToApprobationRequestAction,
    SubmitRightToApprobationSuccessAction
} from "./actions/SubmitRightToApprobation";
import {ResetRightCreationFormAction} from "./actions/ResetRightCreationForm";

export type RightCatalogWithProfile = RightCatalog & { profile: Profile };
export type ProfileWithRightCatalogs = Profile & { rightCatalogs: Array<RightCatalogWithProfile> };

export type RightCreationFormValueState = {
    person?: Person,
    vehicle?: Vehicle,
    selectedRightCatalog?: RightCatalog,
    selectedVariation?: Area,
    validityPeriodResult?: RightCatalogValidity,

    // Data Utile pour le renouvellement des droits
    reference?: string,
    parentRightId?: string,
}

export type RightCreationFormOptionsState = {
    vehicleLoading: boolean,
    vehicleLoaded: boolean,
    profiles?: Array<ProfileWithRightCatalogs>,
    profilesLoading?: boolean,
    profilesLoaded?: boolean,
    rightCatalogsLoading?: boolean,
    rightCatalogsLoaded?: boolean,
    datesValidity?: {
        validityStart: Date;
        validityEnd: Date;
        readOnlyStart: boolean,
        readOnlyEnd: boolean,
    },
    computingDatesValidity?: boolean,
    datesValidityComputed?: boolean,
    requiredProofsLoading: boolean,
    requiredProofsLoaded: boolean,
    requiredProofs: RequiredProof[],
    proofsToUpload: string[], // List des codes de proof to upload
    submittingRight?: boolean,
}

export type RightCreationFormState = {
    value: RightCreationFormValueState,
    options: RightCreationFormOptionsState,
};

export const initialState: RightCreationFormState = {
    value: {},
    options: {
        vehicleLoading: false,
        vehicleLoaded: false,
        profiles: [],
        profilesLoading: false,
        profilesLoaded: false,
        rightCatalogsLoading: false,
        rightCatalogsLoaded: false,
        computingDatesValidity: false,
        datesValidityComputed: false,
        requiredProofsLoading: false,
        requiredProofsLoaded: false,
        requiredProofs: [],
        proofsToUpload: [],
        submittingRight: false,
    }
};

export const rightCreationFormStateKey = 'right_creation';

export const selectState = (state: any): RightCreationFormState => {
    return state[rightCreationFormStateKey];
}

export const rightCreationStateReducer = reducerFromActions(
    initialState,
    RightCreationFormArrivedAction,
    FetchVehicleRequestAction,
    FetchVehicleSuccessAction,
    FetchVehicleFailureAction,
    FetchProfilesRequestAction,
    FetchProfilesSuccessAction,
    FetchProfilesFailureAction,
    FetchRightCatalogsRequestAction,
    FetchRightCatalogsSuccessAction,
    FetchRightCatalogsFailureAction,
    ComputeDatesValidityRequestAction,
    ComputeDatesValiditySuccessAction,
    ComputeDatesValidityFailureAction,
    FetchRequiredProofsRequestAction,
    FetchRequiredProofsSuccessAction,
    FetchRequiredProofsFailureAction,
    RegisterProofForUploadAction,
    UploadProofActionRequestAction,
    UploadProofActionSuccessAction,
    UploadProofActionFailureAction,
    CreateRightRequestAction,
    CreateRightSuccessAction,
    CreateRightFailureAction,
    SubmitRightToApprobationRequestAction,
    SubmitRightToApprobationSuccessAction,
    SubmitRightToApprobationFailureAction,
    ResetRightCreationFormAction,
)

registerRightCreationFormListeners();
