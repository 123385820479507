import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData} from "../index";
import {RequiredProof} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/required-proof";
import {customerContractsPostRightCatalogRequiredProof} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postRightCatalogRequiredProof";
import {proofId} from "../../../../../components/content/domain/right-creation-workflow/utils";

export type RequestActionCreatorPayload = {
    deliveryChannel?: string,
    limit?: string,
    offset?: string,
    startDate?: string,
    endDate?: string,
    vehicleId?: number | string,
    personId?: number | string,
    rightCatalogId?: number | string,
    city: string
}

export default newRequestActions<RequestActionCreatorPayload, UiRightCreationWorkflowStateData, RequiredProof[]>(
    "/ui/right-creation/fetch_required_proofs",
    customerContractsPostRightCatalogRequiredProof,
    (action, state) => ({
        ...state,
        requiredProofsStatus: 'loading',
    }),
    (action, state) => ({
        ...state,
        requiredProofs: formatRequiredProofs(action.apiCallResult.data),
        requiredProofsStatus: 'fetched',
    }),
    (action, state) => ({
        ...state,
        requiredProofsStatus: 'failed',
    })
);

const formatRequiredProofs = (proofs: RequiredProof[]) => {
    const result: { [key: string]: RequiredProof } = {};

    proofs.forEach(p => {
        const id = proofId({
            code: p.proofCatalog.code,
            label: p.proofCatalog.label
        });

        result[id] = p;
    });

    return result;
};

