import {Formater} from "../../../common/modeling";

type ApiAreaResult = {
    id: string,
    label: string,
    label_i18n: {
        [key: string]: string,
        _id: string,
    },
    city: string,
    type: string,
    color: string,
    polygon: string,
    lat: string,
    lng: string,

    created: string,
    updated: string,
    deleted: string,
}

export type Area = {
    id: string,
    label: string,
    label_i18n: {
        [key: string]: string,
        _id: string,
    },
    city: string,
    type: string,
    color: string,
    polygon: string,
    lat: string,
    lng: string,
}

export const apiAreaToArea: Formater<ApiAreaResult, Area> = a => a;