import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiProvidedProof, UiRightCreationWorkflowStateData, UiVehicle} from "../index";
import {proofId} from "../../../../../components/content/domain/right-creation-workflow/utils";

type ActionPayload = {
    type: string,
    code: string,
    label: string,
};

type ActionCreatorPayload = {
    code: string,
    label: string,
};

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/remove-provided-proof",
    create: (type, { code, label }) => ({ type, code, label }),
    reducer: (action, state) => {
        const key = proofId({
            code: action.code,
            label: action.label
        });

        const providedProofs = {
            ...state.providedProofs,
        };

        delete providedProofs[key];

        return {
            ...state,
            providedProofs
        }
    }
});
