/**
 * Created by priano on 31/08/2020 at 16:39.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {apiRightCatalogValidityResultToRightCatalogValidity} from "./modeling/right-catalog-validity";

const path = "/rightCatalog/:id/computeValidityDates";

type Payload = {
    rightCatalogId: number | string,
    startDate?: string,
};

export const customerContractsComputeValidityDates = (payload: Payload): ApiCall<any> => {
    const urlParameters: any = {};
    if (payload.rightCatalogId) {
        urlParameters.id = payload.rightCatalogId;
    }
    if (payload.startDate) {
        urlParameters.startDate = payload.startDate;
    }

    return {
        url: coreConfiguration.api.xts.customerContractsUrl + path,
        urlParameters,
        method: 'GET',
        formater: apiRightCatalogValidityResultToRightCatalogValidity,
        tokenRepository: 'xts',
    }
};
