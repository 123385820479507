import {Store} from "redux";

/**
 * Created by Nicolas on 04/09/2020
 * Nicolas was born at Versailles, on 01/12/1993 and is the fruit of the union between a colombian woman and a french man.
 * Nicolas has had a funny life, with downs and ups, as everyone. But he did manage to find the way in. He shines by his philosophy and the way he always questions himself about what he did, about how the world is, how it will be, can Nicolas impact its changes
 */

/*
 * Le but est de delay tous les dispatchs des fonctions arrived, de sorte à ce que les dispatch des HOC
 * aient le temps de se faire et d'impacter le state.
 *
 * Notamment pour CorePage, qui doit dispatch le currentRoute afin que les arrived des enfants puissent le lire
 *
 * Note: Avant on hackait dans COrePage, et ne renderions l'enfant que 50ms après render du CorePage. Cela provoquait des blink à chaque chg de page
 *
 * Note: Le delay est très faible, même à 0 ca marche, car il suffit de placer l'arrived plus loin dans l'event loop.
 */

const DELAY = 5; // ms

export const delayArrivedsMiddleware = (store : Store|any) => (next : Function) => async (action : any) => {
    if (action.type.toString().endsWith('/arrived')) {
        return setTimeout(() => {
            next({
                ...action,
                _delayed: DELAY,
            });
        }, DELAY);
    }

    next(action);
};
