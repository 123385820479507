/**
 * Created by priano on 09/09/2020 at 09:15.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/proof/:id/attachement";

type Payload = {
    id: number | string,
}

export const customerContractsPostDownloadProof = ({ id }: Payload): ApiCall<any> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path.replace(':id', id as string),
    method: 'GET',
    formater: r => r,
    responseType: "blob",
    tokenRepository: "xts",
    meta: { id },
});
