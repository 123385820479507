import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {Person, apiPersonToPerson} from "./modeling/person";
import {CustomerTitleIdentifier} from "./modeling/customer-contracts-city";

const path = "/person/:id";

type Payload = {
    lastname: string;
    firstname: string;
    gender: CustomerTitleIdentifier;
    email: string;
    landphone: string;
    mobilephone: string;
    contactChannel: string;
    language: string;
    city: string;
    householdId?: string;
    externalId?: string;
    organization?: string;
    organizationId?: string;
    accountIds?: string[];
};

export const customerContractsPutPerson = (id: number|string, payload: Payload): ApiCall<Person> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: "PUT",
    urlParameters: {id},
    body: payload, // ou en url Param
    formater: apiPersonToPerson,
    tokenRepository: 'xts',
});
