import {Profile} from "../../../../core-react/src/modules/api/utils/xts/city/modeling/profile";
import {createSelector} from "reselect";
import {UiRightCatalog, UiVehicle} from "./index";

export const profileToString = (p: Profile) => {
    return (p.label_i18n && p.label_i18n._id) || p.label;
}
export const profileToId = (p: Profile) => {
    return p.id;
}

export const selectChoosedRightCatalogMaxVehicles = createSelector(
    (state: any) => state.rightCatalogs,
    (state: any) => state.choosedRightCatalogId,
    (rightCatalogs, id) => {
        const rightCatalog: UiRightCatalog = rightCatalogs.find((r: UiRightCatalog) => r.id === id);

        if ((! rightCatalog) || (! rightCatalog.maxVehicles)) {
            return -1;
        }

        return rightCatalog.maxVehicles;
    },
);

export const selectSelectedVehicles = createSelector(
    (state: any) => state.vehicles,
    (vehicles: { [key: string]: UiVehicle}) => {
        return Object.values(vehicles).filter((v: UiVehicle) => v.checked);
    }
);

export const selectUnselectedVehicles = createSelector(
    (state: any) => state.vehicles,
    (vehicles: { [key: string]: UiVehicle}) => {
        return Object.values(vehicles).filter((v: UiVehicle) => (!v.checked));
    }
);

export const formatVehicles = (vehicles: UiVehicle[]) => {
    const result: { [key: string]: UiVehicle } = {};

    vehicles.forEach(vehicle => {
        result[vehicle.id] = vehicle;
    });

    return result;
};

// Fonction qui map un vehicule en un UiVehicule
export function mapVehicleToUiVehicle(v: any): UiVehicle {
    return ({
        id: v.id.toString(),
        plate: v.plate,
        model: v.model,
        brand: v.brand,
        driver: v.driver && `${ v.driver.firstname } ${ v.driver.lastname }`,
        status: 'checking',
        checked: false,
    })
}
