import React from "react";

type Page = () => React.ReactNode;

export type CityPages = {
    // Public pages
    PublicHome: Page,
    Register: Page,
    AskAccountValidation: Page,
    AccountValidation: Page,
    CustomerCreate: Page,
    ChooseCustomer: Page,
    AskChangePassword: Page,
    ChangePassword: Page,
    TermsOfSale: Page,
    TermsOfService: Page,
    LegalNotices: Page,
    FrequentlyAskedQuestions: Page,
    FranceConnectCallback?: Page,
    CompleteProfile?: Page,

    // Private
    PrivateHome: Page,
    CreateVehicle: Page,
    EditVehicle: Page,
    CreateRight: Page,
    CustomerEdit: Page,
    InvoicesList: Page,
    Account: Page,
    PaymentResult: Page,
    Invoice: Page,
    PermitsList: Page,
    PaymentAliasList: Page,
    VehiclesList: Page,
    ProofsList: Page,
    PrepaidAccountPayment?: Page,
    BPayMessage?: Page
}

export type CityConfiguration = {
    // Name of the city for the back-end. Shall not be used for UI.
    id: string,

    // Language use for translation for the city. default browserLanguage
    language?: string,

    // use pour activer ou non l'autoskip dans le workflow de création de droit
    rightWorkflowAutoSkipEnabled?: boolean,
} & CityPages;

let _currentCity: CityConfiguration|undefined;

export const registerCurrentCity = async (forcedCity?: string) => {
    if (!forcedCity && process.env.REACT_APP_FORCED_CITY && process.env.NODE_ENV !== 'production') {
        forcedCity = process.env.REACT_APP_FORCED_CITY;
    }

    const urlHas = (name: string) => window.location.href.toUpperCase().indexOf(name.toUpperCase()) > -1;

    const city = [
        forcedCity,
        process.env.NODE_ENV !== 'production' && process.env.REACT_APP_FORCED_CITY,
        urlHas('mount-prospect') && 'MountProspect',
        urlHas('PENSACOLA') && 'pensacola',
        urlHas('WINTERFELLEN') && 'winterfellen',
        urlHas('WINTERFELL') && 'winterfell',
        urlHas('UNIVERSITY-NEW-ENGLAND') && 'universityNewEngland',
        urlHas('Middelkerke') && 'middelkerke',
        urlHas('Versailles') && 'versailles',
        urlHas('Sacramento') && 'sacramento',
        'winterfellen'
    ].filter((option): option is string => !!option)[0];

    try {
        _currentCity = (await import('./' + city + '.tsx')).default;
    } catch (e) {
        alert("Could not find '" + city + "'");
    }
};

export const currentCity = (): CityConfiguration => _currentCity as CityConfiguration;

export const currentPrefix = () => {
    const pathName = window.location.pathname;
    const subDirectoriesHosts = [
        'us.my-parking-assistant.com',
        'fr.my-parking-assistant.com',
        'eu.my-parking-assistant.com',
        'prod3.my-parking-assistant.com',
        'prod2.my-parking-assistant.com',
        'prod1.my-parking-assistant.com',
        'prod.my-parking-assistant.com',
        'xts3.my-parking-assistant.com',
        'xts2.my-parking-assistant.com',
        'xts1.my-parking-assistant.com',
        'xts0.my-parking-assistant.com',
        'xtsdev.my-parking-assistant.com',
        'xts.my-parking-assistant.com',
        //'localhost:3001'
    ];

    if (subDirectoriesHosts.indexOf(window.location.host) === -1) {
        return '';
    }

    const pathname = window.location.pathname;
    const subDirectory = '/' + pathname.split('/')[1]; // /pensacola

    return subDirectory;
}
