import {applyMiddleware, combineReducers, createStore, Store} from "redux";
import {composeWithDevTools} from 'redux-devtools-extension';
import {routingMiddleware} from "../core-react/src/modules/routing/redux/router/middleware";
import {listeningMiddleware} from "../core-react/src/utils/redux/middlewares/listeningMiddleware";
import {apiMiddleware} from "../core-react/src/modules/api/redux/middleware";
import {coreSessionReducer, coreSessionStateKey} from "../core-react/src/modules/session/redux/state";
import {persistanceMiddleware} from "../core-react/src/modules/persistance/redux/middleware";
import {coreNotificationsReducer, coreNotificationsStateKey} from "../core-react/src/modules/notifications/redux/state";
import {coreRouterReducer, coreRouterStateKey} from "../core-react/src/modules/routing/redux/router/state";
import {coreNavigationReducer, coreNavigationStateKey} from "../core-react/src/modules/routing/redux/navigation/state";
import {registerFormSlice} from "./modules/register-form";
import {loginFormSlice} from "./modules/login-form";
import {profileFormSlice} from "./modules/profile-form";
import {mailConfirmationReducer, registerConfirmationStateKey} from "./modules/mail-confirmation";
import {homeContentReducer, homeContentStateKey} from "./modules/home-content";
import {vehicleFormSlice} from "./modules/vehicle-form";
import {sessionReducer, sessionStateKey} from "./modules/session";
import {askMailConfirmationReducer, askMailConfirmationStateKey} from "./modules/ask-mail-confirmation";
import {rightCreationFormStateKey, rightCreationStateReducer} from "./modules/right-creation-form";
import {delayArrivedsMiddleware} from "./middlewares/delayArrivedMiddlewares";
import {VehicleFormDependencies} from "./modules/vehicle-form/dependencies";
import {tokenExpiredMiddleware} from "./middlewares/tokenExpiredMiddleware";
import {DbPersonState} from "./modules_clean/db/person";
import {DbVehiclesState} from "./modules_clean/db/vehicles";
import {UiPasswordChangeDemandState} from "./modules_clean/ui/password-change-demand";
import {UiPasswordChangeFormState} from "./modules_clean/ui/password-change-form";
import {DbRightsState} from "./modules_clean/db/rights";
import {DbInvoicesState} from "./modules_clean/db/invoices";
import {DbAliasesState} from "./modules_clean/db/aliases";
import {UiProfileFormState} from "./modules_clean/ui/profile-form";
import createSagaMiddleware from 'redux-saga'
import {UiAccountEmailChange} from "./modules_clean/ui/account-email-change-form";
import {UiAccountPasswordChange} from "./modules_clean/ui/account-password-change-form";
import { all } from "redux-saga/effects";
import {DbVehicleDriversState} from "./modules_clean/db/vehicleDrivers";
import {UiVehiclesState} from "./modules_clean/ui/vehicles";
import {DbPaymentsState} from "./modules_clean/db/payments";
import initializationSagas from "./modules_clean/ui/initialization/sagas";
import rightCreationSagas from "./modules/right-creation-form/sagas";
import franceConnectConfirmationSagas from "./modules/france-connect/sagas";
import {DbProfilesState} from "./modules_clean/db/profiles";
import {UiRightCreationWorkflowState} from "./modules_clean/ui/right-creation-workflow";
import {BusinessCityConfigurationState} from "./modules_clean/configuration/businessCustomer";
import {OrderConfigurationState} from "./modules_clean/configuration/order";
import {CustomerContractsState} from "./modules_clean/configuration/customerContracts";
import {DbProofsState} from "./modules_clean/db/proofs";
import {FranceConnectState} from "./modules/france-connect";
import {DbPrepaidAccountState} from "./modules_clean/db/prepaidAccount";
import errorMessageSagas from "./modules_clean/ui/error-message/sagas";
import {SessionDisconnectAction} from "./modules/session/actions/disconnect";
import {UiLoginAttemptFailureState} from "./modules_clean/ui/loginAttemptFailure";
import navigationSagas from "./modules_clean/ui/navigation/sagas";
import { LogsState } from './modules/logs';
import LogsSagas from './modules/logs/sagas';
import logConsoleError from "./modules/logs/actions/logConsoleError";
import {UiBPayPaymentState} from "./modules_clean/ui/b-pay";
import {DbAreaState} from "./modules_clean/db/areas";

const modules: { stateKey: string, reducer: any }[] = [
    DbPersonState,
    DbVehiclesState,
    DbRightsState,
    DbInvoicesState,
    DbAliasesState,
    DbVehicleDriversState,
    DbPaymentsState,
    DbProfilesState,
    UiPasswordChangeFormState,
    UiPasswordChangeDemandState,
    UiProfileFormState,
    UiAccountEmailChange,
    UiAccountPasswordChange,
    UiVehiclesState,
    UiRightCreationWorkflowState,
    BusinessCityConfigurationState,
    OrderConfigurationState,
    CustomerContractsState,
    DbProofsState,
    FranceConnectState,
    DbPrepaidAccountState,
    UiLoginAttemptFailureState,
    LogsState,
    UiBPayPaymentState,
    DbAreaState,

];

function* rootSaga() {
    yield all([
        UiProfileFormState.sagas(),
        UiRightCreationWorkflowState.sagas(),
        initializationSagas(),
        rightCreationSagas(),
        franceConnectConfirmationSagas(),
        errorMessageSagas(),
        navigationSagas(),
        LogsSagas(),
        UiBPayPaymentState.sagas(),
        DbRightsState.sagas(),
    ])
}

const moduleReducers: {
    [key: string]: any
} = {};

modules.forEach(module => {
    moduleReducers[module.stateKey] = module.reducer;
});

const appReducer = combineReducers({
    [coreRouterStateKey]: coreRouterReducer,
    [coreNavigationStateKey]: coreNavigationReducer,
    [coreSessionStateKey]: coreSessionReducer,
    [coreNotificationsStateKey]: coreNotificationsReducer,

    [askMailConfirmationStateKey]: askMailConfirmationReducer,
    [registerFormSlice.stateKey]: registerFormSlice.reducer,
    [loginFormSlice.stateKey]: loginFormSlice.reducer,
    [profileFormSlice.stateKey]: profileFormSlice.reducer,
    [sessionStateKey]: sessionReducer,

    [registerConfirmationStateKey]: mailConfirmationReducer,
    [homeContentStateKey]: homeContentReducer,

    [vehicleFormSlice.stateKey]: vehicleFormSlice.reducer,
    [VehicleFormDependencies.stateKey]: VehicleFormDependencies.reducer,

    [rightCreationFormStateKey]: rightCreationStateReducer,
    [DbAreaState.stateKey]: DbAreaState.reducer,

    ...moduleReducers,
})

const rootReducer = (state: any, action: any) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === SessionDisconnectAction.type) {
        state = undefined;
    }

    return appReducer(state, action);
};

export const configureStore = (initialState: any = {}): Store => {
    // create the saga middleware
    const sagaMiddleware = createSagaMiddleware()

    const store = createStore(
        rootReducer,
        initialState,
        composeWithDevTools({trace: true})(
            applyMiddleware(
                delayArrivedsMiddleware,
                routingMiddleware,
                listeningMiddleware,
                apiMiddleware,
                persistanceMiddleware,
                tokenExpiredMiddleware,
                sagaMiddleware,
            ),
        ),
    );

    // wrapp console.error ici pour pouvoir utiliser le store
    const consoleError = console.error;

    console.error = (...args: any[]) => {
        if (args[0] && args[2] && String(args[0]).indexOf('two children') > -1 && String(args[2]).indexOf('created by Calendar') > -1) {
            return console.log('%cIgnored Prime-Calendar error', 'color: darkgrey;');
        }

        // Dispatch pour logger l'erreur console
        store.dispatch(
            logConsoleError({
                args,
            })
        );

        consoleError(...args);
    };

    // then run the saga
    sagaMiddleware.run(rootSaga);

    return store;
}
