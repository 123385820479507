import {all, put, takeEvery} from "redux-saga/effects";
import {getTranslation} from "../../../../translations/useTranslation";
import {CoreNotify} from "../../../../core-react/src/modules/notifications/redux/state";
import {loginFormSlice} from "../../../modules/login-form";

export default function* sagas() {
    yield all ([
        watchError(),
    ])
}

function* watchError() {
    yield takeEvery(
        (action: any) => /failure$/.test(action.type),
        function* (action: any) {
            // On garde seulement les actions possédant le payload apiCallResult
            if (!action.apiCallResult) {
                return;
            }

            // Recherche d'un error handler qui pourrait prendre la responsabilité de handle l'error sus-nommé
            const errorHandler = errorHandlers.find(handler => handler.support(action));

            const { data: { error }, statusCode } = action.apiCallResult;

            // Si responsable trouvé on le laisse handle, sinon on utilise le hanlder par défaut
            yield (errorHandler ? errorHandler.handleError(statusCode, error) : handleError(statusCode, error));
        }
    )
}

// Configuration des errorHandlers et de leur responsabilité ❤️
const errorHandlers: Array<{ support: (action: any) => boolean, handleError: (...args: any[]) => Generator }> = [
    {
        support(action: any) {
            const { _fromListeningToActionEnd: fromAction, apiCallResult: { data: { status: statusCode } } } = action;
            return (
                fromAction === loginFormSlice.actions.submitRequest.type &&
                [403, 401].includes(statusCode)
            );
        },
        handleError: function* () {
            const translation = getTranslation();

            let msgTitleError = translation.loginError;
            let msgError = translation.invalidCredentials;

            yield put(CoreNotify.failure(msgTitleError, msgError));
        }
    },
    {
        support(action: any) {
            const { _fromListeningToActionEnd: fromAction, apiCallResult: { data: { status: statusCode } } } = action;
            return (
                fromAction === loginFormSlice.actions.submitRequest.type &&
                statusCode === 422 // Seul 422 existant "NOT_CONFIRMED_ACCOUNT" donc on estime (Priano et moi-même) que ça suffit
            );
        },
        handleError: function* () {}
    }
]

/**
 * Gestion des errors par défaut
 * @param statusCode
 * @param error
 */
function* handleError(statusCode: number, error: string) {

    // On récupère la langue courante de l'app
    const translation = getTranslation();

    let msgTitleError = translation.error;
    let msgError = translation.errorMesage[error] || "";

    if (statusCode !== 422 && statusCode !== 409) {
        msgTitleError = `${translation.error} ${statusCode}`;
        msgError = translation.anErrorHappened;
    }

    yield put(CoreNotify.failure(msgTitleError, msgError));
}
