import {ApiCall} from "../../../index";
import {configuration} from "../../../../../../../configuration/configuration";

const path = "/logs";

export type PostLogPayload = {
    city: string,
    creationDate: string,
    application: 'dev-xts-parking-enduser-foui' | 'xts-parking-enduser-foui',
    severity: 'error',
    processId: string,
    message: string,
    data: {
        [key: string]: any,
    },
    tag: 'uiErrors',
    stalk: {
        clientEmail: string,
    }
};

export const internalLogsPostLogs = (payload: PostLogPayload): ApiCall<any> => ({
    url: configuration().logging?.url + path,
    method: 'POST',
    body: payload,
    formater: (a: any) => a,
    tokenRepository: 'xts-or-xts-account',
});
