import {Route as ConfigRoute, routingHistory} from "../../index";
import {History} from "history";
import {connect} from "react-redux";
import {CoreRouter} from "./coreRouter";
import {coreConfiguration} from "../../../../internal/coreConfiguration";
import _ from "lodash";

export type CoreRouterProps = {
    routes: Route[],
    history: History
};

const makeMapStateToProps = () => {
    const routes = computeRoutes();

    return (state: any) => ({
        routes,
        history: routingHistory
    });
};

export const CoreRouterContainer = connect(makeMapStateToProps)(CoreRouter);

type Route = {
    key: string,
    component?: React.ComponentType,
    render?: () => React.ReactNode,
    path?: string,
    exact?: boolean,
    sensitive?: boolean,
    strict?: boolean
};

const computeRoutes = () => {
    const config = coreConfiguration.routing;

    const configRouteToRoute = (configRoute: ConfigRoute) => {
        return {
            key: configRoute.name + '__' + configRoute.path,
            component: configRoute.component,
            render: configRoute.render,
            path: configRoute.path,
            exact: !!configRoute.path,
        }
    };

    const routes = Object.keys(config).flatMap(key => {
        const routeOrRouteGroup = config[key] as any;

        if (routeOrRouteGroup.routes !== undefined) {
            return _.values(routeOrRouteGroup.routes).map(configRouteToRoute);
        }

        return [configRouteToRoute(routeOrRouteGroup)];
    }).filter(r => !!r);

    return _.uniqBy(routes, 'path') as Route[];
};
