import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/right/:id/vehicules";

type Payload = {
    date: Date,
    channel: string,
    vehiclesIds: (string|number)[],
};

export const customerContractsPutRightVehiculeAdd = (id: number|string, payload: Payload): ApiCall<any> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    urlParameters: {id},
    method: 'PUT',
    body: payload,
    formater: r => r,
    tokenRepository: 'xts',
});
