import {DbPaymentsState, DbPaymentsStateData} from "./index";
import {createSelector} from "reselect";
import {Payment} from "../../../../core-react/src/modules/api/utils/xts/order/modeling/payment";

const select = (state: any): DbPaymentsStateData => state[DbPaymentsState.stateKey];

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    all: (state: any) => select(state).byId,
    allAsArray: createSelector(
        (state: any) => select(state).byId,
        all => Object.values(all).map(v => v.payment) as Payment[]
    ),
    one: (id: string|number) => (state: any) => select(state).byId[id],
}
