import {CoreConfiguration} from "../index";
import {CoreLayoutConfiguration} from "../modules/layout";
import {CoreRoutingConfiguration} from "../modules/routing";
import {CoreApiConfiguration} from "../modules/api";
import {CorePersistanceConfiguration} from "../modules/persistance";
import {CoreThemeConfiguration} from "../modules/theme";
import {CoreFromPortalConnectionConfiguration} from "../modules/fromPortalConnection";
import {CoreNotificationsConfiguration} from "../modules/notifications";

let self: Configuration;

class Configuration {
    private config?: CoreConfiguration;

    constructor() { self = this }

    write(config: CoreConfiguration) {
        if (self.config) {
            console.warn("CoreConfiguration re-writed. It has not been made to change dynamicly but will probably work tho");
        }

        self.config = config;
    }

    get api() {
        return Configuration.getSubConfiguration<CoreApiConfiguration>('api');
    }

    get fromPortalConnection() {
        return Configuration.getSubConfiguration<CoreFromPortalConnectionConfiguration>('fromPortalConnection');
    }

    get layout() {
        return Configuration.getSubConfiguration<CoreLayoutConfiguration>('layout');
    }

    get notifications() {
        return Configuration.getSubConfiguration<CoreNotificationsConfiguration>('notifications');
    }

    get persistance() {
        return Configuration.getSubConfiguration<CorePersistanceConfiguration>('persistance');
    }

    get routing() {
        return Configuration.getSubConfiguration<CoreRoutingConfiguration>('routing');
    }

    get theme() {
        return Configuration.getSubConfiguration<CoreThemeConfiguration>('theme');
    }

    private static getSubConfiguration<T>(key: string): T {
        if (!self.config) {
            throw new Error(`Tried to read core ${key} configuration, but configuration has not been registered`);
        }

        if (undefined === (self.config as any)[key]) {
            throw new Error(`Tried to read core ${key} configuration, but ${key} has not been configured`);
        }

        return (self.config as any)[key] as T;
    }
}


export const coreConfiguration = new Configuration();
