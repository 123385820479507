/**
 * Created by priano on 14/10/2020 at 14:41.
 */
import {createFormSlice, FormState} from "../../../../core-react/src/utils/form/redux";
import {sessionSelectors} from "../../../modules/session";
import {customerContractsPatchPassword} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/patchAccountPassword";
import {CoreNotify} from "../../../../core-react/src/modules/notifications/redux/state";
import hooks from "./hooks";
import {getTranslation} from "../../../../translations/useTranslation";

export type AccountPasswordChangeFormData = {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const initialDto: AccountPasswordChangeFormData = {
    oldPassword:'',
    newPassword: '',
    confirmPassword: '',
}

const {actions, reducer, select, stateKey} = createFormSlice<AccountPasswordChangeFormData, any, FormState<AccountPasswordChangeFormData>, any>(
    "/update_password_form",
    "update_password_form",
    {
        fields: {
            oldPassword: { required: true, },
            newPassword: {
                required: true,
                validator: (dto: AccountPasswordChangeFormData) => {
                    const minLength = 6;
                    const translations = getTranslation();

                    return dto.newPassword && dto.newPassword.length <= minLength
                        ? [translations.passwordIsTooShort.replace('#minLength', minLength.toString())]
                        : [];
                }
            },
            confirmPassword: {
                required: true,
                validator: (dto: AccountPasswordChangeFormData) => {
                    const translations = getTranslation();

                    return dto.newPassword !== dto.confirmPassword
                        ? [translations.mustMatchFirstPassword]
                        : [];
                }
            }
        }
    }, initialDto, {
        // Dispatch via component à chaque render
        onArrived(dispatch, state, action) {

        },
        // Dispatch via component
        onReset(dispatch, state, action) {

        },
        // Dispatch via listeners
        onInitialize(dispatch, state, action) {
            dispatch(
                actions.initialized({
                    dto: initialDto,
                })
            );
        },
        onSubmit(dispatch, state, action) {
            const { submitting, dto } = select(state);

            if (! submitting) {
                return;
            }

            const {submitRequest} = actions;

            const accountId = sessionSelectors.accountId(state) as string;

            dispatch(
                submitRequest({
                    apiCall: customerContractsPatchPassword(accountId, {
                        password: dto.newPassword,
                    }),
                })
            );
        },
        onSubmitSuccess(dispatch, state, action) {
            const translations = getTranslation();

            dispatch(CoreNotify.success(translations.changesSaved));
        },
        onSubmitFailure(dispatch, state, action) {
            // return dispatch(CoreNotify.failure('An error occured'));
        }
    }
)

export const UiAccountPasswordChange = {
    actions, stateKey, reducer,
    initialDto, hooks,
    selectors: { state: select },
};

