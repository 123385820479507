import {Right} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbRightsStateData} from "../index";
import {customerContractsPutCancelRight} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/putCancelRight";

type RequestActionCreatorPayload = {
    id: string,
    cancellationDate: string;
    cancellationReason: string;
    cancellationChannel: string;
}

const actions = newRequestActions<RequestActionCreatorPayload, DbRightsStateData, Right>(
    "/db/rights/cancel-one",
    (payload) => {
        const { cancellationDate, cancellationReason, cancellationChannel } = payload;
        return customerContractsPutCancelRight(
            payload.id, {
                cancellationDate,
                cancellationReason,
                cancellationChannel
            }
        );
    },
    (action, state) => ({
        ...state,
    }),
    (action, state) => {
        const cancelledRight = action.apiCallResult.data;
        const rightId = cancelledRight.id;
        const byId = state.byId;
        const currentRight = byId[rightId];

        return {
            ...state,
            byId: {
                ...byId,
                [rightId]: {
                    loading: false,
                    right: {
                        ...currentRight,
                        ...cancelledRight,
                    }
                }
            }
        }
    },
    (action, state) => ({
        ...state,
    })
);

export default actions;
