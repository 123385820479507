/**
 * Created by priano on 25/08/2020 at 16:18.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {apiRightToRight, Right} from "./modeling/right";
import {apiPaginationToPagination, Pagination} from "../../common/modeling";

const path = "/right/search";

type Payload = {
    limit?: string,
    offset?: string,
    updatedSinceMax?: string,
    vehicleId?: string,
    plateContains?: string,
    state?: string,
    states?: string,
    reference?: string,
    referenceStartsWith?: string,
    labelContains?: string,
    rightCatalogLabelContains?: string,
    sortValidityEnd?: string,
    pricePosition?: string,
    containInNames?: string,
    personId?: string | number,
    externalId?: string,
    householdId?: string,
    addressStreet?: string,
    addressNumber?: string,
    addressPostalCode?: string,
    addressLocality?: string,
    addressDetails?: string,
    cities?: string,
    city?: string,
    id?: string | number,
    updatedSince?: string,
};

export const customerContractsSearchRights = (payload: Payload): ApiCall<Pagination<Right>> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    body: payload,
    method: 'POST',
    formater: apiPaginationToPagination(apiRightToRight),
    tokenRepository: 'xts',
});
