/**
 * Created by priano on 25/08/2020 at 10:58.
 */
import {Dispatch} from "react";
import { selectState } from ".";
import {FetchInvoiceRequestAction, FetchPersonRequestAction, FetchRightsRequestAction} from "./actions";
import {currentCity} from "../../../configuration/cities/_city";
import {saveAs} from 'file-saver';
import {sessionSelectors} from "../session";

export const homeContentArrived = (dispatch: Dispatch<any>, state: any, action: any) => {
    const { loading, loaded } = selectState(state);
    const personId = sessionSelectors.customerId(state) as number;

    if (loading || loaded) {
        return;
    }

    dispatch(
        FetchPersonRequestAction({ personId })
    )
}

export const homeContentFetchPersonSuccess = (dispatch: Dispatch<any>, state: any, action: any) => {
    const personId = sessionSelectors.customerId(state) as number;

    dispatch(
        FetchRightsRequestAction({ personId, city: currentCity().id })
    )
}

export const homeContentFetchRightsSuccess = (dispatch: Dispatch<any>, state: any, action: any) => {
    const { vehicles, loading } = selectState(state);

    for (const vehicle of vehicles) {
        for (const right of vehicle.rights) {
            dispatch(
                FetchInvoiceRequestAction({ productId: right.id, productType: 'Right' })
            )
        }
    }
}

export const homeContentPrintInvoiceSuccess = (dispatch: Dispatch<any>, state: any, action: any) => {
    const { data, request } = action.apiCallResult;

    saveAs(data, `invoice_${request.data.invoiceId}.pdf`);
}
