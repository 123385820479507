import {PasswordChangeData} from "./index";

export default {
    fields: {
        password: {
            required: true,
            validator: (dto: PasswordChangeData) => {
                const minLength = 6;

                return dto.password && dto.password.length <= minLength
                    ? [`Password is too short. Minimal length is ${minLength} characters`]
                    : [];
            }
        },
        confirmPassword: {
            required: false,
            validator: (dto: PasswordChangeData) => {
                return dto.password !== dto.confirmPassword
                    ? ['Must match first password']
                    : [];
            }
        }
    }
};
