import {listener} from "../../../../utils/redux/middlewares/listeningMiddleware";
import {
    CoreNavigationDemand,
    CoreNavigationHistoryChange,
    CoreNavigationRemoveHistoryItem
} from "./actions";
import {push} from "connected-react-router";
import {HistoryItem, selectHistory} from "./state";
import {objectsAreEqual} from "../../../../utils/comparaisons";
import {coreConfiguration} from "../../../../internal/coreConfiguration";
import _ from "lodash";
import {Route, RouteGroup} from "../../index";
import {CoreNotifyForConfirmation} from "../../../notifications/redux/state";
import React from "react";
import {getRouteName} from "./utils";

export const registerNavigationListeners = () => {
    listener.onActionEnd(CoreNavigationRemoveHistoryItem, (dispatch, state, action) => {
        const history = selectHistory(state);

        const nextHistory = history.filter(item => item.key !== action.itemKey);

        dispatch(CoreNavigationHistoryChange({
            history: nextHistory
        }));
    });

    listener.onActionEnd(CoreNavigationDemand, (dispatch, state, action) => {
        let route: Route = action.route;

        if (action.route.menu === 'alwaysShownAndLinksToHistoryRoute') {
            _.values(coreConfiguration.routing).forEach((v: Route | RouteGroup | any) => {
                if (v.routes !== undefined) {
                    _.values(v.routes).forEach((vv: Route) => {
                        if (vv.path === action.route.path && vv.menu === 'history') {
                            route = vv;
                        }
                    });
                } else if (v.path === action.route.path && v.menu === 'history') {
                    route = v;
                }
            })
        }

        let askConfirmation: undefined | ((nextHistory: any) => void);

        if (route.menu === 'history') {
            const previousHistory = selectHistory(state);
            const key = route.history?.key as string;
            const actionAsHistoryItem = {
                actualPath: action.path,
                definedPath: route.path as string,
                key: route.history?.key as string,
                confirmReplacement: route.history?.confirmReplacement as boolean,
                args: action.args,
                name: getRouteName(route, action.args, {})
            };

            let nextHistory = [];

            if (!previousHistory.filter(i => i.key === key).length) {
                nextHistory = [
                    ...previousHistory,
                    actionAsHistoryItem
                ];
            } else {
                for (let item of previousHistory) {
                    if (item.key !== key || item.actualPath === action.path) {
                        nextHistory.push(item);
                        continue;
                    }

                    nextHistory.push(actionAsHistoryItem);

                    if (!item.confirmReplacement) {
                        continue;
                    }

                    const oldName = item.name;
                    const newName = actionAsHistoryItem.name;

                    askConfirmation = (newHistory: any) => {
                        dispatch(CoreNotifyForConfirmation({
                            message: () => (
                                <>
                                    <p className={"mt-1"}> La page <b>{oldName}</b> est toujours ouverte.</p>
                                    <p style={{marginBottom: 0}}> Continuer vers la page <b>{newName}</b> ? </p>
                                </>
                            ),
                            onCancel: () => {},
                            onConfirm: () => {
                                dispatch(CoreNavigationHistoryChange({
                                    history: newHistory
                                }));
                                dispatch(push(
                                    action.path
                                ));
                            }
                        }))
                    };
                }
            }

            if (askConfirmation) {
                (askConfirmation as any)(nextHistory);
            } else if (!objectsAreEqual(nextHistory, previousHistory)) {
                dispatch(CoreNavigationHistoryChange({history: nextHistory}));
            }
        }

        if (!askConfirmation) {
            dispatch(
                push(action.path)
            );
        }
    });
};
