import {Payment} from "../../../../core-react/src/modules/api/utils/xts/order/modeling/payment";
import fetchAll from "./actions/fetchAll";
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import selectors from './selectors';


export type DbPaymentsStateData = {
    loading: boolean, // All rights being fetched
    byId: {
        [id: string]: {
            loading: boolean, // One right being fetched
            payment: Payment,
        }
    }
}

const initialState: DbPaymentsStateData = {
    loading: false,
    byId: {}
};

const actions = { fetchAll };

const stateKey = "db.payments";

const reducer = reducerFromActionsObject(initialState, actions);

export const DbPaymentsState = {
    actions, stateKey, reducer, selectors
};
