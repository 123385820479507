import { newAction } from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiLoginAttemptFailureStateData} from "../index";

type ActionPayload = {
    type: string,
    reason: string,
    email: string
};

type ActionCreatorPayload = {
    reason: string,
    email: string
};

export const SetFailedLoginAttempt = newAction<ActionPayload, UiLoginAttemptFailureStateData, ActionCreatorPayload>({
    type: "/ui/login-attempt-failure/set-failed-login-attempt",
    create: (type, payload) => ({
        type,
        ...payload,
    }),
    reducer: (action, state) => ({
        reason: action.reason,
        email: action.email,
    }),
});
