import {DbVehicleDriversStateData} from "../index";
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {currentCity} from "../../../../../configuration/cities/_city";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";
import {VehicleDriver} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/driver";
import {customerContractsPostVehicleDriverSearch} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postVehicleDriverSearch";

type RequestActionCreatorPayload = {
    personId: number|string
};

const actions = newRequestActions<RequestActionCreatorPayload, DbVehicleDriversStateData, Pagination<VehicleDriver>>(
    "/db/vehicleDrivers/fetch-all",
    payload => customerContractsPostVehicleDriverSearch({
        ...payload,
        city: currentCity().id
    }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        loading: false,
        byId: formatVehicles(action.apiCallResult.data.items),
    }),
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;

const formatVehicles = (vehicles: VehicleDriver[]) => {
    const result: any = {};

    vehicles.forEach(driver => {
        result[driver.id] = {
            loading: false,
            driver
        };
    });

    return result;
};
