import {Right} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbRightsStateData} from "../index";
import {customerContractsPutRightVehiculeAdd} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/putRightVehicleAdd";

type RequestActionCreatorPayload = {
    id: string,
    date: Date,
    channel: string,
    vehiclesIds: (string|number)[],
}

const actions = newRequestActions<RequestActionCreatorPayload, DbRightsStateData, Right>(
    "/db/rights/add-one",
    (payload) => {
        return customerContractsPutRightVehiculeAdd(
            payload.id, payload
        );
    },
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => {
        return {
            ...state,
            loading: false,
        }
    },
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;
