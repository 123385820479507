import {listener} from "../../../core-react/src/utils/redux/middlewares/listeningMiddleware";
import {SessionAskAccountValidationAction} from "./actions/askAccountValidation";
import {CoreNavigationDemand} from "../../../core-react/src/modules/routing/redux/navigation/actions";
import {routes} from "../../../configuration/routes";
import {SessionConnectAccountAction} from "./actions/connectAccount";
import {SessionStartCustomerConnectionProcessAction} from "./actions/startCustomerConnectionProcess";
import {
    SessionGetAccountMatchingCustomersFailureAction, SessionGetAccountMatchingCustomersRequestAction,
    SessionGetAccountMatchingCustomersSuccessAction
} from "./actions/getAccountMatchingCustomers";
import {CoreNotify} from "../../../core-react/src/modules/notifications/redux/state";
import {sessionSelectors} from "./index";
import {
    SessionEndCustomerConnectionProcessFailureAction, SessionEndCustomerConnectionProcessRequestAction,
    SessionEndCustomerConnectionProcessSuccessAction
} from "./actions/endCustomerConnectionProcess";
import {SessionDisconnectAction} from "./actions/disconnect";

export default () => {
    listener.onActionEnd(SessionAskAccountValidationAction, (dispatch, state, action) => {
        dispatch(CoreNavigationDemand({
            route: routes().AskAccountValidation,
            args: {
                from: action.source
            }
        }));
    });

    listener.onActionEnd(SessionConnectAccountAction, (dispatch, state, action) => {
        dispatch(SessionStartCustomerConnectionProcessAction());
    });

    listener.onActionEnd(SessionStartCustomerConnectionProcessAction, (dispatch, state, action) => {
        dispatch(SessionGetAccountMatchingCustomersRequestAction({
            id: sessionSelectors.state(state).account?.id as string
        }));
    });

    listener.onActionEnd(SessionGetAccountMatchingCustomersFailureAction, (dispatch, state, action) => {
        // dispatch(CoreNotify.failure(
        //     "An error occured. Please connect again"
        // ));
        /*
        dispatch(CoreNavigationDemand({
            route: routes().PublicHome
        }));

         */
    });

    listener.onActionEnd(SessionGetAccountMatchingCustomersSuccessAction, (dispatch, state, action) => {
        const persons = sessionSelectors.accountMatchingPersons(state);

        if (persons.length === 0) {
            return dispatch(CoreNavigationDemand({
                route: routes().CustomerCreate
            }));
        }

        if (persons.length > 1) {
            return dispatch(CoreNavigationDemand({
                route: routes().ChooseCustomer
            }));
        }

        dispatch(SessionEndCustomerConnectionProcessRequestAction({
            id: persons[0].id
        }));
    });

    listener.onActionEnd(SessionEndCustomerConnectionProcessFailureAction, (dispatch, state, action) => {
        // dispatch(CoreNotify.failure(
        //     "An error happened"
        // ));
    });

    listener.onActionEnd(SessionEndCustomerConnectionProcessSuccessAction, (dispatch, state, action) => {
        dispatch(CoreNavigationDemand({
            route: routes().PrivateHome
        }));
    });

    listener.onActionEnd(SessionDisconnectAction, (dispatch, state, action) => {
        dispatch(CoreNavigationDemand({
            route: routes().PublicHome
        }));
    });
};

/*
const { db } = action.apiCallResult;

            const personLength = db.persons.length;

            // 0 clients correspondent : Redirect vers /login/createProfile?from=login
            if (personLength === 0) {
                return navigator().LoginCreateProfilePage();
            }

            // 1 client correspond : on appelera un service pour lancer la connexion et rediriger.
            if (personLength === 1) {
                // auto connect + redirect sur private
                return;
            }

            // N clients correspondent: Redirect vers /login/chooseProfile?from=login
            return navigator().LoginChooseProfilePage();
 */
