/**
 * Created by priano on 17/09/2020 at 09:17.
 */
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {Alias, DbAliasesStateData} from "../index";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";
import {currentCity} from "../../../../../configuration/cities/_city";
import {ApiCall} from "../../../../../core-react/src/modules/api";
import {configuration} from "../../../../../configuration/configuration";
import {formatAliases} from "../utils";


const path = "/api/alias/search";

type Payload = {
    limit?: string,
    offset?: string,
    id?: string,
    customerId?: number | string,
    cities?: string,
    city?: string
};

export const orderSearchAliases = (payload: Payload): ApiCall<Pagination<Alias>> => ({
    url: configuration().payment.url + path,
    body: payload,
    method: 'POST',
    formater: r => r,
    tokenRepository: 'xts',
});

type RequestActionCreatorPayload = {
    city?: string,
    customerId?: string | number,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbAliasesStateData, Pagination<Alias>>(
    "/db/aliases/fetch-all",
    payload => orderSearchAliases({
        ...payload,
        city: currentCity().id
    }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        loading: false,
        byId: formatAliases(action.apiCallResult.data.items),
    }),
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;

