import {actionInheritsCreationPayload, newAction} from "../../../utils/redux/actionBuilder";
import {reducer as toastrReducer, toastr} from 'react-redux-toastr';
import {listener} from "../../../utils/redux/middlewares/listeningMiddleware";
import {coreConfiguration} from "../../../internal/coreConfiguration";
import React from "react";

type Notification = {
    title: string,
    timeout?: number,
    message?: string,
    kind: 'success' | 'failure'
}

export const CoreNotify = Object.assign(newAction<Notification, any, Notification>({
        type: "/core/notify/new",
        create: actionInheritsCreationPayload()
    }), {
        success: (title: string, message?: string) => ({
            type: "/core/notify/new",
            kind: "success",
            message, title,
        }),
        failure: (title: string, message?: string) => ({
            type: "/core/notify/new",
            kind: "failure",
            message, title
        })
    }
);

export const coreNotificationsStateKey = "toastr";
export const coreNotificationsReducer = toastrReducer;


type NotifyForConfirmationAction = { type: string, onConfirm: () => void, onCancel: () => void, message: string|React.ReactNode };
type NotifyForConfirmationCreation = { onConfirm: () => void, onCancel: () => void, message: string|React.ReactNode };

export const CoreNotifyForConfirmation = newAction<NotifyForConfirmationAction, any, NotifyForConfirmationCreation>({
    type: "/core/notify/confirmation",
    create: (type, {message, onCancel, onConfirm}) => ({ type, onConfirm, onCancel, message })
});

listener.onActionEnd(CoreNotifyForConfirmation, (dispatch, state, action) => {
    toastr.confirm(typeof action.message === "string" ? action.message : (action.message as any)(), {
        onOk: action.onConfirm,
        onCancel: action.onCancel
    });
});

listener.onActionEnd(CoreNotify, (dispatch, state, action) => {
    if (!coreConfiguration.notifications) {
        return;
    }

    const timeOut = action.timeout || 5000;

    if (action.kind === 'success') {
        toastr.success(action.title, action.message || '', {timeOut})
    }

    if (action.kind === 'failure') {
        toastr.error(action.title || '', action.message || '', {timeOut})
    }
});

