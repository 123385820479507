import {OrderConfigurationState, OrderConfigurationStateModel} from "./index";

const select = (state: any): OrderConfigurationStateModel => state[OrderConfigurationState.stateKey];

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    loaded: (state: any) => select(state).loaded,
    configuration: (state: any) => select(state).configuration,
    accountPaymentMethod: (state: any) => select(state).configuration?.accountPaymentMethod || [],
    cardPaymentsAreEnable: (state: any) => (select(state).configuration?.accountPaymentMethod || []).filter((ac) => ac.id === 'cybersource').length > 0
}
