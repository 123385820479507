import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData} from "../index";
import {RightCatalogValidity} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right-catalog-validity";
import {customerContractsComputeValidityDates} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/getComputeValidityDates";

export type RequestActionCreatorPayload = {
    rightCatalogId: number | string,
    startDate?: string,
};

export const actions = newRequestActions<RequestActionCreatorPayload, UiRightCreationWorkflowStateData, RightCatalogValidity>(
    "/ui/right-creation/compute_dates_validity",
    customerContractsComputeValidityDates,
    (action, state) => ({
        ...state,
        validityPeriod: undefined,
        validityPeriodStatus: 'computing',
    }),
    (action, state) => ({
        ...state,
        validityPeriod: action.apiCallResult.data,
        validityPeriodStatus: 'computed',
    }),
    (action, state) => ({
        ...state,
        validityPeriodStatus: 'failed',
    }),
);

export default actions;
