/**
 * Created by priano on 09/09/2020 at 09:15.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/proof/search";

type Payload = {
    limit?: number | string,
    offset?: number | string,
    personId?: number | string,
    type?: string,
    city: string,
    id?: number,
    updatedSince?: string,
}

export const customerContractsPostProofSearch = (payload: Payload): ApiCall<any> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: r => r,
    tokenRepository: "xts"
});
