import {Formater} from "../../../common/modeling";

type ApiCityResult = {
    languages: string[]|null,
    lat?: string,
    lng?: string,
    zoom?: number,
    backend: string,
    country: string,
    currency: string,
    frenchAddressApiActive: boolean,
    locale: string,
    logoUrl: string,
    shallUseNationalRegistryBE: boolean,
};

export type City = {
    languages: string[],
    coordinates: {
        latitude?: number,
        longitude?: number,
        zoom?: number
    },
    backend: string,
    country: string,
    currency: string,
    frenchAddressApiActive: boolean,
    locale: string,
    logoUrl: string,
    shallUseNationalRegistryBE: boolean,
};

const apiCityToCity: Formater<ApiCityResult, City> = city => ({
    languages: city.languages || [],
    coordinates: {
        latitude: Number(city.lat) || undefined,
        longitude: Number(city.lng) || undefined,
        zoom: city.zoom || undefined
    },
    backend: city.backend,
    country: city.country,
    currency: city.currency,
    frenchAddressApiActive: city.frenchAddressApiActive,
    locale: city.locale,
    logoUrl: city.logoUrl,
    shallUseNationalRegistryBE: city.shallUseNationalRegistryBE,
});

export default apiCityToCity;
