import {Formater} from "../../../common/modeling";
import {ApiPersonResult, apiPersonToPerson, Person} from "./person";

// RAW API RESULTS
export type ApiAccountResult = {
    username: string,
    email: string,
    city: string,
    id: number,
    persons: ApiPersonResult[]|null,
    created: string,
    updated: string,
    deleted: string
};

// FORMATED API RESULTS
export type Account = {
    id: number,
    city: string,
    username: string,
    email: string,
    persons: Person[],
};

// FORMATING
export const apiAccountToAccount: Formater<ApiAccountResult, Account> = ({city, email, id, persons, username}) => ({
    id,
    city,
    email,
    username,
    persons: (persons || []).map(apiPersonToPerson),
});
