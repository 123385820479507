import {reducerFromActions} from "../../../core-react/src/utils/redux/reducerBuilder";
import {MailConfirmationArrivedAction} from "./actions/arrived";
import {
    MailConfirmationFailureAction,
    MailConfirmationRequestAction,
    MailConfirmationSuccessAction
} from "./actions/request";
import {MailConfirmationUndefinedParametersAction} from "./actions/undefinedParameters";
import listeners from "./listeners";

export type MailConfirmationState = {
    loading?: boolean,
    confirmed?: boolean,
    error?: string,
}

const initialState: MailConfirmationState = {};

export const registerConfirmationStateKey = 'register_confirmation';

export const selectMailConfirmationState = (state: any): MailConfirmationState => {
    return state[registerConfirmationStateKey];
};

export const mailConfirmationReducer = reducerFromActions(
    initialState,
    MailConfirmationArrivedAction,
    MailConfirmationRequestAction,
    MailConfirmationSuccessAction,
    MailConfirmationFailureAction,
    MailConfirmationUndefinedParametersAction,
);

listeners();
