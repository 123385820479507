import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/account/:id/validationEmail/:code";

type Payload = {
    id: string,
    code: string,
};

export const customerContractsPostAccountValidationEmail = (payload: Payload): ApiCall<string> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    urlParameters: payload,
    formater: (s: string) => s
});
