import {Formater} from "../../../common/modeling";

export type ApiOrderCityConfiguration = {
    smtpHost: string,
    smtpPort: string,
    smtpEncryption: string,
    smtpUsername: string,
    smtpPassword: string,
    smtpSenderEmail: string,
    smtpSenderName: string,
    accountPaymentMethod: ApiAccountPaymentMethod[] | null,
    daysBeforeCancellingUnpaidPermit: number,
}

export type ApiAccountPaymentMethod = {
    id: string,
    name: string,
    name_i18n: {
        [key: string]: string,
    },
    description: string,
    description_i18n: {
        [key: string]: string,
    },
    usableAtBackoffice: boolean,
    usableAtFrontoffice: boolean,
}

export type OrderCityConfiguration = {
    orderSmtpHost: string,
    orderSmtpPort: string,
    orderSmtpEncryption: string,
    orderSmtpUsername: string,
    orderSmtpPassword: string,
    orderSmtpSenderEmail: string,
    orderSmtpSenderName: string,
    accountPaymentMethod: AccountPaymentMethod[] | null,
    orderDaysBeforeCancellingUnpaidPermit: number,
}

export type AccountPaymentMethod = {
    id: string,
    name: string,
    name_i18n: {
        [key: string]: string,
    },
    description: string,
    description_i18n: {
        [key: string]: string,
    },
    usableAtBackoffice: boolean,
    usableAtFrontoffice: boolean,
}

export const apiOrderCityConfigurationToOrderCityConfiguration: Formater<ApiOrderCityConfiguration, OrderCityConfiguration> = conf => ({
    orderSmtpHost: conf.smtpHost,
    orderSmtpPort: conf.smtpPort,
    orderSmtpEncryption: conf.smtpEncryption,
    orderSmtpUsername: conf.smtpUsername,
    orderSmtpPassword: conf.smtpPassword,
    orderSmtpSenderEmail: conf.smtpSenderEmail,
    orderSmtpSenderName: conf.smtpSenderName,
    accountPaymentMethod: conf.accountPaymentMethod,
    orderDaysBeforeCancellingUnpaidPermit: conf.daysBeforeCancellingUnpaidPermit,
});
