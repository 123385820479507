import {newAction} from "../../../../utils/redux/actionBuilder";
import {CoreSessionState} from "../state";

type ActionPayload = any;
type CreationPayload = void;

export const CoreSessionEnd = newAction<ActionPayload, CoreSessionState, CreationPayload>({
    type: "core/session/end",
    create: (type, payload) => ({
        type
    }),
    reducer: (action, state) => null
});
