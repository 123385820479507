import {useMemo} from "react";
import en from './en';
import en_AU from './en_AU';
import fr from './fr';
import de from './de';
import nl from './nl';
import { Translations } from "./type";
import {currentCity} from "../configuration/cities/_city";
import {getBrowserLanguage} from "../core-react/src/utils/lang";

type AvailableTranslations = {
    [key: string]: Translations
}

export const translations: AvailableTranslations = {
    en, en_AU, fr, de, nl
};

export type Language = 'en' | 'en_AU' | 'fr' | 'de' | 'nl';

export const FALLBACK_LANGUAGE = 'en';

export default () => {
    const lang = useCurrentLanguage();

    return useMemo(() => {
        return translations[lang] || translations[FALLBACK_LANGUAGE]
    }, [lang]);
};

/**
 * getCurrentLanguage masi en hook
 */
export function useCurrentLanguage() {
    return getCurrentLanguage();
}

export function getCurrentLanguage() {
    // Language configuré pour la city ou sinon browser language
    return currentCity()?.language || getBrowserLanguage();
}

/**
 * useTranslation mais en fonction
 */
export function getTranslation() {
    const lang = getCurrentLanguage();
    return translations[lang] || translations[FALLBACK_LANGUAGE];
}
