import {ApiCall, ApiCallResult} from "../index";
import {AxiosRequestConfig} from "axios";
import axios from "axios";
import {coreConfiguration} from "../../../internal/coreConfiguration";
import {wait} from "../../../utils/timer";
import {objectsAreEqual} from "../../../utils/comparaisons";

type ExtraHeaders = undefined | {
    Authorization?: string,
    [header: string]: string|undefined
}

export async function call<SuccessDataType>(apiCall: ApiCall<SuccessDataType>, extraHeaders?: ExtraHeaders): Promise<ApiCallResult<SuccessDataType>>
{
    if (extraHeaders?.Authorization && apiCall.options?.dontAddAuthorization) {
        delete extraHeaders.Authorization;
    }

    const url = buildUrl(apiCall.url, apiCall.urlParameters);

    const request: AxiosRequestConfig = {
        url,
        method: apiCall.method,
        data: apiCall.body,
        headers: {
            ...apiCall.headers,
            ...extraHeaders
        },
        responseType: apiCall.responseType,
    };

    const mockedCall = (coreConfiguration.api.mockedCalls || [])
        .filter(mockedCall => mockedCall.when.urlIs === url
            && mockedCall.when.methodIs === apiCall.method
            && (!mockedCall.when.bodyMaybe || objectsAreEqual(mockedCall.when.bodyMaybe, apiCall.body))
        )[0];

    if (mockedCall) {
        await wait(mockedCall.will.take || 0);
        return {
            ...mockedCall.will.resultIn,
            meta: {
                ...apiCall.meta,
                mocked: true
            }
        };
    }

    try {
        const response = await axios.request(request);

        await wait(300);

        //console.log('Reponse of', apiCall.url, response.db, apiCall.formater(response.db));
        return {
            statusCode: response.status,
            data: apiCall.formater(response.data),
            errored: response.status >= 400,
            timedOut: false,
            unreachable: false,
            request,
            meta: apiCall.meta,
        }
    } catch (e) {
        if (! e.isAxiosError) {
            throw e;
        }

        const unreachable = e.toString().indexOf('Network Error') > -1;

        return {
            statusCode: unreachable ? 503 : e.response.status,
            timedOut: false, // todo
            unreachable: unreachable,
            errored: true,
            data: unreachable ? 'Network error' : e.response?.data,
            request,
            meta: apiCall.meta,
        };
    }
}

const buildUrl = (baseUrl: string, urlParams?: {[key: string]: undefined|string|number}) => {
    const params = urlParams || {};
    let url = baseUrl;

    Object.keys(params).forEach(key => {
        const value = params[key];
        if (value === undefined) {
            return true;
        }

        if (url.indexOf(':' + key) > -1) {
            url = url.replace(':' + key, value.toString());
            return true;
        }

        const prefix = url.indexOf('?') > -1 ? '&' : '?';

        url += prefix + key + '=' + value;
    });

    return url;
};
