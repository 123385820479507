import { Vehicle } from './modeling/vehicle';
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/vehicle/:id";

type Payload = {
    vehicleId: number,
};

export const customerContractsDeleteVehicle = (payload: Payload): ApiCall<Vehicle> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path.replace(':id', payload.vehicleId.toString()),
    method: 'DELETE',
    formater: r => r,
    tokenRepository: 'xts',
});
