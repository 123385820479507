import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import {act} from "react-dom/test-utils";
import selectors from "./selectors";
import sendDemand from "./actions/sendDemand";
import hooks from "./hooks";

export type UiPasswordChangeDemandStateData = {
    loading: boolean,
    done: boolean,
    emailNotFound: boolean,
};

const initialState: UiPasswordChangeDemandStateData = {
    loading: false,
    done: false,
    emailNotFound: false,
};

const actions = { sendDemand };

const stateKey = "ui.password-change-demand";

const reducer = reducerFromActionsObject(initialState, actions);

export const UiPasswordChangeDemandState = {
    actions, stateKey, reducer, selectors, hooks
};
