import {CustomerContractsState, CustomerContractsConfigurationStateModel} from "./index";

const select = (state: any): CustomerContractsConfigurationStateModel => state[CustomerContractsState.stateKey];

const accessState = (path: keyof CustomerContractsConfigurationStateModel) => (state: any) => select(state)[path];

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    loaded: (state: any) => select(state).loaded,
    configuration: (state: any) => select(state).configuration,
    country:  (state: any) => select(state)?.configuration?.country || '',
    customerTypes: (state: any) => (select(state)?.configuration?.customerTypes || []),
    customerTitles: (state: any) => select(state)?.configuration?.customerTitles || [],
    hasCustomerTitles: (state: any) => (select(state)?.configuration?.customerTitles || []).length > 1,
    shallValidatePhones: (state: any) => select(state)?.configuration?.shallValidatePhones,
    shallRequireLocalizedAddress: (state: any) => select(state).configuration?.shallRequireLocalizedAddress,
    shallCancelPermitFromFrontoffice: (state: any) => select(state).configuration?.shallCancelPermitFromFrontoffice,
}
