/**
 * Created by priano on 07/09/2020 at 16:50 pensacola GMT.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {apiPersonToPerson, Person} from "./modeling/person";
import {apiRightToRight, Right} from "./modeling/right";

const path = "/right";

type Payload = {
    label: string,
    reference?: string,
    validityStart: string,
    validityEnd: string,
    comments?: string,
    deliveryChannel: string, // "FRONTOFFICE",
    rightCatalogId: number | string,
    validityAreas: string[],
    profile: string,
    vehicleIds: number[],
    personId: number | string,
    allowsParking: boolean,
    priceIncludingVat: number,
    priceVatRate: number,
    pricePosition: number,
    parentRightId?: number | string,
    city: string
};

export const customerContractsPostRight = (payload: Payload): ApiCall<Right> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: apiRightToRight,
    tokenRepository: "xts"
});
