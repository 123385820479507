import {CustomerContratsCity} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/customer-contracts-city";
import loadConfiguration from './actions/loadConfiguration';
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import selectors from "./selectors";

export type CustomerContractsConfigurationStateModel = {
    configuration?: CustomerContratsCity,
    loading: boolean,
    loaded: boolean,
}

const initialState: CustomerContractsConfigurationStateModel = {
    configuration: undefined,
    loading: false,
    loaded: false,
};

const actions = { loadConfiguration };

export const stateKey = 'configuration.customerContracts';

const reducer = reducerFromActionsObject(initialState, actions);

export const CustomerContractsState = {
    actions, stateKey, reducer, selectors
}
