import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {ApiCall} from "../../../index";
import apiCityToCity, {CustomerContratsCity} from "./modeling/customer-contracts-city";

const path = '/city/:city';

export default (city: string): ApiCall<CustomerContratsCity> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path.replace(':city', city),
    method: 'GET',
    formater: apiCityToCity,
    tokenRepository: "xts-or-xts-account"
});
