import {Listeners} from "../../../../core-react/src/utils/form/redux";
import {PasswordChangeData, UiPasswordChangeFormState} from "./index";
import {customerContractsPostAccountChangePassword} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/postAccountChangePassword";
import {selectCurrentRoute} from "../../../../core-react/src/modules/routing/redux/navigation/state";
import {CoreNavigationDemand} from "../../../../core-react/src/modules/routing/redux/navigation/actions";
import {routes} from "../../../../configuration/routes";
import {CoreNotify} from "../../../../core-react/src/modules/notifications/redux/state";
import {getTranslation} from "../../../../translations/useTranslation";

const listeners: Listeners<PasswordChangeData, string, any> = {
    // Dispatch via component à chaque render
    onArrived(dispatch, state, action) {
        if (selectCurrentRoute(state).definedPath === routes().PublicHome.path) {
            // Redirect sur home post-success, mais y'a encore le dispatch arrived d'ici qui pop
            return;
        }

        if (!selectCurrentRoute(state).query.accountId || !selectCurrentRoute(state).query.code) {
            const translations = getTranslation();
            dispatch(CoreNotify.failure(
                translations.thereIsAProblemWithYourChangepasswordLink
            ));
            dispatch(CoreNavigationDemand({
                route: routes().PublicHome,
            }))
        }
    },
    // Dispatch via component
    onReset(dispatch, state, action) {
        dispatch(
            UiPasswordChangeFormState.actions.initialize({})
        );
    },
    // Dispatch via listeners
    onInitialize(dispatch, state, action) {
        dispatch(
            UiPasswordChangeFormState.actions.initialized({
                dto: UiPasswordChangeFormState.initialDto,
            }),
        )
    },
    onSubmit(dispatch, state, action) {
        if (!UiPasswordChangeFormState.selectors.state(state).submitting) {
            return;
        }

        const data = UiPasswordChangeFormState.selectors.state(state).dto;

        const {accountId, code} = selectCurrentRoute(state).query;

        dispatch(
            UiPasswordChangeFormState.actions.submitRequest({
                apiCall: customerContractsPostAccountChangePassword({
                    password: data.password,
                    accountId, code,
                })
            })
        );
    },
    onSubmitSuccess(dispatch, state, action) {
        const translations = getTranslation();

        dispatch(CoreNotify.success(
            translations.yourPasswordChanged
        ));
        dispatch(CoreNavigationDemand({
            route: routes().PublicHome,
        }));
    },
    onSubmitFailure(dispatch, state, action) {
        if ((action.apiCallResult.data as any).error == "Invalid code provided") {
            // dispatch(CoreNotify.failure(
            //     "There is a problem with your change-password link"
            // ));
            dispatch(CoreNavigationDemand({
                route: routes().PublicHome
            }));
            return;
        }

        // dispatch(CoreNotify.failure(
        //     "An error occured. Please try again"
        // ));
    }
};

export default listeners;
