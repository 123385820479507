import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {VehicleFormDependenciesState} from "../index";

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = void;

export const VehicleFormDependenciesArrivedAction = newAction<ActionPayload, VehicleFormDependenciesState, ActionCreatorPayload>({
    type: "/vehile-form-dependencies/arrived",
    create: (type, payload) => ({
        type
    }),
    reducer: (action, state) => state,
});
