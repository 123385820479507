import {put, select, takeEvery} from "redux-saga/effects";
import {
    CoreNavigationDemand,
    CoreNavigationPageChanged
} from "../../../../core-react/src/modules/routing/redux/navigation/actions";
import {sessionSelectors} from "../../../modules/session";
import {routes} from "../../../../configuration/routes";
import {selectCurrentRoute} from "../../../../core-react/src/modules/routing/redux/navigation/state";

export default function* sagas() {
    yield takeEvery(CoreNavigationPageChanged.type, function* (action) {
        // Check si connecté en tant que customer et pas account car il y a une diff
        const isConnected = yield select(sessionSelectors.customerIsConnected);
        const currentRoute = yield select(selectCurrentRoute);

        // Si je ne suis pas connecté et que je suis une route privée je redirect sur la page login public
        if (!isConnected && currentRoute.private) {
            yield put(
                CoreNavigationDemand.create({
                    route: routes().PublicHome,
                })
            );
        }

        // Si pas connecté on ne fait rien pas de redirection
        // Si connecté mais que la route n'est pas slash on ne fait rien pas de redirection
        if (!isConnected || (action as any).routerProps.location.pathname !== "/") {
            return;
        }

        // Par contre si connecté mais que a route est slash ici
        // Redirect to private direct
        yield put(
            CoreNavigationDemand.create({
                route: routes().PrivateHome
            })
        );
    });
}
