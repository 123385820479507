import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData, UiVehicle} from "../index";
import {formatVehicles} from "../utils";

type ActionPayload = {
    type: string,
    vehicles: UiVehicle[],
};

type ActionCreatorPayload = {
    vehicles: UiVehicle[],
};

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/set-vehicles",
    create: (type, { vehicles }) => ({ type, vehicles }),
    reducer: (action, state) => {
        return {
            ...state,
            vehicles: formatVehicles(action.vehicles)
        }
    }
});

