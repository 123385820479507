/**
 * Created by priano on 28/08/2020 at 09:06.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {apiPaginationToPagination, Pagination} from "../../common/modeling";
import {apiVehicleToVehicle, Vehicle} from "./modeling/vehicle";

const path = "/vehicle/search";

type Payload = {
    limit?: string,
    offset?: string,
    city?: string,
    plate?: string,
    plateStartWith?: string,
    country?: string,
    personId?: number | string,
    id?: number | string,
    updatedSince?: string
};

export const customerContractsPostVehicleSearch = (payload: Payload): ApiCall<Pagination<Vehicle>> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: apiPaginationToPagination(apiVehicleToVehicle),
    tokenRepository: 'xts',
});
