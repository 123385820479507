/**
 * Created by priano on 27/08/2020 at 17:17.
 * Edited by Nicolas later on.
 */
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {ApiCall} from "../../../index";
import {apiPaginationToPagination, Pagination} from "../../common/modeling";
import {apiCatalogToCatalog, Catalog} from "./modeling/catalog";

const path = "/catalog/search";

type Payload = {
    city: string,
    type: "energy" | "vehicleType",
};

export const businesscustomerCityCatalogsSearch = (payload: Payload): ApiCall<Pagination<Catalog<any>>> => ({
    url: coreConfiguration.api.xts.businesscustomerCityUrl + path,
    method: 'POST',
    body: payload,
    formater: apiPaginationToPagination(apiCatalogToCatalog),
    tokenRepository: 'xts',
});
