/**
 * Created by priano on 20/08/2020 at 14:27.
 */
import {listener} from "../../../core-react/src/utils/redux/middlewares/listeningMiddleware";
import {RightCreationFormArrivedAction} from "./actions/RightCreationFormArrived";
import {Dispatch} from "react";
import {selectState} from "./index";
import {currentCity} from "../../../configuration/cities/_city";
import {FetchProfilesRequestAction, FetchProfilesSuccessAction} from "./actions/FetchProfilesAction";
import {FetchRightCatalogsRequestAction} from "./actions/FetchRightCatalogsAction";
import {selectCurrentRoute} from "../../../core-react/src/modules/routing/redux/navigation/state";
import {FetchVehicleRequestAction, FetchVehicleSuccessAction} from "./actions/FetchVehicleAction";
import {sessionSelectors} from "../session";
import {CreateRightSuccessAction} from "./actions/CreateRightAction";
import {navigator} from "../../../services/navigator";
import {
    SubmitRightToApprobationRequestAction,
    SubmitRightToApprobationSuccessAction
} from "./actions/SubmitRightToApprobation";
import {DbRightsState} from "../../modules_clean/db/rights";
import {CoreNotify} from "../../../core-react/src/modules/notifications/redux/state";
import {getTranslation} from "../../../translations/useTranslation";

const fetchVehicleOnRightCreationArrived = (dispatch: Dispatch<any>, state: any, action: any) => {
    const { options: {vehicleLoading, vehicleLoaded} } = selectState(state);
    const { query: { vehicleId } } = selectCurrentRoute(state);

    if (vehicleLoading || vehicleLoaded) {
        return;
    }

    // Ici il faut fetch la person et le vehicle depuis l'api
    // person venant de la session + vehicle venant de ? (queryParams)
    dispatch(
        FetchVehicleRequestAction({
            id: vehicleId,
            city: currentCity().id,
        })
    )
};

const fetchProfilesOnVehicleFetched = (dispatch: Dispatch<any>, state: any, action: any) => {
    const { options: {profilesLoaded, profilesLoading} } = selectState(state);
    const { query: { wishedStartDate, parentRightId } } = selectCurrentRoute(state);

    // if (profilesLoaded || profilesLoading) {
    //     return;
    // }

    dispatch(
        FetchProfilesRequestAction({
            city: currentCity().id,
            parentRightId,
            wishedStartDate,
        })
    )
};

const fetchRightCatalogsOnProfilesFetched = (dispatch: Dispatch<any>, state: any, action: any) => {
    const {
        value: { vehicle },
        options: {rightCatalogsLoading, rightCatalogsLoaded}
    } = selectState(state);

    const personId = sessionSelectors.customerId(state) as number;

    // if (rightCatalogsLoading || rightCatalogsLoaded) {
    //     return;
    // }

    dispatch(
        FetchRightCatalogsRequestAction({
            availablesForPersonId: personId,
            availablesForVehicleId: vehicle?.id,
            city: currentCity().id,
            deliveryChannel: 'FRONTOFFICE',
        })
    )
};

const submitRightAfterSuccessfulCreation = (dispatch: Dispatch<any>, state: any, action: any) => {
    const result: any = action.apiCallResult.data;

    dispatch(
        SubmitRightToApprobationRequestAction({
            rightId: result.id,
            channel: "FRONTOFFICE",
            date: new Date().toISOString().slice(0, 19) + '+00:00',
        })
    )
}

const redirectToPrivateHomeAfterRightCreationSuccess = (dispatch: Dispatch<any>, state: any, action: any) => {
    const translations = getTranslation();
    const right: any = action.apiCallResult.data;

    dispatch(
        CoreNotify.success(translations.permitRequested)
    );
    dispatch(
        DbRightsState.actions.writeOne({
            right,
        })
    );

    navigator().PrivateHome();
}

export const registerRightCreationFormListeners = () => {
    listener.onActionEnd(RightCreationFormArrivedAction, fetchVehicleOnRightCreationArrived);
    listener.onActionEnd(FetchVehicleSuccessAction, fetchProfilesOnVehicleFetched);
    listener.onActionEnd(FetchProfilesSuccessAction, fetchRightCatalogsOnProfilesFetched);
    listener.onActionEnd(CreateRightSuccessAction, submitRightAfterSuccessfulCreation);
    listener.onActionEnd(SubmitRightToApprobationSuccessAction, redirectToPrivateHomeAfterRightCreationSuccess);
};
