/**
 * Created by priano on 17/09/2020 at 09:17.
 */
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {Right} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";
import {orderSearchInvoices} from "../../../../../core-react/src/modules/api/utils/xts/order/postInvoiceSearch";
import {DbInvoicesStateData} from "../index";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";
import {currentCity} from "../../../../../configuration/cities/_city";
import {Invoice} from "../../../../../core-react/src/modules/api/utils/xts/order/modeling/invoice";

type RequestActionCreatorPayload = {
    productId?: string | number,
    productType?: string,
    externalAccountId?: string | number,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbInvoicesStateData, Pagination<Invoice>>(
    "/db/invoices/fetch-all",
    payload => orderSearchInvoices({
        ...payload,
        city: currentCity().id
    }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        loading: false,
        loaded: true,
        byId: formatInvoices(action.apiCallResult.data.items),
    }),
    (action, state) => ({
        ...state,
        loading: false,
        loaded: true,
    })
);

export default actions;

const formatInvoices = (invoices: Invoice[]) => {
    const result: any = {};

    invoices.forEach(invoice => {
        result[invoice.id] = {
            loading: false,
            invoice,
        };
    });

    return result;
};
