import {registerCurrentCity} from "./configuration/cities/_city";
import {configuration, registerConfiguration} from "./configuration/configuration";
import {configurateCore} from "./core-react/src/utils/configuration";
import {registerTokenRepository} from "./core-react/src/modules/api/redux/middleware";
import {retrieveState} from "./core-react/src/modules/persistance/utils/stateRepository";
import {configureStore} from "./redux";
import {registerNavigator} from "./services/navigator";
import {sessionSelectors} from "./redux/modules/session";
import {addCalendarLocale} from "./services/calendar-locales";
import {getCurrentLanguage} from "./translations/useTranslation";

export default async () => {
    const basePath = (<HTMLMetaElement>document.head.querySelector('meta[name="base-path"]')).content; // Base path is in this tag AND in the config.json fetched below. It will always be the same. We do this because on sub-sub-routes fetch(config.json) will assume wrong prefix
    const configDotJson = await (await fetch(`${basePath}/config.json`)).json();

    const city = process.env.REACT_APP_FORCED_CITY || configDotJson.city;
    await registerCurrentCity(city);

    await registerConfiguration(undefined, configDotJson);

    configurateCore(configuration().core);

    registerTokenRepository('xts', state => sessionSelectors.customerToken(state));
    registerTokenRepository('xts-account', state => sessionSelectors.accountToken(state));
    registerTokenRepository('xts-or-xts-account', state => sessionSelectors.customerIsConnected(state) ? sessionSelectors.customerToken(state) : sessionSelectors.accountToken(state));

    const oldState = retrieveState();
    const store = configureStore(oldState || {});

    registerNavigator(store.dispatch);

    // Ajout le bon local en fonction de la current langue
    addCalendarLocale(getCurrentLanguage());

    return {store};
};
