import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiProfileFormStateData} from "../index";
import {customerContractsPostAddress} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postAddress";
import {Address} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/address";

type RequestActionCreatorPayload = {
    city: string,
    area: string,
    street: string,
    number: string,
    postalCode: string,
    state: string,
    locality: string,
    addressDetails: string,
    country: string,
    usage: string,
    personId: number,
    lat: number,
    lng: number,
}

const actions = newRequestActions<RequestActionCreatorPayload, UiProfileFormStateData, Address>(
    "/ui/profile_form_create_address",
    customerContractsPostAddress,
    (action, state) => ({ ...state }),
    (action, state) => ({ ...state }),
    (action, state) => ({ ...state }),
);

export default actions;
