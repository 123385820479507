/**
 * Created by priano on 17/09/2020 at 09:18.
 */
import fetchAll from "./actions/fetchAll";
import removeAlias from "./actions/remove-alias";
import markAliasAsMain from "./actions/markAliasAsMain";
import renameAlias from "./actions/renameAlias";
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import selectors from "./selectors";


export type Alias = {
    cardPan: string,
    brand: string,
    name: string,
    cvc: string,
    ed: string,
    alias: string,
    customerId: string,
    city: string,
    id: number,
    isMain: boolean,
}

export type DbAliasesStateData = {
    loading: boolean, // All rights being fetched
    byId: {
        [id: string]: {
            loading: boolean, // One right being fetched
            alias: Alias,
        }
    }
}

const initialState: DbAliasesStateData = {
    loading: false,
    byId: {}
};

const actions = { fetchAll , removeAlias, markAliasAsMain, renameAlias };

const stateKey = "db.aliases";

const reducer = reducerFromActionsObject(initialState, actions);

export const DbAliasesState = {
    actions, stateKey, reducer, selectors
};
