// RAW API RESULTS
export type ApiPagination<ItemType> = {
    itemsOffset: string | number,
    itemsLimit: string | number,
    itemsRecovered: string | number,
    itemsAvailable: string | number
    items: ItemType[]
}

// FORMATED API RESULTS
export type Pagination<ItemType> = {
    itemsOffset: number,
    itemsLimit: number,
    itemsRecovered: number,
    itemsAvailable: number
    items: ItemType[]
};

// FORMATING
export type Formater<ApiType, FormatedType> = (apiItem: ApiType) => FormatedType;

export function apiPaginationToPagination<ApiItem, Item>(itemFormater: Formater<ApiItem, Item>): Formater<ApiPagination<ApiItem>, Pagination<Item>> {
    return (apiPagination: ApiPagination<ApiItem>) => ({
        itemsOffset: +apiPagination.itemsOffset,
        itemsLimit: +apiPagination.itemsLimit,
        itemsRecovered: +apiPagination.itemsRecovered,
        itemsAvailable: +apiPagination.itemsAvailable,
        items: apiPagination.items.map(itemFormater)
    });
}

export function noFormating<SuccessDataType>(data: SuccessDataType) {
    return data;
}
