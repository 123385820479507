import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {AskMailConfirmationState} from "../index";

type ActionPayload = {
    type: string,
};

export const AskMailConfirmationFailureAction = newAction<ActionPayload, AskMailConfirmationState>({
    type: "/ask-mail-confirmation/failure",
    reducer: (action, state) => ({
        ...state,
        loading: false,
        failure: true
    })
});
