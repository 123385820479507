import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {AskMailConfirmationState} from "../index";

type ActionPayload = {
    type: string,
};

export const AskMailConfirmationSuccessAction = newAction<ActionPayload, AskMailConfirmationState>({
    type: "/ask-mail-confirmation/success",
    reducer: (action, state) => ({
        ...state,
        loading: false,
        success: true,
    })
});
