import fetchAll from "./actions/fetchAccount";
import fetchPrepaidOperations from './actions/fetchPrepaidOperations';
import payUsingPrepaidAccount from './actions/payUsingPrepaidAccount';
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import selectors from "./selectors";
import {PrepaidAccount} from "../../../../core-react/src/modules/api/utils/xts/order/modeling/prepaidAccount";
import {PrepaidAccountOperation} from "../../../../core-react/src/modules/api/utils/xts/order/modeling/prepaidAccountOperation";

export type DbPrepaidAccountStateData = {
    accountLoading: boolean,
    account?: PrepaidAccount,
    operationsLoading: boolean,
    operations: PrepaidAccountOperation[],
    paymentStatus?: 'processing' | 'completed' | 'failed'
}

const initialState: DbPrepaidAccountStateData = {
    accountLoading: false,
    account: undefined,
    operationsLoading: false,
    operations: [],
};

const actions = { fetchAll, fetchPrepaidOperations, payUsingPrepaidAccount };

const stateKey = "db.prepaidAccount";

const reducer = reducerFromActionsObject(initialState, actions);

export const DbPrepaidAccountState = {
    actions, stateKey, reducer, selectors
};
