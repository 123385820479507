import {Action, ActionCreator, Dispatch} from "redux";
import jwt_decode from "jwt-decode";
import {reducer, reducerFromActions} from "../../../utils/redux/reducerBuilder";
import {CoreSessionStart} from "./actions/start";
import {CoreSessionEnd} from "./actions/end";
import {CoreSessionCitySelection} from "./actions/citySelection";

// Store (state)
export type CoreSessionState = null | {
    token: string,
    tokenExpirationTimestamp: number,
    refreshToken?: string
    city: string,
    user: {
        id: string,
        name: string,
        cities: string[],
        claims: {
            serviceName: string,
            routeId: string
        }[]
    }
}

const initialState: CoreSessionState = null;

// Store key
export const coreSessionStateKey = "core_session";

// Selectors
const selectSession = (state: any): CoreSessionState => state[coreSessionStateKey];
export const selectSessionCity = (state: any): string => selectSession(state)?.city || '';
export const selectSessionCities = (state: any): string[] => selectSession(state)?.user.cities || [];
export const selectSessionUsername = (state: any): string => selectSession(state)?.user.name || '';
export const selectSessionToken = (state: any): string|null => selectSession(state)?.token || null;
export const selectSessionUserId = (state: any): string|null => selectSession(state)?.user.id || null;
export const selectSessionIsActiv = (state: any): boolean => {
    const sessionState = selectSession(state);
    if (! sessionState) {
        return false;
    }

    const now = Math.ceil((new Date()).valueOf() / 1000);

    return sessionState.tokenExpirationTimestamp > now;
};


export const coreSessionReducer = reducerFromActions(initialState, CoreSessionStart, CoreSessionEnd, CoreSessionCitySelection);

/*
// Actions
interface SessionStartAction extends Action {
    token: string
}
interface SessionEndAction extends Action {}
interface SessionCitySelectionAction extends Action {
    city: string
}

// Action Types
export const SESSION_START = "core/session/start";
export const SESSION_END = "core/session/end";
export const SESSION_CITY_SELECTION = "core/session/city-selection";

// Action creators
const createSessionStart: ActionCreator<SessionStartAction> = (token: string) => ({
    type: SESSION_START,
    token
});
const createSessionEnd: ActionCreator<SessionEndAction> = () => ({
    type: SESSION_END
});
const createSessionCitySelection: ActionCreator<SessionCitySelectionAction> = (city: string) => ({
    type: SESSION_CITY_SELECTION,
    city
});

// Dispatchers
export const sessionStart = (token: string, dispatch: Dispatch) => dispatch(createSessionStart(token));

export const sessionCitySelection = (city: string, dispatch: Dispatch) => dispatch(createSessionCitySelection(city));

export const sessionEnd = (dispatch: Dispatch) => dispatch(createSessionEnd());

// Reducers
export const sessionReducer = reducer<CoreSessionState>(initialState)
    .when(SESSION_END, (action) => null)
    .when(SESSION_CITY_SELECTION, (action, state) => {
        return state ? {
            ...state,
            city: action.city
        } : null
    })
    .when(SESSION_START, (action, store) => {
        const decodedToken: any = jwt_decode(action.token);
        const cityIds = decodedToken.cityIds === '*.*' ? ['*'] : decodedToken.cityIds;

        const previousCityIsAvailable = store?.city && cityIds.indexOf(store?.city) !== -1;
        const city = previousCityIsAvailable ? store?.city : cityIds[0];

        return {
            token: action.token,
            tokenExpirationTimestamp: decodedToken.exp,
            city,
            user: {
                cities: cityIds,
                claims: decodedToken.claims,
                id: '',
                name: decodedToken.name
            },
        }
    })
    .done();


 */
