import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {ApiCall} from "../../../index";
import {apiPaginationToPagination, Pagination} from "../../common/modeling";
import {apiAreaToArea, Area} from "./modeling/area";

const path = "/area/search";

type Payload = {
    city: string,
};

export const businesscustomerCityAreaSearch = (payload: Payload): ApiCall<Pagination<Area>> => ({
    url: coreConfiguration.api.xts.businesscustomerCityUrl + path,
    method: 'POST',
    body: payload,
    formater: apiPaginationToPagination(apiAreaToArea),
    tokenRepository: 'xts-or-xts-account',
});
