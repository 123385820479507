import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {sessionSelectors, SessionState} from "../index";
import jwt_decode from "jwt-decode";

type ActionPayload = {
    type: string,
    token: string,
    email?: string,
};

type ActionCreatorPayload = {
    token: string,
    email?: string,
};

export const SessionConnectAccountAction = newAction<ActionPayload, SessionState, ActionCreatorPayload>({
    type: '/session/connected-account',
    create: (type, payload) => ({
        type,
        ...payload
    }),
    reducer: ({token, email}, state) => {
        const decodedToken: any = jwt_decode(token);
        const tokenExpirationTimestamp = decodedToken.exp;
        const id = decodedToken.accountId;

        return {
            ...state,
            account: {
                email: email || state.account?.email,
                token,
                tokenExpirationTimestamp,
                id
            }
        };
    }
});
