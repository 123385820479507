import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbAreaStateData} from "../index";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";
import {currentCity} from "../../../../../configuration/cities/_city";
import {Area} from "../../../../../core-react/src/modules/api/utils/xts/city/modeling/area";
import {businesscustomerCityAreaSearch} from "../../../../../core-react/src/modules/api/utils/xts/city/postAreaSearch";

type RequestActionCreatorPayload = {
    city: string,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbAreaStateData, Pagination<Area>>(
    "/db/area/fetch-all",
    payload => businesscustomerCityAreaSearch({
        ...payload,
    }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        loading: false,
        byId: formatAreas(action.apiCallResult.data.items),
    }),
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;

const formatAreas = (areas: Area[]) => {
    const result: any = {};

    areas.forEach(area => {
        result[area.id] = {
            loading: false,
            area,
        };
    });

    return result;
};
