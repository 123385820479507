import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {RightCreationFormState} from "../index";

type ActionPayload = {
    type: string,
    proofType: string,
};

export type ActionCreatorPayload = {
    proofType: string,
};

export const RegisterProofForUploadAction = newAction<ActionPayload, RightCreationFormState, ActionCreatorPayload>({
    type: "/register_proof_for_upload",
    create: (type, payload) => ({
        type,
        proofType: payload.proofType
    }),
    reducer: (action, state) => ({
        ...state,
        options: {
            ...state.options,
            proofsToUpload: [
                ...state.options.proofsToUpload,
                action.proofType,
            ]
        }
    })
});
