import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/account/:id/changePassword";

type Payload = {
    code: string,
    password: string,
    accountId: number|string,
};

export const customerContractsPostAccountChangePassword = (payload: Payload): ApiCall<string> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    urlParameters: {
        id: payload.accountId
    },
    body: {
        code: payload.code,
        password: payload.password
    },
    formater: str => str
});
