/**
 * Created by priano on 16/09/2020 at 11:28.
 */
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbRightsStateData} from "../index";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";
import {Right} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";
import {customerContractsSearchRights} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postRightSearch";
import {currentCity} from "../../../../../configuration/cities/_city";

type RequestActionCreatorPayload = {
    personId: number,
    limit?: string,
    offset?: string,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbRightsStateData, Pagination<Right>>(
    "/db/rights/fetch-all",
    payload => customerContractsSearchRights({
        ...payload,
        city: currentCity().id,
    }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => {
        const { itemsAvailable: total, itemsOffset: offset, itemsLimit: limit } = action.apiCallResult.data;

        const nb = Math.min(offset + limit, total);
        const percentage = Math.round((100 * nb) / total);

        return {
            completionPercentage: percentage,
            loading: false,
            byId: {
                ...state.byId,
                ...formatRights(action.apiCallResult.data.items)
            },
        }
    },
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;

const formatRights = (rights: Right[]) => {
    const result: any = {};

    rights.forEach(right => {
        result[right.id] = {
            loading: false,
            right,
        };
    });

    return result;
};
