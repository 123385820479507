import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {RightCreationFormState, initialState} from "../index";

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = void;

export const ResetRightCreationFormAction = newAction<ActionPayload, RightCreationFormState, ActionCreatorPayload>({
    type: "/right_creation/reset",
    create: (type, payload) => ({
        type
    }),

    /**
     *  Reduce du state avec les valeurs initialState
     * @param action
     * @param state
     */
    reducer: (action, state) => initialState
});
