import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/right/:id/vehiculeSwap";

type Payload = {
    date: Date,
    channel: string,
    oldVehicleId: string|number,
    newVehicleId: string|number,
};

export const customerContractsPutRightVehiculeSwap = (id: number|string, payload: Payload): ApiCall<any> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    urlParameters: {id},
    method: 'PUT',
    body: payload,
    formater: r => r,
    tokenRepository: 'xts',
});
