import {newAction} from "../../../../utils/redux/actionBuilder";
import {computeCurrentRoute} from "./utils";
import {CoreNavigationState, HistoryItem} from "./state";
import {Route} from "../../index";
import _ from "lodash";

type CoreRoutingPageChangedAction = { type: string, routerProps: any };
type CoreRoutingPageChangedPayload = { routerProps: any };

type CoreRoutingNavigateToAction = { type: string, route: Route, path: string, args?: any };
type CoreRoutingNavigateToPayload = { route: Route, args?: any };

type CoreNavigationHistoryChangeAction = { type: string, history: HistoryItem[] };
type CoreNavigationHistoryChangePayload = { history: HistoryItem[] };

type CoreNavigationRemoveHistoryItemAction = { type: string, itemKey: string };
type CoreNavigationRemoveHistoryItemPayload = { itemKey: string };


export const CoreNavigationPageChanged = newAction<CoreRoutingPageChangedAction, CoreNavigationState, CoreRoutingPageChangedPayload>({
    type: "/core/navigation/page-changed",
    create: (type, {routerProps}) => ({ type, routerProps }),
    reducer: (action, state) => ({
        ...state,
        currentRoute: computeCurrentRoute(action.routerProps, state.currentRoute)
    })
});

export const CoreNavigationDemand = newAction<CoreRoutingNavigateToAction, CoreNavigationState, CoreRoutingNavigateToPayload>({
    type: "/core/navigation/demand",
    create: (type, {route, args}) => {
        let path = route.path as string;
        let numberOfQueryArgs = 0;

        _.forOwn(args || {}, (value, key) => {
            const newPath = path.replace(':' + key, value);

            if (newPath !== path) {
                path = newPath
            } else if (numberOfQueryArgs++ === 0) {
                path += '?' + key + '=' + value;
            } else {
                path += '&' + key + '=' + value;
            }
        });

        return { path, route, type, args };
    }
});

export const CoreNavigationHistoryChange = newAction<CoreNavigationHistoryChangeAction, CoreNavigationState, CoreNavigationHistoryChangePayload>({
    type: "/core/navigation/history-change",
    create: (type, {history}) => ({ type, history }),
    reducer: ({history}, state) => ({ ...state, history })
});

export const CoreNavigationRemoveHistoryItem = newAction<CoreNavigationRemoveHistoryItemAction, CoreNavigationState, CoreNavigationRemoveHistoryItemPayload>({
    type: "/core/navigation/remove-history-item",
    create: (type, {itemKey}) => ({ type, itemKey }),
});

