import {useDispatch, useSelector} from "react-redux";
import {PasswordChangeData, UiPasswordChangeFormState} from "./index";
import React from "react";

const useForm = () => {
    const dispatch = useDispatch();
    const state = useSelector(UiPasswordChangeFormState.selectors.state);

    return {
        changed: (payload: { field: keyof PasswordChangeData, value: any }) => dispatch(UiPasswordChangeFormState.actions.changed(payload)),
        submit: () => dispatch(UiPasswordChangeFormState.actions.submit()),
        reset: () => dispatch(UiPasswordChangeFormState.actions.reset()),
        ...state,
        data: state.dto,
    };
};

const useArrived = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(UiPasswordChangeFormState.actions.arrived());
    });
};

export default {
    useForm,
    useArrived,
}
