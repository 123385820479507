import React from "react";
import {createMuiTheme, ThemeProvider as MuiThemeProvider} from '@material-ui/core';
import {coreConfiguration} from "../../../../internal/coreConfiguration";

type Props = {
    children: any
};

export const CoreThemeProvider = ({children}: Props) => (
    <MuiThemeProvider theme={createMuiTheme({ palette: coreConfiguration.theme }) }>
        {children}
    </MuiThemeProvider>
);
