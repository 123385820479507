import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {AskMailConfirmationState} from "../index";

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = void

export const AskMailConfirmationUndefinedEmailAction = newAction<ActionPayload, AskMailConfirmationState, ActionCreatorPayload>({
    type: "/ask-mail-confirmation/undefinedEmail",
    create: (type, payload) => ({
        type
    }),
    reducer: (action, state) => ({
        ...state,
        failure: true
    })
});
