import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbVehicleDriversStateData} from "../index";
import {VehicleDriver} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/driver";
import {ApiCall} from "../../../../../core-react/src/modules/api";

type ActionPayload = {
    type: string,
    driver: VehicleDriver,
};

type RequestActionCreatorPayload = ApiCall<VehicleDriver>;

// Call writeOne avec direct le retour de post ou putCustomerContractsVehicleDriver()

const actions = newRequestActions<RequestActionCreatorPayload, DbVehicleDriversStateData, VehicleDriver>(
    "/db/vehicleDriers/write-one",
    payload => payload,
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        loading: false,
        byId: {
            ...state.byId,
            [action.apiCallResult.data.id]: {
                loading: false,
                driver: action.apiCallResult.data
            }
        },
        lastCreatedDriver: action.apiCallResult.data,
    }),
    (action, state) => ({
        ...state,
        loading: false,
    }),
);

export default actions;
