import {AnyAction, Dispatch, Store} from "redux";
import {Action} from "../actionBuilder";

type Handle = (dispatch: Dispatch, state: any) => void;
type HandleAction<ActionPayload> = (dispatch: Dispatch, state: any, action: ActionPayload) => void;

type listener = {
    listen: Handle,
    actionType: string,
}
type ActionListener<ActionPayload> = {
    handler: HandleAction<ActionPayload>,
    actionType: string
}

class Listener {
    readonly onActionEndListeners: listener[] = [];
    readonly onActionEndListeners2: ActionListener<any>[] = [];

    listenOnActionEnd(actionType: string|Object, listen: Handle) {
        this.onActionEndListeners.push({ listen, actionType: String(actionType) });
    }

    onActionEnd<ActionPayload>(action: Action<ActionPayload, any, any>, handler: HandleAction<ActionPayload>) {
        this.onActionEndListeners2.push({ actionType: action.toString(), handler });
    }
}

export const listener = new Listener();

export const listeningMiddleware = (store : Store|any) => (next : Function) => (action : AnyAction) => {
    next(action);

    listener.onActionEndListeners.forEach(listener => {
        if (listener.actionType === action.type) {
            const dispatch = (action: AnyAction) => {
                return store.dispatch({
                    _fromListeningToActionEnd: listener.actionType,
                    ...action
                });
            };

            listener.listen(dispatch, store.getState());
        }
    });

    listener.onActionEndListeners2.forEach(listener => {
        if (listener.actionType !== action.type) {
            return true;
        }

        const dispatch = (action: AnyAction) => {
            return store.dispatch({
                _fromListeningToActionEnd: listener.actionType,
                ...action
            });
        };

        listener.handler(dispatch, store.getState(), action);
    });
};
