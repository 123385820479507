import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData, UiVehicle} from "../index";
import {
    formatVehicles,
    selectChoosedRightCatalogMaxVehicles,
    selectSelectedVehicles,
    selectUnselectedVehicles
} from "../utils";

type ActionPayload = {
    type: string,
    vehicleId: string,
};

type ActionCreatorPayload = {
    vehicleId: string,
};

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/vehicle-checked",
    create: (type, { vehicleId }) => ({ type, vehicleId }),
    reducer: (action, state) => {
        const vehicle: UiVehicle = {
            ...state.vehicles[action.vehicleId],
            checked: true,
        };

        const newState = {
            ...state,
            vehicles: {
                ...state.vehicles,
                [vehicle.id]: vehicle,
            }
        }

        const maxVehicles = selectChoosedRightCatalogMaxVehicles(state);
        const selectedVehicles = selectSelectedVehicles(newState);
        const unselectedVehicles = selectUnselectedVehicles(newState)
            .map(
                vehicle => {
                    return {
                        ...vehicle,

                        // Ici on regarde si maxVehicles est égal au nb de véhicule sélectionnés
                        // si c'est le cas on met tous les "available" en "unavailable-due-to-limit" on check
                        status: (maxVehicles === selectedVehicles.length) && vehicle.status === 'available'
                            ? 'unavailable-due-to-limit' // status explicite pour une message explicite "This permit is limited to a maximum of XX vehicles."
                            : vehicle.status,
                    }
                }
            );

        const vehicles = [
            ...selectedVehicles,
            ...unselectedVehicles,
        ];

        return {
            ...newState,
            vehicles: formatVehicles(vehicles),
        };
    }
});
