import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbAliasesStateData} from "../index";
import {enduserPaymentCybersourcePutAliasName} from "../../../../../core-react/src/modules/api/utils/xts/enduser-payment-cybersource/putAliasName";

type RequestActionCreatorPayload = {
    id: string,
    name: string,
}

export default newRequestActions<RequestActionCreatorPayload, DbAliasesStateData, void>(
    "/db/aliases/rename-alias",
    payload => enduserPaymentCybersourcePutAliasName(payload.id, payload.name),
    (action, state) => ({
        ...state,
    }),
    (action, state) => {
        const { id, name } = action.apiCallResult.request.data;
        const byId = state.byId;
        const newAlias = {
            ...byId[id].alias,
            name
        };

        return {
            ...state,
            byId: {
                ...byId,
                [id]: {
                    loading: false,
                    alias: newAlias,
                }
            }
        }
    },
    (action, state) => ({
        ...state,
    })
);
