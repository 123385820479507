import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {orderBPayPayment} from "../../../../../core-react/src/modules/api/utils/xts/order/postBPayPayment";
import {UiBPayPaymentStateData} from "../index";

type RequestActionCreatorPayload = {
    reference: string,
    bankAccountId: string,
    orderId: string,
    customerName: string,
    customerCountryCode: string,
    amountInCents: number,
    city: string
};

const actions = newRequestActions<RequestActionCreatorPayload, UiBPayPaymentStateData, any>(
    "ui/b-pay-payment/pay",
    orderBPayPayment,
    (action, state) => ({
        ...state,
        loading: true,
        disabledButton: true,
    }),
    (action, state) => ({
        ...state,
        loading: false,
        disabledButton: false
    }),
    (action, state) => ({
        ...state,
        loading: false,
        disabledButton: false
    }),
)

export const payUsingBPayRequestAction = actions.request;
export const payUsingBPaySuccessAction = actions.success;
export const payUsingBPayFailureAction = actions.failure;
