import {UiProfileFormStateData} from "../index";
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {customerContractsGetAddressAutocompleteHints} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postAddressAutocompleteHints";
import {AddressSuggestion} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/address-suggestion";


type RequestActionCreatorPayload = {
    city: string,
    address: string,
}

const actions = newRequestActions<RequestActionCreatorPayload, UiProfileFormStateData, Array<AddressSuggestion>>(
    "/ui/profile_form/autocomplete_hints",
    customerContractsGetAddressAutocompleteHints,
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        ...state,
        loading: false,
    }),
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;
