import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {AskMailConfirmationState} from "../index";

type ActionPayload = {
    type: string,
    title: string,
};

type ActionCreatorPayload = {
    title: string
};

export const AskMailConfirmationSetTitleAction = newAction<ActionPayload, AskMailConfirmationState, ActionCreatorPayload>({
    type: "/ask-mail-confirmation/set-title",
    create: (type, payload) => ({
        type,
        ...payload,
    }),
    reducer: (action, state) => ({
        ...state,
        title: action.title,
    })
});
