import {LogsState} from "./index";
import {LogsStateData} from "./types";

const selectState = (state: any): LogsStateData => state[LogsState.stateKey];

export default {
    state: selectState,
    actions: (state: any) => selectState(state)?.actions,

    // selectionner les 15 premiers éléments de l'array des actions
    // on va de la plus récente à la moins récente mais ce par 15 🧐
    latestActions: (limit: number = 15) => {
        return (state: any) => {
            const actions = selectState(state)?.actions || [];

            // on sort by date en DESC
            return actions.slice(0, limit);
        }
    }
}
