/**
 * Created by priano on 16/09/2020 at 11:29.
 */
import {DbProofsState, DbProofsStateData} from ".";
import {createSelector} from "reselect";
import {CustomerProof} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/customer-proof";

const select = (state: any): DbProofsStateData => state[DbProofsState.stateKey];
const allAsArray = createSelector(
    (state: any) => select(state).byId,
    all => Object.values(all).map(v => v.proof) as CustomerProof[]
);

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    all: (state: any) => select(state).byId,
    allAsArray,
    one: (id: string | number) => (state: any) => select(state).byId[id]?.proof,
    downloadingOne: (id: string | number) => (state: any) => (select(state)?.downloadQueue || {})[id],
}
