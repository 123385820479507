import {newAction} from "../../../../utils/redux/actionBuilder";
import {CoreSessionState} from "../state";
import jwt_decode from "jwt-decode";

type ActionPayload = { token: string };
type CreationPayload = { token: string };

export const CoreSessionStart = newAction<ActionPayload, CoreSessionState, CreationPayload>({
    type: "core/session/start",
    create: (type, {token}) => ({
        type,
        token
    }),
    reducer: (action, state) => {
        const decodedToken: any = jwt_decode(action.token);
        const cityIds = decodedToken.cityIds === '*.*' ? ['*'] : decodedToken.cityIds;

        const previousCityIsAvailable = state?.city && cityIds.indexOf(state?.city) !== -1;
        const city = previousCityIsAvailable ? state?.city : cityIds[0];

        return {
            token: action.token,
            tokenExpirationTimestamp: decodedToken.exp,
            city,
            user: {
                cities: cityIds,
                claims: decodedToken.claims,
                id: decodedToken.id,
                name: decodedToken.name
            },
        }
    }
});
