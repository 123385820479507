import {VehicleFormDependenciesState} from "../index";
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {Catalog} from "../../../../../core-react/src/modules/api/utils/xts/city/modeling/catalog";
import {businesscustomerCityCatalogsSearch} from "../../../../../core-react/src/modules/api/utils/xts/city/postCatalogSearch";
import {currentCity} from "../../../../../configuration/cities/_city";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";

type RequestActionCreatorPayload = void;

const actions = newRequestActions<RequestActionCreatorPayload, VehicleFormDependenciesState, Pagination<Catalog<'vehicleType'>>>(
    "/vehicle-form-dependencies/fetch-vehicle-types",
    payload => businesscustomerCityCatalogsSearch({
        city: currentCity().id,
        type: "vehicleType"
    }),
    (action, state) => ({
        ...state,
        vehicleTypes: {
            loading: true,
            data: []
        }
    }),
    (action, state) => ({
        ...state,
        vehicleTypes: {
            loading: false,
            data: action.apiCallResult.data.items.map(item => ({
                label: item.datum,
                value: item.datum,
            }))
        }
    }),
    (action, state) => ({
        ...state,
        vehicleTypes: {
            loading: false,
            data: [],
        }
    })
);

export const VehicleFormDependenciesFetchVehicleTypesRequestAction = actions.request;
export const VehicleFormDependenciesFetchVehicleTypesSuccessAction = actions.success;
export const VehicleFormDependenciesFetchVehicleTypesFailureAction = actions.failure;
