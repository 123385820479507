/**
 * Created by priano on 17/09/2020 at 09:19.
 */
import {Alias, DbAliasesState, DbAliasesStateData} from "./index";
import {createSelector} from "reselect";

const select = (state: any): DbAliasesStateData => state[DbAliasesState.stateKey];

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    all: (state: any) => select(state).byId,
    allAsArray: createSelector(
        (state: any) => select(state).byId,
        all => Object.values(all).map(v => v.alias) as Alias[]
    ),
    one: (id: string|number) => (state: any) => select(state).byId[id],
};
