import {connectRouter, createMatchSelector, goBack, goForward, push, RouterState} from "connected-react-router";
import {Route, routingHistory} from "../../index";
import {Action, combineReducers, Dispatch} from "redux";
import {newAction} from "../../../../utils/redux/actionBuilder";
import {objectsAreEqual} from "../../../../utils/comparaisons";
import {listener} from "../../../../utils/redux/middlewares/listeningMiddleware";
import {coreConfiguration} from "../../../../internal/coreConfiguration";

// Wrap et utilise le state de react-connected-router

export const coreRouterStateKey = "router"; // Not prefixing with 'core_' because we are doijng a shortcut for reac-connected-router state, which requires 'router' key reducer

// Selectors dépréciés // TODO REPLACE URPL PATH PAR CURRENT_ROUTE DANS CLIENT
export const selectUrlPath = (state: any): string => state[coreRouterStateKey].location.pathname;

// Dispatches dépréciés // TODO REPLACE PAR NAVIGATE_TO (via Navigator?)
export const navigateTo = (route: string, dispatch: Dispatch) => dispatch(push(route));
export const navigateBack = (dispatch: Dispatch) => dispatch(goBack());
export const navigateForward = (dispatch: Dispatch) => dispatch(goForward());

export const coreRouterReducer = connectRouter(routingHistory);
