import {UiProvidedProof} from "../../../../redux/modules_clean/ui/right-creation-workflow";
import * as blobUtil from "blob-util";
import {currentCity} from "../../../../configuration/cities/_city";

export async function mapProvidedProofToProofPayload(p: UiProvidedProof, vehicleId: any, personId: any) {
    // strip prefix et garder que le texte en base64 string
    const base64EncodedContent = await blobUtil.blobToBase64String(p.file);

    return {
        type: p.code,
        label: p.label,
        attachement: {
            fileName: p.file.name,
            base64EncodedContent
        },
        vehicleId,
        personId,
        city: currentCity().id,
    }
}

export function proofId(data: { code: string, label: string }) {
    return `${data.code}-${data.label}`;
}
