import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import listeners from "./listeners";
import {UiVehiclesStateData} from "./types";
import actions from "./actions";

const initialState: UiVehiclesStateData = {};

const stateKey = "ui.vehicles";

const reducer = reducerFromActionsObject(initialState, actions);

export const UiVehiclesState = {
    actions, stateKey, reducer
};

listeners();
