import {DbVehiclesStateData} from "../index";
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {Vehicle} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/vehicle";
import {customerContractsPutVehicle} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/putVehicle";
import {currentCity} from "../../../../../configuration/cities/_city";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";

type RequestActionCreatorPayload = {
    id: string,
    plate: string,
    country: string,
    brand?: string,
    model?: string,
    color?: string,
    fuelType?: string,
    kgWeightTotal?: string,
    literVolume?: string,
    gkmco2?: string | null,
    vin?: string,
    vehicleType?: string,
    driverId?: string | undefined,
    personId?: string | number,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbVehiclesStateData, Vehicle>(
    "/db/vehicles/modify-one",
    payload => ({ 
        ...customerContractsPutVehicle(payload.id, payload)
    }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => {
        const cancelledRight = action.apiCallResult.data;
        const vehicleId = cancelledRight.id;
        const byId = state.byId;

        return {
        ...state,
        byId: {
            ...byId,
            [vehicleId]: {
                loading: false,
                vehicle: cancelledRight
            }
        }
    }
    },
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;
