import {LogsStateData} from "./types";
import saveAction from './actions/saveAction';
import postLog from './actions/postLog';
import {reducerFromActionsObject} from "../../../core-react/src/utils/redux/reducerBuilder";
import selectors from "./selectors";

const initialState: LogsStateData = {
    actions: [], // Listes des actions saved depuis logs
};

const stateKey = "logs";

const actions = { saveAction, postLog };

const reducer = reducerFromActionsObject(initialState, actions);

export const LogsState = {
    stateKey, actions, reducer, selectors,
}
