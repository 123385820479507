/**
 * Created by priano on 25/08/2020 at 14:57.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {apiPersonToPerson, Person} from "./modeling/person";

const path = "/person/:personId";

type Payload = {
    personId: string | number,
};

export const customerContractsGetPerson = (payload: Payload): ApiCall<Person> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    urlParameters: payload,
    method: 'GET',
    formater: apiPersonToPerson,
    tokenRepository: 'xts',
});
