import {CoreConfiguration} from "../core-react/src";

export type Configuration = {
    core: CoreConfiguration,
    payment: {
        url: string,
        bankingAccountId: string,
    },
    logging?: {
        url: string,
    }
};

let _config: Configuration;

export const registerConfiguration = async (env?: string, configDotJson?: any) => {
    const module = await import(`./configuration.${configDotJson ? 'json' : 'development'}`);

    _config = module.default(configDotJson);
};

export const configuration = () => {
    return _config;
};
