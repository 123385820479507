import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {apiPaginationToPagination, Pagination} from "../../common/modeling";
import {apiProfileToProfile, Profile} from "./modeling/profile";

/**
 * Created by priano on 31/08/2020 at 16:07.
 */
const path = '/profile/search';

type Payload = {
    limit?: number | string,
    offset?: number | string,
    city: string,
    updatedSince?: string,
    id?: number | string
};

export const businesscustomerCityProfilesSearch = (payload: Payload): ApiCall<Pagination<Profile>> => ({
    url: coreConfiguration.api.xts.businesscustomerCityUrl + path,
    method: 'POST',
    body: payload,
    formater: apiPaginationToPagination(apiProfileToProfile),
    tokenRepository: 'xts',
})
