// 82 Rue du Méridien, 1210 Saint-Josse-ten-Noode, BE
import React from "react"
import {
    AddressKey,
    Address,
    AddressUsage
} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/address";
import {CustomerType} from "../../../../redux/modules/profile-form";

type AddressPart = AddressKey | { name: AddressKey, transforms?: Array<(value: any) => any> }

export function formatAddress(address: Address) {

    const format = addressFormats[address.country] || defaultAddressFormat;

    const formatted = format.map(
        (addressParts: AddressPart[]) => {

            // On pick les properties concernées dans l'object
            const values = addressParts.map(
                part => {

                    // si string rien de plus simple
                    if (typeof part === 'string') {
                        return address[part] ;
                    }

                    // si plus avancé on accepte aussi
                    if (typeof part === 'object') {
                        let value = address[part.name];

                        if (Array.isArray(part.transforms)) {
                            part.transforms.forEach((transform: any) => {
                                if (typeof transform === 'function') {
                                    value = transform(value);
                                }
                            })
                        }

                        return value;
                    }
                }
            );

            // On map les différents éléments d'address pour éliminer les valeurs vides
            // en ensuite les join séparés par un espace
            // On fait en sorte de ne pas avoir des infos à null
            // et donc en filtre pour n'afficher que ce qui est set
            return values.map(v => v && v.toString().trim()).filter(v => !!v).join(' ');
        }
    );

    // Return tous les éléments sous format de string séparé par des virgules;
    return formatted.filter(v => !!v).join(', ');
}


const localityUppercase = (locality: any) => locality.replace(/\b[a-z]/g, (letter: any) => letter.toUpperCase());

const localityPart: AddressPart = { name: 'locality', transforms: [localityUppercase] };

const defaultAddressFormat: Array<AddressPart[]> = [
    ['number', 'street'],
    ['postalCode', localityPart],
    ['country']
];

// I18N-AWARE: Ne pas oublier dans le cadre d'un nouveau pays
type AddressFormat = {
    [key: string]: Array<AddressPart[]>,
}

const addressFormats: AddressFormat = {
    FR: defaultAddressFormat,
    DE: defaultAddressFormat,
    US: [
        ['number', 'street'],
        [localityPart, 'state', 'postalCode'],
        ['country']
    ],
    AU: [
        ['number', 'street'],
        [localityPart, 'postalCode'],
        ['state'],
        ['country']
    ]
}

/**
 * Sélectionner dans la liste des addresses d'une personne une adresse selon l'usage
 * @param addresses La liste des adresses
 * @param usage filtrer selon l'usage
 */
export const selectPersonAddressByUsage = (addresses: Address[], usage: AddressUsage) => {
    const address = (addresses || []).find(a => a.usage === usage);

    if (!address) {
        return;
    }

    return address;
};

export const customerTypesRequiringAdditionnalFields: CustomerType[] = ['Organization', 'UneStudent', 'UneStaff', 'UneContractor'];

export const internalAccommodationOptions = [
    "Do not live on campus",
    "Austin College",
    "Duval College",
    "Earle Page College",
    "Mary White College",
    "Robb College",
    "St Alberts College",
    "Wright College",
    "Wright Village",
    "Drummond/Smith College",
];
