import {Store} from "redux";
import {call} from "../utils/apiCaller";
import {getBrowserLanguage} from "../../../utils/lang";

const REQUEST = '/request';
const SUCCESS = '/success';
const FAILURE = '/failure';

export type TokenRepository = 'xts' | 'xts-account' | 'xts-or-xts-account';

const tokenRepositories: {[key: string]: (state: any) => string|undefined} = {};

export const registerTokenRepository = (key: TokenRepository, fetchToken: (state: any) => string|undefined) => {
    tokenRepositories[key] = fetchToken;
};

export const apiMiddleware = (store : Store|any) => (next : Function) => async (action : any) => {
    next(action);

    if (! action.type.endsWith(REQUEST)) {
        return;
    }

    if (! action.apiCall) {
        console.error('Action ', action.type, ' is of type /request but does not register an apiCall');
        return;
    }

    const headers: any = {};

    const authorizationHeaderAlreadyDefined = !!action.apiCall.headers?.Authorization;
    const tokenRepository = action.apiCall.tokenRepository;
    let token: string|undefined;

    if (!authorizationHeaderAlreadyDefined && tokenRepository && tokenRepositories[tokenRepository] !== undefined) {
        token = tokenRepositories[tokenRepository](store.getState());
    }

    const authorization = token ? `Bearer ${token}` : null;
    if (authorization) {
        headers.Authorization = authorization;
    }

    // Accept-Language Header
    const state = store.getState();

    // Get du customer dans le state et utilsation de sa langue dans le accept-header si default language browser
    const customer = state?.session?.customer;
    headers['Accept-Language'] = customer?.language || getBrowserLanguage();

    const result = await call(action.apiCall, headers);

    const actionName = action.type.replace(REQUEST, '');

    //setTimeout(() => {
        store.dispatch({
            _fromListeningToActionEnd: action.type,
            type: actionName + (result.errored ? FAILURE : SUCCESS),
            apiCallResult: result
        });
    //}, 1000);
};
