import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiProvidedProof, UiRightCreationWorkflowStateData, UiVehicle} from "../index";
import {proofId} from "../../../../../components/content/domain/right-creation-workflow/utils";

type ActionPayload = {
    type: string,
    proof: UiProvidedProof,
};

type ActionCreatorPayload = {
    proof: UiProvidedProof,
};

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/provide-proof",
    create: (type, { proof }) => ({ type, proof }),
    reducer: (action, state) => {
        const key = proofId({
            code: action.proof.code,
            label: action.proof.label,
        });

        const providedProofs = {
            ...state.providedProofs,
            [key]: action.proof,
        };

        return {
            ...state,
            providedProofs
        }
    }
});
