import {DbVehicleDriversStateData, DbVehicleDriversState} from "./index";
import {createSelector} from "reselect";
import {VehicleDriver} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/driver";

const select = (state: any): DbVehicleDriversStateData => state[DbVehicleDriversState.stateKey];

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    all: (state: any) => select(state).byId,
    allAsArray: createSelector(
        (state: any) => select(state).byId,
        all => Object.values(all).map(v => v.driver) as VehicleDriver[]
    ),
    one: (id: string|number) => (state: any) => select(state).byId[id],
    lastCreatedDriver: (state: any) => select(state).lastCreatedDriver,
}
