import {RightCreationFormState} from "../index";
import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {RequiredProof} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/required-proof";
import {customerContractsPostRightCatalogRequiredProof} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/postRightCatalogRequiredProof";

export type RequestActionCreatorPayload = {
    deliveryChannel?: string,
    limit?: string,
    offset?: string,
    startDate?: string,
    endDate?: string,
    vehicleId?: number | string,
    personId?: number | string,
    rightCatalogId?: number | string,
    city: string
}

const actions = newRequestActions<RequestActionCreatorPayload, RightCreationFormState, RequiredProof[]>(
    "/fetch_required_proofs",
    customerContractsPostRightCatalogRequiredProof,
    (action, state) => ({
        ...state,
        options: {
            ...state.options,
            requiredProofsLoading: true,
        }
    }),
    (action, state) => ({
        ...state,
        options: {
            ...state.options,
            requiredProofs: action.apiCallResult.data,
            requiredProofsLoading: false,
            requiredProofsLoaded: true,
        }
    }),
    (action, state) => ({
        ...state,
    })
);

export const FetchRequiredProofsRequestAction = actions.request;
export const FetchRequiredProofsSuccessAction = actions.success;
export const FetchRequiredProofsFailureAction = actions.failure;

