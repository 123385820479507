import {DbVehiclesStateData} from "../index";
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {Vehicle} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/vehicle";
import {customerContractsPostVehicleSearch} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postVehicleSearch";
import {currentCity} from "../../../../../configuration/cities/_city";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";

type RequestActionCreatorPayload = {
    personId: number,
    offset?: string,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbVehiclesStateData, Pagination<Vehicle>>(
    "/db/vehicles/fetch-all",
    payload => ({
        ...customerContractsPostVehicleSearch({
            ...payload,
            city: currentCity().id
        }),
        meta: {
            personId: payload.personId
        }
    }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => {
        const oldById = state.byId;
        const newById = formatVehicles(action.apiCallResult.data.items);

        return {
            loading: false,
            byId: {
                ...oldById,
                ...newById,
            }
        }
    },
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;

const formatVehicles = (vehicles: Vehicle[]) => {
    const result: any = {};

    vehicles.forEach(vehicle => {
        result[vehicle.id] = {
            loading: false,
            vehicle
        };
    });

    return result;
};
