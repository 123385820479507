import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {Jwt, apiJwtToJwt} from "./modeling/jwt";

const path = "/account/login/customer/:id";

type Payload = {
    id: string|number
};

export const customerContractsPostAccountLoginCustomer = (payload: Payload): ApiCall<Jwt> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: "POST",
    urlParameters: payload, // ou en url Param
    formater: apiJwtToJwt,
    tokenRepository: "xts-account",
});
