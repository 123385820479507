import {DbAreaState, DbAreaStateData} from "./index";
import {createSelector} from "reselect";
import {Area} from "../../../../core-react/src/modules/api/utils/xts/city/modeling/area";

const select = (state: any): DbAreaStateData => state[DbAreaState.stateKey];

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    allAsArray: createSelector(
        (state: any) => select(state).byId,
        all => Object.values(all).map(v => v.area) as Area[]
    ),

}