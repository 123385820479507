import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {AskMailConfirmationState} from "../index";
import {ApiCall} from "../../../../core-react/src/modules/api";
import {customerContractsPostAccountAskValidationEmail} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/postAccountAskValidationEmail";

type ActionPayload = {
    type: string,
    apiCall: ApiCall<string>
};

type ActionCreatorPayload = {
    confirmationUrl: string,
    city: string,
    email: string,
};

export const AskMailConfirmationRequestAction = newAction<ActionPayload, AskMailConfirmationState, ActionCreatorPayload>({
    type: "/ask-mail-confirmation/request",
    create: (type, payload) => ({
        type,
        apiCall: customerContractsPostAccountAskValidationEmail(payload)
    }),
    reducer: (action, state) => ({
        ...state,
        loading: true,
    })
});
