import {Vehicle} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/vehicle";
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import fetchAll from './actions/fetchAll';
import selectors from "./selectors";
import writeOne from "./actions/writeOne";
import modifyOne from "./actions/modifyOne";
import deleteOne from "./actions/deleteOne";
import localDeleteOne from "./actions/localDeleteOne";
import {listener} from "../../../../core-react/src/utils/redux/middlewares/listeningMiddleware";
import {CoreNotify} from "../../../../core-react/src/modules/notifications/redux/state";
import {getTranslation} from "../../../../translations/useTranslation";

export type DbVehiclesStateData = {
    loading: boolean, // All vehicles being fetched
    byId: {
        [id: string]: {
            loading: boolean, // One vehicle being fetched
            vehicle?: Vehicle // todo undefinable ou ap.
        }
    }
};

const initialState: DbVehiclesStateData = {
    loading: false,
    byId: {}
};

const actions = { fetchAll, writeOne, modifyOne, deleteOne, localDeleteOne };

const stateKey = "db.vehicles";

const reducer = reducerFromActionsObject(initialState, actions);

export const DbVehiclesState = {
    actions, stateKey, reducer, selectors
};

// Gestiopn pagination fetcAll des véhicules
listener.onActionEnd(fetchAll.success, (dispatch, state, action) => {
    const data = action.apiCallResult.data as any;
    const meta = action.apiCallResult.meta;

    const vehiclesCount = DbVehiclesState.selectors.count(state);

    const { itemsAvailable, itemsLimit: limit, itemsOffset } =  data;

    // calcul du nouvel offset si jamais besoin de reload
    const newOffset = itemsOffset + limit;

    // refetch si le nombre d'itemsAvailable est sup au nombre de véhicles déjà loaded
    if (itemsAvailable > vehiclesCount) {
        dispatch(DbVehiclesState.actions.fetchAll.request({
            personId: meta.personId,
            offset: newOffset.toString(),
        }));
    }
});

listener.onActionEnd(modifyOne.failure, (dispatch, state, action) => {
    const data = action.apiCallResult.data as any;
    // if (data && data[0]?.message === 'PLATE_ALREADY_EXISTS') {
    //     return dispatch(CoreNotify.failure(
    //         'The license plate is already registered'
    //     ));
    // }
    //
    // dispatch(CoreNotify.failure(
    //     "An error happened"
    // ));
});

listener.onActionEnd(modifyOne.success, (dispatch, state, action) => {
    const translations = getTranslation();

    dispatch(CoreNotify.success(
        translations.vehicleModified
    ));
});

listener.onActionEnd(deleteOne.failure, ((dispatch, state, action) => {
    const data = action.apiCallResult.data as any;
    // if (data && data.error === "There is still a valid right. Deletion is forbidden.") {
    //     return dispatch(CoreNotify.failure(
    //         "There is still a valid permit attached to the vehicle. Its deletion is forbidden."
    //     ));
    // }
    //
    // dispatch(CoreNotify.failure(
    //     "The state of this vehicle forbids its deletion"
    // ));
}));

listener.onActionEnd(deleteOne.success, (dispatch, state, action) => {
    const translations = getTranslation();

        dispatch(CoreNotify.success(
            translations.vehicleDeleted
        ));
});
