import {getTranslation} from "../../../../translations/useTranslation";

const translations = getTranslation();

const ERRORS = {
    requiredField: translations.thisFieldIsMandatory,
};

type Errors = string[];

export type FormStatus<DTO> = {
    valid: boolean,
    fields: {
        [key in keyof DTO]: {
            changed: boolean, // True when field has been changed or form submitted
            validity?: 'valid' | 'invalid',
            errors: Errors
        }
    }
};

// TODO SUITE :
//  - Bouton reset
//  - Restriction emailFormat
//  - Action form
export type FormStatusConfiguration<DTO> = {
    fields: {
        [key in keyof DTO]: {
            required: boolean,
            validator?: (dto: DTO) => Errors
        }
    }
};

export type FormStatusComputationCause<DTO> = {
    fieldChanged?: keyof DTO,
    formSubmitted?: boolean
}

export function formStatusComputer<DTO>(dto: DTO, cause: FormStatusComputationCause<DTO>, config: FormStatusConfiguration<DTO>, previousStatus?: FormStatus<DTO>): FormStatus<DTO> {
    const fields = Object.keys(config.fields);
    const nextStatus: any = {
        valid: true,
        fields: {}
    };

    for (let _field of fields) {
        const field = _field as keyof DTO;

        const fieldHasChanged = previousStatus?.fields[field]?.changed || cause.fieldChanged === field || cause.formSubmitted;
        if (!fieldHasChanged) {
            nextStatus.fields[field] = { changed: false, errors: [] };
            continue;
        }

        let errors = [];
        if (config.fields[field].required && ([null, undefined, ''] as any[]).indexOf(dto[field]) > -1) {
            errors.push(ERRORS.requiredField);
        }

        const validator = config.fields[field].validator || (() => []);

        errors = errors.concat(validator(dto));

        nextStatus.fields[field] = {
            changed: true,
            validity: errors.length === 0 ? 'valid' : 'invalid',
            errors: errors
        };
        if (errors.length) {
            nextStatus.valid = false;
        }
    }

    return nextStatus;
}
