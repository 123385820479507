import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbPrepaidAccountStateData} from "../index";
import {currentCity} from "../../../../../configuration/cities/_city";
import {Payment} from "../../../../../core-react/src/modules/api/utils/xts/order/modeling/payment";
import {orderPrepaidAccountPayment} from "../../../../../core-react/src/modules/api/utils/xts/order/postPrepaidAccountPayment";

type RequestActionCreatorPayload = {
    date: string,
    channel: 'DESK' | 'ONLINE'| 'MOBILE',
    amount: number,
    invoiceId: string | number,
    accountId: string | number,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbPrepaidAccountStateData, Payment>(
    "/db/prepaid-account/pay",
    payload => orderPrepaidAccountPayment({
        ...payload,
        city: currentCity().id
    }),
    (action, state) => ({
        ...state,
        paymentStatus: 'processing'
    }),
    (action, state) => ({
        ...state,
        paymentStatus: 'completed'
    }),
    (action, state) => ({
        ...state,
        paymentStatus: 'failed'
    })
);

export default actions;
