import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

type Payload = {
    email: string,
    resetUrl: string,
    city: string
};

const path = "/account/ask/changePassword";

export const customerContractsPostAccountAskChangePassword = (payload: Payload): ApiCall<string> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: str => str,
});