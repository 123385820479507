import {put, select, take, takeEvery, throttle, race, fork, delay, call, takeLatest} from "redux-saga/effects";
import {SessionEndCustomerConnectionProcessSuccessAction} from "../../../modules/session/actions/endCustomerConnectionProcess";
import {sessionSelectors} from "../../../modules/session";
import {DbPersonState} from "../../db/person";
import {DbVehiclesState} from "../../db/vehicles";
import {DbRightsState} from "../../db/rights";
import {DbInvoicesState} from "../../db/invoices";
import {DbProfilesState} from "../../db/profiles";
import {currentCity} from "../../../../configuration/cities/_city";
import {OrderConfigurationState} from "../../configuration/order";
import {BusinessCityConfigurationState} from "../../configuration/businessCustomer";
import {CustomerContractsState} from "../../configuration/customerContracts";
import {DbProofsState} from "../../db/proofs";
import {FranceConnectState} from "../../../modules/france-connect";
import {DbPrepaidAccountState} from "../../db/prepaidAccount";
import {DbAreaState} from "../../db/areas";
import {DbAliasesState} from "../../db/aliases";
import {AccountPaymentMethod} from "../../../../core-react/src/modules/api/utils/xts/order/modeling/city";

export const INIT_ACTION_TYPE = 'jean-initialize';

export default function* sagas() {
    yield takeEvery(SessionEndCustomerConnectionProcessSuccessAction.type, init);
    yield fork(listenForInitializationsRequests);
    yield put({
        type: INIT_ACTION_TYPE
    });
    yield reInit();
}

// Only take init starters actions, when the previous one is older than 5 seconds ago
// -- (The connection will probably still overlapse the jean-initialize, but osef)
function *listenForInitializationsRequests() {
    while ('jean-clebs') {
        yield take(INIT_ACTION_TYPE);
        yield init();
        yield delay(3000);
    }
}

function* init() {
    // Load de la config à l'init direct pas besoin d'être co
    yield put(BusinessCityConfigurationState.actions.loadConfiguration.request({ city: currentCity().id }));
    yield put(FranceConnectState.actions.checkFranceConnectConfiguration.request({ city: currentCity().id }));

    // Ne fetch la conf customer que si accountIsConnected
    const accountIsConnected = yield select(sessionSelectors.accountIsConnected);

    if (accountIsConnected) {
        yield put(CustomerContractsState.actions.loadConfiguration.request({ city: currentCity().id }));
    }

    const isConnected = yield select(sessionSelectors.customerIsConnected);
    if (!isConnected) {
        return;
    }

    const personId = yield select(sessionSelectors.customerId);

    yield put(DbPersonState.actions.fetch.request({id: personId}));
    yield put(DbVehiclesState.actions.fetchAll.request({personId}));
    yield put(DbRightsState.actions.fetchAll.request({personId}));
    yield put(OrderConfigurationState.actions.loadConfiguration.request({ city: currentCity().id }));
    yield put(DbProfilesState.actions.fetchAll.request({ city: currentCity().id }));
    yield put(DbProofsState.actions.fetchAll.request({ personId }));
    yield put(DbPrepaidAccountState.actions.fetchAll.request({ externalAccount: personId }));
    yield put(DbAreaState.actions.fetchAll.request({city: currentCity().id}));

    // On attend que le call de la conf d'order se termine (soit success soit failure) pour continuer
    const { apiCallResult: orderConfResult } = yield take([OrderConfigurationState.actions.loadConfiguration.success.type, OrderConfigurationState.actions.loadConfiguration.failure.type]);

    // On fait le call ici parce que l'on a besoin qu'il soit fait après que la conf order soit load
    yield put(DbInvoicesState.actions.fetchAll.request({externalAccountId: personId}));

    // Si le load de la config d'order fail on ne fait rien
    if (orderConfResult.errored) {
        return;
    }

    // On vérifie si le moyen de paiement 'cybersource' existe dans la config order pour éventuellement faire le call d'aliases
    if ((orderConfResult.data.accountPaymentMethod || []).findIndex((p: AccountPaymentMethod) => p.id === 'cybersource') < 0) {
        return;
    }

    // On attend le fin du call, pour récupérer le customerId et faire le call d'aliases
    yield take([DbInvoicesState.actions.fetchAll.success.type, DbInvoicesState.actions.fetchAll.failure.type]);
    const customerId = yield select(DbInvoicesState.selectors.orderAccountId);

    // On fait ce call pour avoir les aliases quand on arrive sur la homepage
    yield put(DbAliasesState.actions.fetchAll.request({customerId, city:currentCity().id}));
}

function *reInit() {
    // Query param pour gérer le pb du paiement et activé une tempo avant de reinit la le tout
    // un param d’url alors me parait pertinent, qui permettra de définir qu’on doit relancer
    // un autre fetch complet (action init) N secondes après le premier init
    // const urlParams = new URLSearchParams(window.location.search);
    // const needInit = urlParams.get('init');

    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const needInit = searchParams.get('init');

    // Pas Besoin de refetch le tout ? On ne continu pas
    if (! needInit) {
        return;
    }

    // Delete the init parameter.
    searchParams.delete('init');

    // get the new url
    const newUrl = url.toString();

    // replace current URL without adding it to history entries
    window.history.replaceState({}, document.title, newUrl);

    // un sleep 1s dans la redirection suffirait ? NON, car Nico ne take que les actions JEAN_INITIALIZE vielles de 3 sec ... 🕒
    // si tu ne lis pas attentivement et n'analyse pas la manière dont a été faite la saga bah tu perds ton temps car tu penses que le code n'est jamais appelé.
    // Bah oui il faut lire c'est pas que le code n'est jamais appelé,
    // c'est juste que le bon view Charpentier est gérontophile 👵 et ne take que les actions cougars 🐆.
    // Du coup moi après avoir perdu mon temps je push un JEAN_INITIALIZE 4 sec après le premier 🕒 pour être sur que ça sera traité ✨
    yield delay(4000);
    yield put({
        type: INIT_ACTION_TYPE
    });
}
