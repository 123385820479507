import {newAction, newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbVehiclesStateData} from "../index";
import {Vehicle} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/vehicle";
import { customerContractsDeleteVehicle } from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/deleteVehicle";

type ActionPayload = {
    vehicleId: number,
};

type ActionCreatorPayload = {
    vehicleId: number,
};

export default newRequestActions<ActionCreatorPayload, DbVehiclesStateData, Vehicle>(
    "/db/vehicles/delete-one",
    (payload) => {
        const { vehicleId } = payload;
        return { 
            ...customerContractsDeleteVehicle({vehicleId: vehicleId}),
            meta: {
                id: vehicleId
        }};
    },
    (action, state) => ({
        ...state,
    }),
    (action, state) => {
        let byId = state.byId;
        const removedVehicleId = action.apiCallResult.meta;
        
        delete byId[removedVehicleId.id];

        return {
            ...state,
            byId: {
                ...byId,
            }
        }
    },
    (action, state) => ({
        ...state,
    })
);
