import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiProfileFormStateData} from "../index";

type ActionPayload = {
    type: string,
}

type ActionCreatorPayload = void;

export default newAction<ActionPayload, UiProfileFormStateData, ActionCreatorPayload >({
    type: "/ui/profile_form/fill_billing_address",
    create: (type: string) => ({
        type,
    }),
})
