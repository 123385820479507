import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import getCity from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/getCity";
import {CustomerContractsConfigurationStateModel} from "../index";
import {CustomerContratsCity} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/customer-contracts-city";

type RequestActionCreatorPayload = {
    city: string,
};

export default newRequestActions<RequestActionCreatorPayload, CustomerContractsConfigurationStateModel, CustomerContratsCity>(
    "/configuration/customer-contracts/get-city",
    payload => getCity(payload.city),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => {

        // Store de __CUSTOMER_TITLES__ en global dans le windows pour un une validation coté gender
        (window as any).__CUSTOMER_TITLES__ = action.apiCallResult.data.customerTitles;

        return ({
            loading: false,
            loaded: true,
            configuration: action.apiCallResult.data,
        });
    }
    ,
    (action, state) => ({
        ...state,
        loading: false,
        loaded: true,
    })
);
