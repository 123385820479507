import {ApiCall} from "../../../index";

import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {Pagination} from "../../common/modeling";
import {PrepaidAccount} from "./modeling/prepaidAccount";

const path = "/prepaid/account/search";

type Payload = {
    limit?: string,
    offset?: string,
    externalAccount?: string,
    city?: string,
    updatedSince?: string,
};

export const orderSearchPrepaidAccount = (payload: Payload): ApiCall<Pagination<PrepaidAccount>> => ({
    url: coreConfiguration.api.xts.orderUrl + path,
    body: payload,
    method: 'POST',
    formater: r => r,
    tokenRepository: 'xts',
});
