import {Formater} from "../../../common/modeling";

type ApiRawVehicleDriver = {
    firstname: string,
    lastname: string,
    person: any,
    id: number,
    created: string,
    updated: string,
    deleted: string
};

export type VehicleDriver = {
    id: string,
    firstName: string,
    lastName: string,
};

export const apiVehicleDriverToDriver: Formater<ApiRawVehicleDriver, VehicleDriver> = apiDriver => ({
    id: apiDriver.id.toString(),
    firstName: apiDriver.firstname,
    lastName: apiDriver.lastname,

    // Dans la sélection des droits parfois on a undefined undefined pour les driver
    // par sécurité je rajoute ça ou lieu de remove la réécriture ci dessus
    // car je ne comprends pas pourquoi il a fallu le réécrire ça firstname -> firstName
    firstname: apiDriver.firstname,
    lastname: apiDriver.lastname,
});
