/**
 * Created by priano on 16/09/2020 at 11:29.
 */
import {Right} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";
import fetchAll from "./actions/fetchAll";
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import selectors from "./selectors";
import writeOne from "./actions/writeOne";
import cancelOne from './actions/cancelOne';
import swapOne from './actions/swapOne';
import addOne from './actions/addOne';
import {listener} from "../../../../core-react/src/utils/redux/middlewares/listeningMiddleware";
import {CoreNotify} from "../../../../core-react/src/modules/notifications/redux/state";
import {getTranslation} from "../../../../translations/useTranslation";
import sagas from "./saga";

export type DbRightsStateData = {
    completionPercentage?: number,
    loading: boolean, // All rights being fetched
    byId: {
        [id: string]: {
            loading: boolean, // One right being fetched
            right?: Right
        }
    }
}

const initialState: DbRightsStateData = {
    completionPercentage: 0,
    loading: false,
    byId: {}
};

const actions = { fetchAll, writeOne, cancelOne, swapOne, addOne };

const stateKey = "db.rights";

const reducer = reducerFromActionsObject(initialState, actions);

export const DbRightsState = {
    actions, stateKey, reducer, selectors, sagas
};

listener.onActionEnd(cancelOne.success, (dispatch, state, action) => {
    const translations = getTranslation();

    dispatch(CoreNotify.success(
        translations.permitCanceled
    ));
});

listener.onActionEnd(cancelOne.failure, (dispatch, state, action) => {
    // dispatch(CoreNotify.failure(
    //     "An error happened"
    // ));
});

listener.onActionEnd(swapOne.failure, (dispatch, state, action) => {
    // dispatch(CoreNotify.failure(
    //     "An error happened"
    // ));
});

listener.onActionEnd(addOne.failure, (dispatch, state, action) => {
    // dispatch(CoreNotify.failure(
    //     "An error happened"
    // ));
});
