import {newAction, newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbVehicleDriversStateData} from "../index";
import {VehicleDriver} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/driver";
import {customerContractsDeleteVehicleDriver} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/deleteVehicleDriver";
import _ from "lodash";

type ActionPayload = {
    type: string,
    driver: VehicleDriver,
};

type RequestActionCreatorPayload = {
    id: string
};

// Call writeOne avec direct le retour de post ou putCustomerContractsVehicleDriver()

const actions = newRequestActions<RequestActionCreatorPayload, DbVehicleDriversStateData, void>(
    "/db/vehicleDriers/delete-one",
    payload => ({
        ...customerContractsDeleteVehicleDriver(payload),
        meta: payload,
    }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        loading: false,
        byId: _.omit(state.byId, action.apiCallResult.meta.id)
    }),
    (action, state) => ({
        ...state,
        loading: false,
    }),
);

export default actions;