import fetchAll from "./actions/fetchAll";
import fetchOne from "./actions/fetchOne";
import fetchRightInvoices from "./actions/fetchRightInvoices";
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import selectors from "./selectors";
import {Invoice} from "../../../../core-react/src/modules/api/utils/xts/order/modeling/invoice";

export type DbInvoicesStateData = {
    loading: boolean, // All rights being fetched
    loaded: boolean,
    byId: {
        [id: string]: {
            loading: boolean, // One right being fetched
            invoice: Invoice,
        }
    }
}

const initialState: DbInvoicesStateData = {
    loading: false,
    loaded: false,
    byId: {}
};

const actions = { fetchAll, fetchOne, fetchRightInvoices };

const stateKey = "db.invoices";

const reducer = reducerFromActionsObject(initialState, actions);

export const DbInvoicesState = {
    actions, stateKey, reducer, selectors
};
