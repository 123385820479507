import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbProofsStateData} from "../";
import {customerContractsPostDownloadProof} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postDownloadProof";
import * as fileSaver from 'file-saver';

type RequestActionCreatorPayload = {
    proofId: string
};

const actions = newRequestActions<RequestActionCreatorPayload, DbProofsStateData, Blob>(
    "/db/proofs/download-one",
    payload => customerContractsPostDownloadProof({ id: payload.proofId }),
    (action, state) => {
        const { id: proofId } = action.apiCall.meta;

        return {
            ...state,
            downloadQueue: {
                ...state.downloadQueue,
                [proofId]: true
            }
        }
    },
    (action, state) => {
        const { data: blob } = action.apiCallResult;
        const { id: proofId } = action.apiCallResult.meta;

        const { proof } = state.byId[proofId];

        fileSaver.saveAs(blob, proof?.label)


        const newDownloadQueue: any = {
            ...state.downloadQueue,
        };

        delete newDownloadQueue[proofId];

        return {
            ...state,
            downloadQueue: newDownloadQueue,
        }
    },
    (action, state) => state
);

export default actions;
