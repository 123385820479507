import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiProfileFormStateData} from "../index";
import {AddressSuggestion} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/address-suggestion";

type ActionPayload = {
    type: string,
    suggestions: AddressSuggestion[],
};

type ActionCreatorPayload = {
    suggestions: AddressSuggestion[],
};

export default newAction<ActionPayload, UiProfileFormStateData, ActionCreatorPayload>({
    type: "/ui/profile_form/fill_address_suggestions",
    create: (type, payload) => ({
        type,
        ...payload,
    }),
    reducer: (action, state) => ({
        ...state,
        addressSuggestions: action.suggestions,
    })
});
