import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import fetchRightCatalogs from "./actions/fetchRightCatalogs";
import fetchRightCatalogsForVehicle from "./actions/fetchRightCatalogsForVehicle";
import rightCreationNext from "./actions/rightCreationNext";
import rightCreationPrevious from "./actions/rightCreationPrevious";
import markIndexAsCompleted from "./actions/markIndexAsCompleted";
import chooseRightCatalogId from "./actions/markRightCatalogIdAsChoosed"
import rightCreationSetVehicles from "./actions/rightCreationSetVehicles";
import markVehicleAsChecked from "./actions/markVehicleAsChecked";
import markVehicleAsUnchecked from "./actions/markVehicleAsUnchecked";
import rightCreationVehicleSelectionArrived from "./actions/rightCreationVehicleSelectionArrived";
import computeDatesValidity from "./actions/computeDatesValidity";
import updateValidityPeriodEndDate from "./actions/updateValidityPeriodEndDate";
import chooseRightCatalogArea from "./actions/chooseRightCatalogArea";
import fetchRequiredProofs from "./actions/fetchRequiredProofs";
import provideProof from "./actions/provideProof";
import removeProvidedProof from "./actions/removeProvidedProof";
import uploadProof from "./actions/uploadProof";
import createRight from "./actions/createRight";
import realCreateRight from "./actions/realCreateRight";
import submitRightToApprobation from "./actions/submitRightToApprobation";
import resetRightCreationForm from "./actions/resetRightCreationForm";
import setSpecificFieldsValuesAndFormValidState from "./actions/setSpecificFieldsValuesAndFormValidState";
import selectors from "./selectors";
import sagas from './sagas'
import {Area} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/area";
import {RequiredProof} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/required-proof";
import {Price} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/price";
import rightCreationAddVehicles from "./actions/rightCreationAddVehicles";
import {SpecificField} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right-catalog";

type i18n = {
    [key: string]: string,
    _id: string,
};

export type UiRightCatalog = {
    id: string,
    label: i18n,
    profile: string,
    areas: Area[],
    prices: Price[],
    shallAllowParking: boolean,
    shallUseAccountVehicles: boolean,
    maxVehicles?: number,
    specificFields: Array<SpecificField>,
};

export type UiVehicleStatus = 'checking' | 'available' | 'not-available' | 'unavailable-due-to-limit';

export type UiVehicle = {
    id: string,
    plate: string,
    model: string,
    brand: string,
    driver?: string, // nom prenom du driver
    status: UiVehicleStatus,
    checked: boolean,
};

export type UiProvidedProof = {
    code: string,
    label: string,
    file: File,
}

export type UiRightCreationWorkflowStateData = {
    activeStepIndex: number,            // L'index actif courant
    totalStepCount: number,             // nb de step au total dans le workflow - set dans l'initialState est n'est jamais changé
    completedIndexes: number[],         // Array contenant les indexes sur lesquels on est déjà passé
    rightCatalogs: Array<UiRightCatalog>,
    rightCatalogsStatus?: 'loading' | 'fetched' | 'failed',
    validityPeriodStatus?: 'computing' | 'computed' | 'failed',
    validityPeriod?: {
        validityStart: Date;
        validityEnd: Date;
        readOnlyStart: boolean;
        readOnlyEnd: boolean;
    },
    requiredProofs: { [key: string]: RequiredProof },
    requiredProofsStatus?: 'loading' | 'fetched' | 'failed',
    vehicles: { [key: string]: UiVehicle },
    choosedRightCatalogId?: string,
    choosedProfileId?: string,
    choosedArea?: Area,
    providedProofs: { [key: string]: UiProvidedProof },
    rightCreationStatus?: 'sending' | 'sent' | 'failed-to-send' | 'submitting' | 'submitted' | 'submission-failed',
    specificFieldsFormIsValid?: boolean,
    specificFieldsValues?: any, // les specific fields seront saved ici s'ils sont demandés
};

export const initialState: UiRightCreationWorkflowStateData = {
    activeStepIndex: 0,
    totalStepCount: 6,
    completedIndexes: [],
    rightCatalogs: [],
    rightCatalogsStatus: undefined,
    validityPeriodStatus: undefined,
    validityPeriod: undefined,
    requiredProofs: {},
    requiredProofsStatus: undefined,
    vehicles: {},
    choosedRightCatalogId: undefined,
    choosedProfileId: undefined,
    choosedArea: undefined,
    providedProofs: {},
    rightCreationStatus: undefined,
    specificFieldsFormIsValid: false,
    specificFieldsValues: undefined,
};

const actions = {
    fetchRightCatalogs,
    fetchRightCatalogsForVehicle,
    rightCreationNext,
    rightCreationPrevious,
    markIndexAsCompleted,
    chooseRightCatalogId,
    rightCreationVehicleSelectionArrived,
    rightCreationSetVehicles,
    markVehicleAsChecked,
    markVehicleAsUnchecked,
    computeDatesValidity,
    updateValidityPeriodEndDate,
    chooseRightCatalogArea,
    fetchRequiredProofs,
    provideProof,
    removeProvidedProof,
    uploadProof,
    createRight,
    realCreateRight,
    submitRightToApprobation,
    resetRightCreationForm,
    rightCreationAddVehicles,
    setSpecificFieldsValuesAndFormValidState,
};

const stateKey = "ui.right-creation";

const reducer = reducerFromActionsObject(initialState, actions);

export const UiRightCreationWorkflowState = {
    actions, stateKey, reducer, selectors, sagas
};

export const rightCreationWorkflowSelectors = selectors;



