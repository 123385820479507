import {BusinessCityConfigurationState, BusinessCityConfigurationStateModel} from "./index";
import {createSelector} from "reselect";
import {City} from "../../../../core-react/src/modules/api/utils/xts/city/modeling/city";

const select = (state: any): BusinessCityConfigurationStateModel => state[BusinessCityConfigurationState.stateKey];

const accessState = (path: keyof BusinessCityConfigurationStateModel) => (state: any) => select(state)[path];

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    loaded: (state: any) => select(state).loaded,
    configuration: (state: any) => select(state).configuration,
    languages: (state: any) => select(state).configuration?.languages,
    pickInConfiguration: (path: keyof City, transform?: (value: any) => any) => {
        return createSelector(
            (state: any) => select(state).configuration as City,
            (conf: City) => transform ? transform(conf[path]) : conf[path],
        );
    },
}
