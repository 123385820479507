import {useDispatch, useSelector} from "react-redux";
import {UiPasswordChangeDemandState} from "./index";
import {currentCity} from "../../../../configuration/cities/_city";
import {routes} from "../../../../configuration/routes";

const useSendDemand = () => {
    const dispatch = useDispatch();

    return (email: string) => {
        dispatch(UiPasswordChangeDemandState.actions.sendDemand.request({
            email,
            city: currentCity().id,
            resetUrl: window.location.origin + routes().ChangePassword.path
        }));
    };
};

export default {
    useSendDemand
}
