import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {LogsStateData} from "../types";

type ActionPayload = {
    type: string,
    action: any,
};

type ActionCreatorPayload = {
   action: any,
};

export default newAction<ActionPayload, LogsStateData, ActionCreatorPayload>({
    type: "/logs/save-action",
    create: (type, payload) => ({
        type,
        ...payload,
    }),
    reducer: ({ action }, state) => ({
        ...state,
        actions: [
            // Prepend de la nouvelle action dans la liste des actions saved
            {
                ...action,
                date: new Date() // on timestamp l'action
            },
            ...state.actions,
        ]
    })
});
