import {Alias} from "./index";

export const formatAliases = (aliases: Alias[]) => {
    const result: any = {};

    aliases.forEach(alias => {
        result[alias.id] = {
            loading: false,
            alias,
        };
    });

    return result;
};
