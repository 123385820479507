import {ApiCall} from "../../../index";

import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {Payment} from "./modeling/payment";

const path = "/prepaid/payment";

type Payload = {
    date: string,
    channel: string,
    amount: number,
    invoiceId: string | number,
    accountId: string | number,
    city: string,
};

export const orderPrepaidAccountPayment = (payload: Payload): ApiCall<Payment> => ({
    url: coreConfiguration.api.xts.orderUrl + path,
    body: payload,
    method: 'POST',
    formater: r => r,
    tokenRepository: 'xts',
});
