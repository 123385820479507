import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiProvidedProof, UiRightCreationWorkflowStateData, UiVehicle} from "../index";
import {RequestActionCreatorPayload} from "./realCreateRight";

export type CreateRightActionPayload = {
    type: string,
    right: RequestActionCreatorPayload,
    proofs: UiProvidedProof[],
    personId: string,
    vehicleId: string,
};

type ActionCreatorPayload = {
    right: RequestActionCreatorPayload,
    proofs: UiProvidedProof[],
    personId: string,
    vehicleId: string,
};

export default newAction<CreateRightActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/create-right",
    create: (type, payload) => ({ type, ...payload }),
    reducer: (action, state) => {
        return {
            ...state,
            rightCreationStatus: 'sending', // même sur cette action fake on dit quand même que l'on send le droit
        }
    }
});
