import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {RightCreationFormState} from "../index";
import {RightCatalogValidity} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right-catalog-validity";
import {customerContractsComputeValidityDates} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/getComputeValidityDates";

export type RequestActionCreatorPayload = {
    rightCatalogId: number | string,
    startDate?: string,
};

export const actions = newRequestActions<RequestActionCreatorPayload, RightCreationFormState, RightCatalogValidity>(
    "/compute_dates_validity",
    customerContractsComputeValidityDates,
    (action, state) => ({
        ...state,
        options: {
            ...state.options,
            computingDatesValidity: true,
        }
    }),
    (action, state) => ({
        ...state,
        value: {
            ...state.value,
            validityPeriodResult: action.apiCallResult.data,
        },
        options: {
            ...state.options,
            computingDatesValidity: false,
            datesValidityComputed: true,
        }
    }),
    (action, state) => state,
);

export const ComputeDatesValidityRequestAction = actions.request;
export const ComputeDatesValiditySuccessAction = actions.success;
export const ComputeDatesValidityFailureAction = actions.failure;
