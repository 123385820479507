import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData} from "../index";
import {Right} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";
import * as lodash from "lodash";
import {customerContractsPutRightSubmit} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/putRightSubmit";


type RequestActionCreatorPayload = {
    rightId: string | number,
    date: string,
    channel: "FRONTOFFICE"
}

export default newRequestActions<RequestActionCreatorPayload, UiRightCreationWorkflowStateData, Right>(
    "/ui/right-creation/submit-right",
    (payload: RequestActionCreatorPayload) => {
        const newPayload = lodash.omit(payload, 'rightId');

        return customerContractsPutRightSubmit(payload.rightId, newPayload);
    },
    (action, state) => ({
        ...state,
        rightCreationStatus: "submitting",
    }),
    (action, state) => ({
        ...state,
        rightCreationStatus: "submitted",
    }),
    (action, state) => ({
        ...state,
        rightCreationStatus: "submission-failed",
    })
);
