import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {
    UiRightCreationWorkflowStateData
} from "../index";
import {customerContractsRightCatalogsSearch} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postRightCatalogSearch";
import {RightCatalog} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right-catalog";

type RequestActionCreatorPayload = {
    availablesForVehicleId?: string | number,
    availablesForPersonId?: string | number,
    parentRightId?: string,
    wishedStartDate?: string,
    city?: string,
    deliveryChannel?: string,
}

const actions = newRequestActions<RequestActionCreatorPayload, UiRightCreationWorkflowStateData, any>(
    "/ui/right-creation/fetch_right_catalogs",
    customerContractsRightCatalogsSearch,
    (action, state) => ({
        ...state,
        rightCatalogsStatus: "loading",
    }),
    (action, state) => {
        const rightCatalogs = action.apiCallResult.data.items
            .map(
                ({ id, label, profile, areas, prices, shallAllowParking, maxVehicles, shallUseAccountVehicles, specificFields }: RightCatalog) => ({
                    id,
                    label,
                    profile,
                    areas: areas || [],
                    prices: prices || [],
                    shallAllowParking,
                    maxVehicles,
                    shallUseAccountVehicles,
                    specificFields: specificFields || [],
                })
            );

        return {
            ...state,
            rightCatalogs,
            rightCatalogsStatus: "fetched"
        }
    },
    (action, state) => ({
        ...state,
        rightCatalogsStatus: "failed",
    }),
);

export default actions;
