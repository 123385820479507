import {Dispatch} from "redux";
import {CoreNavigationDemand} from "../core-react/src/modules/routing/redux/navigation/actions";
import {routes} from "../configuration/routes";

export type CreateRightArgs = {
    vehicleId?: number | string;
    wishedStartDate?: string;
    parentRightId?: number | string;
    rightReference?: string;
    renewal?: boolean;
};

export type PermitListArgs = {
    status?: string,
    selectedPermit?: number | string,
};

export type Navigate = {
    PublicHome: () => void,
    Register: () => void,
    ChooseCustomer: () => void,
    CreateCustomer: () => void,
    EditCurrentCustomer: () => void,
    Account: () => void,
    PrivateHome: () => void,
    CreateVehicle: () => void,
    EditVehicle: ({ vehicleId }: { vehicleId: number | string }) => void,
    CreateRight: (args?: CreateRightArgs) => void,
    AskMailConfirmationAgain: (email?: string) => void,
    AskPasswordChange: () => void,
    PermitsList: (args?: PermitListArgs) => void,
    InvoicesList: () => void,
    VehiclesList: () => void,
    Invoice: (id?: string|number) => void,
    TermsOfService: () => void,
    TermsOfSale: () => void,
    LegalNotices: () => void,
    FrequentlyAskedQuestions: () => void,
    PaymentAliasList: () => void,
    ProofsList: () => void
};

let _navigator: Navigate|undefined;

export const navigator = (): Navigate => _navigator as Navigate;

export const registerNavigator = (dispatch: Dispatch) => {
    _navigator = {
        PublicHome: () => dispatch(CoreNavigationDemand({
            route: routes().PublicHome,
        })),
        Register: () => dispatch(CoreNavigationDemand({
            route: routes().Register
        })),
        CreateCustomer: () => dispatch(CoreNavigationDemand({
            route: routes().CustomerCreate,
        })),
        ChooseCustomer: () => dispatch(CoreNavigationDemand({
            route: routes().ChooseCustomer,
        })),
        EditCurrentCustomer: () => dispatch(CoreNavigationDemand({
            route: routes().CustomerEdit,
        })),
        Account: () => dispatch(CoreNavigationDemand({
            route: routes().Account,
        })),
        PrivateHome: () => dispatch(CoreNavigationDemand({
            route: routes().PrivateHome,
        })),
        CreateVehicle: () => dispatch(CoreNavigationDemand({
            route: routes().CreateVehicle
        })),
        EditVehicle: ({ vehicleId }: { vehicleId: number | string }) => dispatch(CoreNavigationDemand({
            route: routes().EditVehicle,
            args: {
                vehicleId,
            }
        })),
        CreateRight: (args?: CreateRightArgs) => dispatch(CoreNavigationDemand({
            route: routes().CreateRight,
            args,
        })),
        AskMailConfirmationAgain: (email?: string) => dispatch(CoreNavigationDemand({
            route: routes().AskAccountValidation,
            args: {
                from: 'reload',
                ...(email && {email}),
            }
        })),
        AskPasswordChange: () => dispatch(CoreNavigationDemand({
            route: routes().AskChangePassword
        })),
        InvoicesList: () => dispatch(CoreNavigationDemand({
            route: routes().InvoicesList
        })),
        Invoice: (id?: string|number) => dispatch(CoreNavigationDemand({
            route: routes().Invoice,
            args: {id}
        })),
        PermitsList: (args = {}) => {
            return dispatch(CoreNavigationDemand({
                route: routes().PermitsList,
                args,
            }));
        },
        TermsOfService: () => dispatch(CoreNavigationDemand({
            route: routes().TermsOfService
        })),
        TermsOfSale: () => dispatch(CoreNavigationDemand({
            route: routes().TermsOfSale
        })),
        LegalNotices: () => dispatch(CoreNavigationDemand({
            route: routes().LegalNotices
        })),
        FrequentlyAskedQuestions: () => dispatch(CoreNavigationDemand({
            route: routes().FrequentlyAskedQuestions
        })),
        PaymentAliasList: () => dispatch(CoreNavigationDemand({
            route: routes().PaymentAliasList
        })),
        VehiclesList: () => dispatch(CoreNavigationDemand({
            route: routes().VehiclesList
        })),
        ProofsList: () => dispatch(CoreNavigationDemand({
            route: routes().ProofsList
        })),
    }
};
