import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData, UiVehicle} from "../index";
import {Area} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/area";

type ActionPayload = {
    type: string,
    isValid: boolean,
    values: any,
};

type ActionCreatorPayload = {
    isValid: boolean,
    values: any,
};

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/specific-fields-values",
    create: (type, { isValid, values }) => ({ type, isValid, values }),
    reducer: (action, state) => {
        return {
            ...state,
            specificFieldsFormIsValid: action.isValid,
            specificFieldsValues: action.values,
        }
    }
});
