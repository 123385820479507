import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

/**
 * Created by priano on 31/08/2020 at 16:07.
 */
const path = '/rightCatalog/search';

type Payload = {
    limit?: number | string,
    offset?: number | string,
    availableByDate?: string,
    wishedStartDate?: string,
    availablesForVehicleId?: number | string,
    availablesForPersonId?: number | string,
    profile?: string,
    editionMode?: boolean,
    rightId?: number | string,
    parentRightId?: number | string,
    city?: string,
    id?: number | string,
    updatedSince?: string
};

export const customerContractsRightCatalogsSearch = (payload: Payload): ApiCall<any> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: r => r,
    tokenRepository: 'xts'
})
