import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {
    apiOrderCityConfigurationToOrderCityConfiguration,
    OrderCityConfiguration
} from "./modeling/city"

const path = '/city/:city';

export default (city: string): ApiCall<OrderCityConfiguration> => ({
    url: coreConfiguration.api.xts.orderUrl + path.replace(':city', city),
    method: 'GET',
    formater: apiOrderCityConfigurationToOrderCityConfiguration,
    tokenRepository: "xts-or-xts-account"
});
