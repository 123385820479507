import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbVehiclesStateData} from "../index";
import {Vehicle} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/vehicle";

type ActionPayload = {
    type: string,
    vehicle: Vehicle,
};

type ActionCreatorPayload = {
    vehicle: Vehicle,
};

export default newAction<ActionPayload, DbVehiclesStateData, ActionCreatorPayload>({
    type: "/db/vehicles/write-one",
    create: (type, payload) => ({
        type,
        ...payload,
    }),
    reducer: ({type, vehicle}, state) => ({
        ...state,
        byId: {
            ...state.byId,
            [vehicle.id]: {
                loading: false,
                vehicle
            }
        }
    })
});
