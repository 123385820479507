import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiProfileFormStateData} from "../index";
import {Address} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/address";
import {customerContractsPutAddress} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/putAddress";

type RequestActionCreatorPayload = {
    id: string,
    city: string,
    area: string,
    street: string,
    number: string,
    postalCode: string,
    state: string,
    locality: string,
    addressDetails: string,
    country: string,
    usage: string,
    personId: number,
    lat: number,
    lng: number,
}

const actions = newRequestActions<RequestActionCreatorPayload, UiProfileFormStateData, Address>(
    "/ui/profile_form_update_address",
    (payload: RequestActionCreatorPayload) => customerContractsPutAddress(payload.id, payload),
    (action, state) => ({ ...state }),
    (action, state) => ({ ...state }),
    (action, state) => ({ ...state }),
);

export default actions;
