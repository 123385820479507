/**
 * Created by priano on 05/10/2020 at 16:55.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {Address} from "./modeling/address";

const path = "/account/:id/email";

type Payload = {
    email: string,
};

export const customerContractsPatchEmail = (id: string | number, payload: Payload): ApiCall<Address> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'PATCH',
    urlParameters: {id},
    body: payload, // ou en url Param
    formater: (s: any) => s,
    tokenRepository: "xts"
});
