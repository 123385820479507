import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/payment/bpay";

type Payload = {
    reference: string,
    bankAccountId: string,
    orderId: string,
    customerName: string,
    customerCountryCode: string,
    amountInCents: number,
    city: string
};

export const orderBPayPayment = (payload: Payload): ApiCall<any> => ({
    url: coreConfiguration.api.xts.orderUrl + path,
    body: payload,
    method: 'POST',
    formater: r => r,
    tokenRepository: 'xts',
});
