/**
 * Created by priano on 05/10/2020 at 16:55.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {AddressSuggestion} from "./modeling/address-suggestion";

const path = "/address/autocomplete/hints";

type Payload = {
    city: string,
    address: string,
};

export const customerContractsGetAddressAutocompleteHints = (payload: Payload): ApiCall<Array<AddressSuggestion>> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: (s: any) => s,
    tokenRepository: 'xts-or-xts-account'
});
