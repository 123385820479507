import {UiRightCreationWorkflowState, UiRightCreationWorkflowStateData, UiVehicleStatus} from "./index";
import {createSelector} from "reselect";
import {DbProfilesState} from "../../db/profiles";
import {profileToId, profileToString} from "./utils";
import * as lodash from 'lodash';
import {proofId} from "../../../../components/content/domain/right-creation-workflow/utils";

const select = (state: any): UiRightCreationWorkflowStateData => state[UiRightCreationWorkflowState.stateKey];
const selectActiveIndex = (state: any) => select(state).activeStepIndex;
const selectCompletedIndexes = createSelector(
    select,
    (state: UiRightCreationWorkflowStateData): number[] => state.completedIndexes,
);
const selectTotalStepCount = createSelector(
    select,
    (state: UiRightCreationWorkflowStateData): number => state.totalStepCount,
);
const selectProfiles = createSelector(
    DbProfilesState.selectors.allAsArray,
    (profiles) => {
        return profiles.sort(
            function(a, b) {
                const aLabel = profileToString(a);
                const bLabel = profileToString(b);

                if (aLabel < bLabel) { return -1; }
                if (aLabel > bLabel) { return 1; }

                return 0;
            }
        )
    }
);

const getChoosedRightCatalog = createSelector(
    (state: any) => select(state).rightCatalogs,
    (state: any) => select(state).choosedRightCatalogId,
    (rightCatalogs, id) => rightCatalogs.find(r => r.id === id), // claqued
);

const getSelectedRightCalalogSpecificFields = createSelector(
    getChoosedRightCatalog,
    (rightCatalog) => rightCatalog?.specificFields || [],
);

export default {
    state: select,
    activeStepIndex: selectActiveIndex,
    totalStepCount: selectTotalStepCount,
    isIndexCompleted(index: number) {
        return createSelector(
            selectCompletedIndexes,
            (indexes) => indexes.indexOf(index) !== -1,
        )
    },
    isActiveIndexCompleted: createSelector(
        selectActiveIndex,
        selectCompletedIndexes,
        (activeIndex, completedIndexes) => {
            return completedIndexes.indexOf(activeIndex) !== -1;
        }
    ),
    rightCatalogsStatus: (state: any) => select(state).rightCatalogsStatus,
    allRightCatalogs: (state: any) => select(state).rightCatalogs,
    rightCatalogsByProfiles(profileIds: string[]) {
        return createSelector(
            (state: any) => select(state).rightCatalogs,
            rightCatalogs => {
                return rightCatalogs.filter(
                    r => profileIds.indexOf(r.profile) !== -1
                )
            }
        );
    },
    getOneRightCatalogs: (id: string) => {
        return createSelector(
            (state: any) => select(state).rightCatalogs,
            rightCatalogs => {
                return rightCatalogs.find(
                    r => r.id === id // claqued
                )
            }
        );
    },
    profiles: selectProfiles,
    profileIds: createSelector(
        selectProfiles,
        profiles => profiles.map(p => profileToId(p))
    ),
    profileLabels: createSelector(
        selectProfiles,
        profiles => profiles.map(p => profileToString(p))
    ),
    choosedRightCatalogId: (state: any) => select(state).choosedRightCatalogId,
    getChoosedRightCatalog,
    getSelectedRightCalalogSpecificFields,
    specificFieldsRequired: createSelector(
        getSelectedRightCalalogSpecificFields,
        (specificFields) => {
            // specificFieldsRequired required s'il y en a de configuré + si au moins un des field est required
            return specificFields.length > 0 && specificFields.some(field => field.required);
        }
    ),
    specificFieldsFormIsValid: (state: any) => select(state).specificFieldsFormIsValid,
    specificFieldsValues: (state: any) => select(state).specificFieldsValues || {},
    choosedProfileId: (state: any) => select(state).choosedProfileId,
    vehiclesAsArray: createSelector(
        (state: any) => select(state).vehicles,
        (vehicles) => {
            return lodash.orderBy(Object.values(vehicles), ['driver'], ['asc'])
        }
    ),
    vehiclesFilteredByStatus: (status: UiVehicleStatus) => {
        return createSelector(
            (state: any) => select(state).vehicles,
            (vehicles) => {
                return Object.values(vehicles).filter(v => v.status === status);
            }
        )
    },
    selectedVehicles: createSelector(
        (state: any) => select(state).vehicles,
        (vehicles) => {
            return Object.values(vehicles).filter(v => v.checked);
        }
    ),
    selectedVehicleIds: createSelector(
        (state: any) => select(state).vehicles,
        (vehicles) => {
            return Object.values(vehicles).filter(v => v.checked).map(v => v.id);
        }
    ),
    selectedVehiclePlates: createSelector(
        (state: any) => select(state).vehicles,
        (vehicles) => {
            return Object.values(vehicles).filter(v => v.checked).map(v => v.plate);
        }
    ),
    getOneVehicle(id: string) {
        return createSelector(
            (state: any) => select(state).vehicles,
            vehicles => vehicles[id],
        )
    },
    validityPeriodStatus: (state: any) => select(state).validityPeriodStatus,
    validityPeriod: (state: any) => select(state).validityPeriod,
    choosedArea: (state: any) => select(state).choosedArea,
    requiredProofs: (state: any) => select(state).requiredProofs,
    requiredProofsAsArray: createSelector(
        (state: any) => select(state).requiredProofs,
        proofs => Object.values(proofs),
    ),
    requiredProofsStatus: (state: any) => select(state).requiredProofsStatus,
    getOneRequiredProof(code: string, label: string) {
        return createSelector(
            (state: any) => select(state).requiredProofs,
            proofs => {
                const key = proofId({ code, label });

                return proofs[key];
            },
        );
    },
    providedProofsAsArray: createSelector(
        (state: any) => select(state).providedProofs,
        proofs => Object.values(proofs),
    ),
    getOneProvidedProofs(code: string, label: string) {
        return createSelector(
            (state: any) => select(state).providedProofs,
            proofs => {
                const key = proofId({ code, label });

                return proofs[key];
            },
        );
    },
    rightCreationStatus: (state: any) => select(state).rightCreationStatus,
};
