import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/account";

type Payload = {
    username: string,
    password: string,
    email: string,
    city: string
};

export const customerContractsPostAccount = (payload: Payload): ApiCall<any> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: (a: any) => a
})
