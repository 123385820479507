import {Formater} from "../../../common/modeling";

type ApiCustomerContractsResult = {
    country: string,
    smtpHost: string,
    smtpPort: string,
    smtpEncryption: string,
    smtpUsername: string,
    smtpPassword: string,
    smtpSenderEmail: string,
    smtpSenderName: string,
    backofficeNotificationEmail: string,
    proofAcceptedMimeTypes: string,
    proofMaximumSizeInMega: number,
    shallValidateEmail: boolean,
    shallUploadAttachments: boolean,
    shallFilterRightCatalogFromStreetsmartAreas: boolean,
    shallUseFrenchAddressRegistry: boolean,
    shallUseMapsAddressRegistry: boolean,
    shallUsePlaceAddressRegistry: boolean,
    shallUseApiBrusselAddressRegistry: boolean,
    shallValidatePhones: boolean,
    shallRequireLocalizedAddress: boolean,
    shallCancelPermitFromFrontoffice: boolean,
    daysBeforeCancellingUnpaidPermit: number,
    customerTitles: ApiCustomerTitle[],
    customerTypes: ApiCustomerType[],
    cirbFidusActif: boolean,
    cirbFidusCertificate: string,
    cirbFidusCertificateKey: string,
    cirbFidusTestWsdl: string,
    cirbFidusVehicleWsdl: string,
    cirbFidusReferenceWsdl: string,
    cirbFidusPersonWsdl: string,
    cirbFidusHouseholdWsdl: string,
    cirbFidusEntrepriseWsdl: string,
    cirbFidusCompanyNumber: string,
    cirbFidusCompanyId: string,
    cirbFidusContext: string,
};

type ApiCustomerType =     {
    identifier: string,
    type_i18n: {
        [key: string]: string,
    },
    visibleAtBackoffice: true,
    visibleAtFrontoffice: true
}

export type CustomerType = ApiCustomerType;

export type CustomerTitleIdentifier =
    "Mister"
    | "Mistress"
    | "Miss"
    | "Doctor"
    | "Professor"
    | "AdjunctProfessor"
    | "AssociateProfessor"
    | "EmeritusProfessor";

type ApiCustomerTitle = {
    identifier: CustomerTitleIdentifier,
    name: {
        [key: string]: string,
    },
};

export type CustomerTitle = ApiCustomerTitle;

export type CustomerContratsCity = ApiCustomerContractsResult & { customerTypes: CustomerType[] };

const apiCityToCity: Formater<ApiCustomerContractsResult, CustomerContratsCity> = city => ({
    country: city.country,
    smtpHost: city.smtpHost,
    smtpPort: city.smtpPort,
    smtpEncryption: city.smtpEncryption,
    smtpUsername: city.smtpUsername,
    smtpPassword: city.smtpPassword,
    smtpSenderEmail: city.smtpSenderEmail,
    smtpSenderName: city.smtpSenderName,
    backofficeNotificationEmail: city.backofficeNotificationEmail,
    proofAcceptedMimeTypes: city.proofAcceptedMimeTypes,
    proofMaximumSizeInMega: city.proofMaximumSizeInMega,
    shallValidateEmail: city.shallValidateEmail,
    shallUploadAttachments: city.shallUploadAttachments,
    shallFilterRightCatalogFromStreetsmartAreas: city.shallFilterRightCatalogFromStreetsmartAreas,
    shallUseFrenchAddressRegistry: city.shallUseFrenchAddressRegistry,
    shallUseMapsAddressRegistry: city.shallUseMapsAddressRegistry,
    shallUsePlaceAddressRegistry: city.shallUsePlaceAddressRegistry,
    shallUseApiBrusselAddressRegistry: city.shallUseApiBrusselAddressRegistry,
    shallValidatePhones: city.shallValidatePhones,
    shallRequireLocalizedAddress: city.shallRequireLocalizedAddress,
    shallCancelPermitFromFrontoffice: city.shallCancelPermitFromFrontoffice,
    daysBeforeCancellingUnpaidPermit: city.daysBeforeCancellingUnpaidPermit,
    customerTitles: city.customerTitles,
    customerTypes: city.customerTypes,
    cirbFidusActif: city.cirbFidusActif,
    cirbFidusCertificate: city.cirbFidusCertificate,
    cirbFidusCertificateKey: city.cirbFidusCertificateKey,
    cirbFidusTestWsdl: city.cirbFidusTestWsdl,
    cirbFidusVehicleWsdl: city.cirbFidusVehicleWsdl,
    cirbFidusReferenceWsdl: city.cirbFidusReferenceWsdl,
    cirbFidusPersonWsdl: city.cirbFidusPersonWsdl,
    cirbFidusHouseholdWsdl: city.cirbFidusHouseholdWsdl,
    cirbFidusEntrepriseWsdl: city.cirbFidusEntrepriseWsdl,
    cirbFidusCompanyNumber: city.cirbFidusCompanyNumber,
    cirbFidusCompanyId: city.cirbFidusCompanyId,
    cirbFidusContext: city.cirbFidusContext,
});

export default apiCityToCity;
