/**
 * Created by priano on 14/08/2020 at 09:17.
 */
import {createFormSlice, emptyFormStatus, FormState} from "../../../core-react/src/utils/form/redux";
import {currentCity} from "../../../configuration/cities/_city";
import {
    customerContractsPostAccountLoginWithAccount
} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/postAccountLoginAccount";
import {Jwt} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/jwt";
import {SessionConnectAccountAction} from "../session/actions/connectAccount";
import {ApiCallResult} from "../../../core-react/src/modules/api";
import {SessionAskAccountValidationAction} from "../session/actions/askAccountValidation";
import {SetFailedLoginAttempt} from "../../modules_clean/ui/loginAttemptFailure/actions/setFailedLoginAttempt";
import {getTranslation} from "../../../translations/useTranslation";
import {validateEmail} from "../../../core-react/src/utils/validators";

export type LoginFormData = {
    email: string,
    password: string,
}

const initialLoginFormData: LoginFormData = {
    email: "",
    password: "",
};

export type LoginFormState = FormState<LoginFormData> & {
    globalError?: string
};

export const loginFormSlice = createFormSlice<LoginFormData, Jwt, LoginFormState>(
    "/login_form",
    "login-form",
    {
        fields: {
            email: {
                required: true,
                validator: dto => {
                    const translations = getTranslation();

                        return dto.email && ! validateEmail(dto.email)
                            ? [translations.notValidEmail]
                            : [];
                }
            },
            password: {
                required: true,
                validator: dto => {
                    const minLength = 6;
                    const translations = getTranslation();

                    return dto.password && dto.password.length <= minLength
                        ? [translations.passwordIsTooShort.replace('#minLength', minLength.toString())]
                        : [];
                }
            },
        }
    }, initialLoginFormData, {
        // Dispatch via component à chaque render
        onArrived(dispatch, state, action) {

        },
        // Dispatch via component
        onReset(dispatch, state, action) {
            dispatch(
                loginFormSlice.actions.initialize({}),
            );
        },
        // Dispatch via listeners
        onInitialize(dispatch, state, action) {
            dispatch(
                loginFormSlice.actions.initialized({
                    dto: initialLoginFormData,
                })
            );
        },
        onSubmit(dispatch, state, action) {
            if (!state[loginFormSlice.stateKey].submitting) {
                return;
            }

            const data: LoginFormData = state[loginFormSlice.stateKey].dto;

            const {submitRequest} = loginFormSlice.actions;

            dispatch(
                submitRequest({
                    apiCall: customerContractsPostAccountLoginWithAccount({
                        city: currentCity().id,
                        username: data.email,
                        password: data.password
                    }),
                })
            )
        },
        onSubmitSuccess(dispatch, state, action) {
            const data: LoginFormData = state[loginFormSlice.stateKey].dto;

            /*
            dispatch(
                CoreNotify.success("Connection successfull")
            );
             */
            dispatch(
                SessionConnectAccountAction({
                    token: action.apiCallResult.data.token,
                    email: data.email
                })
            );
            dispatch(loginFormSlice.actions.reset());
        },
        onSubmitFailure(dispatch, state, action: any) {
            const data: LoginFormData = state[loginFormSlice.stateKey].dto;
            const loginError = getErrorType(action.apiCallResult);
            const { error } = action.apiCallResult.data

            // Enregistrement de la tentative de connexion échouée
            dispatch(SetFailedLoginAttempt({
                reason: error,
                email: data.email,
            }));

            if (loginError === 'mail-not-confirmed') {
                return dispatch(SessionAskAccountValidationAction({
                    email: data.email,
                    source: 'login',
                }));
            }

            // const message = {
            //     'wrong-credentials': 'Invalid credentials',
            //     'unknown': 'An error occured'
            // }[error];
            //
            // return dispatch(CoreNotify.failure(message));
        }
    },
    {
        submitFailure: (action, state) => {
            const error = getErrorType(action.apiCallResult);
            if (error === 'mail-not-confirmed') {
                return state;
            }

            return {
                ...state,
                status: emptyFormStatus(state.dto)
            };
        }
    }
);

const getErrorType = (result: ApiCallResult<Jwt>): 'wrong-credentials' | 'mail-not-confirmed' | 'unknown' => {
    if ([403, 401].includes(result.statusCode)) {
        return 'wrong-credentials';
    }

    if (result.statusCode === 412) {
        return 'mail-not-confirmed';
    }

    return 'unknown';
};
