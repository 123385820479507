import {put, select, takeEvery} from "redux-saga/effects";
import {FranceConnectState} from "./index";
import {CoreNotify} from "../../../core-react/src/modules/notifications/redux/state";
import {sessionSelectors} from "../session";
import {navigator} from "../../../services/navigator";
import {SessionConnectAccountAction} from "../session/actions/connectAccount";

export default function* sagas() {
    yield takeEvery(
        FranceConnectState.actions.franceConnectAuthenticate.success.type,
        function* (action: any) {
            const { data } = action.apiCallResult;

            // Si er
            if (data.message === 'error') {
                // yield put(CoreNotify.failure("An error occured"));

                const isConnected = yield select(sessionSelectors.accountIsConnected);

                isConnected
                    ? navigator().PrivateHome()
                    : navigator().PublicHome()
                ;

                return;
            }

            // si pas d'erreur on lance le process de connection avec le token reçu
            yield put(SessionConnectAccountAction({ token: data.token }))
        }
    );
}
