/**
 * Created by priano on 16/09/2020 at 11:28.
 */
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbProofsStateData} from "../";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";
import {Right} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";
import {customerContractsPostProofSearch} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postProofSearch";
import {currentCity} from "../../../../../configuration/cities/_city";
import {CustomerProof} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/customer-proof";

type RequestActionCreatorPayload = {
    personId: number
};

const actions = newRequestActions<RequestActionCreatorPayload, DbProofsStateData, Pagination<CustomerProof>>(
    "/db/proofs/fetch-all",
    payload => customerContractsPostProofSearch({
        ...payload,
        city: currentCity().id
    }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        loading: false,
        byId: formatProofs(action.apiCallResult.data.items),
    }),
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;

const formatProofs = (proofs: CustomerProof[]) => {
    const result: any = {};

    proofs.forEach(proof => {
        result[proof.id as string] = {
            loading: false,
            proof,
        };
    });

    return result;
};
