import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {apiVehicleToVehicle, Vehicle} from "./modeling/vehicle";

const path = "/vehicle/:id";

type Payload = {
    plate: string,
    country: string,
    brand?: string,
    model?: string,
    fuelType?: string,
    vehicleType?: string,
    color?: string,
    literVolume?: string,
    gkmco2?: string | null,
    vin?: string,
    kgWeightTotal?: string,
    personId?: string | number,
    driverId?: string | undefined,
};

export const customerContractsPutVehicle = (id: number | string, payload: Payload): ApiCall<any> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path.replace(':id', id.toString()),
    method: "PUT",
    urlParameters: {id},
    body: payload, // ou en url Param
    formater: apiVehicleToVehicle,
    tokenRepository: 'xts',
});
