import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbVehiclesStateData} from "../index";

type ActionPayload = {
    vehicleId: number,
};

type ActionCreatorPayload = {
    vehicleId: number,
};

export default newAction<ActionPayload, DbVehiclesStateData, ActionCreatorPayload>({
    type: "/db/vehicles/local-delete-one",
    create: (type, { vehicleId }) => ({ type, vehicleId }),

    reducer: (action, state: any) => {

        let byId = state.byId;
        const removedVehicleId = action.vehicleId

        delete byId[removedVehicleId];

        return {
            ...state,
            byId: {
                ...byId,
            }
        }
    }
});

