import {put, select, takeEvery} from "redux-saga/effects";
import saveAction from "./actions/saveAction";
import logConsoleError from "./actions/logConsoleError";
import {sessionSelectors} from "../session";
import {LogsState} from "./index";
import {makeConsoleErrorMessage, makeHttpRequestFailureMessage, makeLogPayload} from "./utils";
import postLog, { actionTypes as postLogActions } from "./actions/postLog";
import {configuration} from "../../../configuration/configuration";

const excludeActions = [
    saveAction.type,
    logConsoleError.type,
    ...postLogActions,
];

export default function* sagas() {
    // Ne gérer tout ce qui est log que si la config pour le loggin est set i.e l'url pour send les logs
    if (! configuration()?.logging?.url) {
        return;
    }

    // Take toutes les actions possibles sauf celle qui sont exlue ici
    yield takeEvery((action: any) => excludeActions.indexOf(action.type) < 0, function* (action) {
        // save toutes les actions concernées
        yield put(saveAction.create({ action }));
    })

    // Intercept dispatch depuis console error
    yield takeEvery(logConsoleErrorPattern,  function* (action: any) {
        // When not connected ne pas envoyer les logs
        const isConnected = yield select(sessionSelectors.customerIsConnected);

        if (! isConnected) {
            return;
        }

        const state = yield select();
        const clientEmail = yield select(sessionSelectors.accountEmail);
        const latestActions = yield select(LogsState.selectors.latestActions());
        const message = makeConsoleErrorMessage(action.args[0]);

        // la payload avec toutes les infos
        const payload = makeLogPayload({
            message,
            error: action.args,
            clientEmail,
            latestActions,
            state,
        });

        // On post le tout
        yield put(postLog.request.create(payload));
    });

    // Intercept request failure
    yield takeEvery(logRequestFailurePattern, function* (action: any) {
        // When not connected ne pas envoyer les logs
        const isConnected = yield select(sessionSelectors.customerIsConnected);

        if (! isConnected) {
            return;
        }

        const state = yield select();
        const clientEmail = yield select(sessionSelectors.accountEmail);
        const latestActions = yield select(LogsState.selectors.latestActions());

        const { apiCallResult: { request: { method, url }, statusCode } } = action;

        const message = makeHttpRequestFailureMessage({
            method,
            statusCode,
            path: url,
        });

        // la payload avec toutes les infos
        const payload = makeLogPayload({
            message,
            error: [],
            clientEmail,
            latestActions: [
                { ...action, date: new Date() }, // on ajoute l'action dans la liste des actions récentes
                ...latestActions,
            ],
            state,
        });

        // On post le tout
        yield put(postLog.request.create(payload));
    });
}

/**
 * function pour filrer les console error à envoyer à l'api
 */
const logConsoleErrorPattern = (action: any) => {
    // On ne tient pas compte des erreurs console conernant les histoire d'unicité des key sur le component
    const uniqueKeyError = 'Each child in a list should have a unique "key" prop.';

    return logConsoleError.type === action.type && matches(uniqueKeyError, action.args).length === 0;
}

/**
 * function pour filrer les request failure à send à l'api
 * on exclude postLog.failure
 * @param action
 */
const logRequestFailurePattern = (action: any) => {
    return action.type.endsWith('/failure') && action.type !== postLog.failure.type;
}

const matches = (substring: string, arr: any[]) => {
    return (
        (arr || []).filter(element => {
            if (element.indexOf && element.indexOf(substring) !== -1) {
                return true;
            }
        })
    )
}
