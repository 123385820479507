import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {orderPaymentsSearch} from "../../../../../core-react/src/modules/api/utils/xts/order/postPaymentsSearch";
import {DbPaymentsStateData} from "../index";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";
import {currentCity} from "../../../../../configuration/cities/_city";
import {Payment} from "../../../../../core-react/src/modules/api/utils/xts/order/modeling/payment";

type RequestActionCreatorPayload = {
    invoiceId: string,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbPaymentsStateData, Pagination<Payment>>(
    "/db/payments/fetch-all",
    payload => orderPaymentsSearch({
        ...payload,
        city: currentCity().id
    }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        loading: false,
        byId: formatPayments(action.apiCallResult.data.items),
    }),
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;

const formatPayments = (payments: Payment[]) => {
    const result: any = {};

    payments.forEach(payment => {
        result[payment.id] = {
            loading: false,
            payment,
        };
    });

    return result;
};
