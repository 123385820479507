import {DbProfilesState, DbProfilesStateData} from "./index";
import {createSelector} from "reselect";
import { Profile } from "../../../../core-react/src/modules/api/utils/xts/city/modeling/profile";

const select = (state: any): DbProfilesStateData => state[DbProfilesState.stateKey];

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    loaded: (state: any) => select(state).loaded,
    all: (state: any) => select(state).byId,
    allAsArray: createSelector(
        (state: any) => select(state).byId,
        all => Object.values(all).map(v => v.profile) as Profile[]
    ),
    one: (id: string|number) => (state: any) => select(state).byId[id],
}
