/**
 * Created by priano on 31/08/2020 at 14:43.
 */
import {Formater} from "../../../common/modeling";

type ApiProfileResult = {
    id: string,
    label: string,
    label_i18n: {
        [key: string]: string,
        _id: string,
    },
    city: string,
    created: string,
    updated: string,
    deleted: string,
}

export type Profile = {
    id: string,
    label: string,
    label_i18n: {
        [key: string]: string,
        _id: string,
    },
    city: string,
}

export const apiProfileToProfile: Formater<ApiProfileResult, Profile> = p => p;
