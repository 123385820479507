import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {RightCreationFormState} from "../index";
//import {RequestActionCreatorPayload as Proof} from "./UploadProofAction";
import {RequestActionCreatorPayload as Right} from "./CreateRightAction";

export type AbstractCreateRightActionPayload = {
    proofs: { index: number, type: string, label: string, file: File }[],
    right: Right,
    personId: number,
    vehicleId: number,
};

type ActionCreatorPayload = AbstractCreateRightActionPayload;

export const AbstractCreateRight = newAction<AbstractCreateRightActionPayload, RightCreationFormState, ActionCreatorPayload>({
    type: "/abstract-create-right",
    create: (type, payload) => ({
        type,
        ...payload
    }),
    reducer: (action, state) => state
});