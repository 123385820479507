import {RightCreationFormState} from "../index";
import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {customerContractsRightCatalogsSearch} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/postRightCatalogSearch";
import * as lodash from 'lodash';
import {RightCatalog} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right-catalog";
import {Profile} from "../../../../core-react/src/modules/api/utils/xts/city/modeling/profile";

type RequestActionCreatorPayload = {
    availablesForVehicleId?: string | number,
    availablesForPersonId?: string | number,
    parentRightId?: string,
    wishedStartDate?: string,
    city?: string,
    deliveryChannel?: string,
}

const linkProfileWithRightCatalogsMapper = (rightCatalogs: RightCatalog[]) => {
    return (profile: Profile) => {
        return {
            ...profile,
            rightCatalogs: rightCatalogs
                .filter(r => r.profile === profile.id)
                .map(r => ({ ...r, profile } as any)) // override profile string en profile object (nécessaire pour récupérer le label_i18n)
        };
    };
}

const actions = newRequestActions<RequestActionCreatorPayload, RightCreationFormState, any>(
    "/fetch_right_catalogs",
    customerContractsRightCatalogsSearch,
    (action, state) => ({
        ...state,
        options: {
            ...state.options,
            rightCatalogsLoading: true,
        }
    }),
    (action, state) => ({
        ...state,
        options: {
            ...state.options,
            profiles: lodash.map(state.options.profiles, linkProfileWithRightCatalogsMapper(action.apiCallResult.data.items)),
            rightCatalogsLoading: false,
            rightCatalogsLoaded: true,
        }
    }),
    (action, state) => state,
);

export const FetchRightCatalogsRequestAction = actions.request;
export const FetchRightCatalogsSuccessAction = actions.success;
export const FetchRightCatalogsFailureAction = actions.failure;
