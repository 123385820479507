import {currentCity} from "../../../configuration/cities/_city";
import crypto from "crypto";

export function makeLogPayload({ message, error, clientEmail, latestActions, state }: {
    message: string,
    error: any[],
    clientEmail: string,
    latestActions: any[],
    state: any,
}) {
    const isDev = process.env.NODE_ENV === 'development'

    const application = isDev ? "dev-xts-parking-enduser-foui" : "xts-parking-enduser-foui";

    const host = window.location.origin;
    const path = window.location.pathname;

    const processId = crypto.randomBytes(8).toString('hex');

    return {
        city: currentCity().id,
        creationDate: new Date().toISOString(),
        application: application as 'xts-parking-enduser-foui',
        severity: "error" as "error",
        processId,
        message,
        data: {
            error,
            host: host,
            path: path,
            lastActions: latestActions,
            state,
        },
        tag: "uiErrors" as "uiErrors",
        stalk: {
            clientEmail,
        }
    }
}

export function makeConsoleErrorMessage(message: string) {
    const path = window.location.pathname;

    return [`Error on ${path}`, message]
        .map(m => m && m.trim())
        .filter(m => !!m).join(' - ')
    ;
}

export function makeHttpRequestFailureMessage(
    { method, statusCode, path }: { method: string, path: string, statusCode: string }
) {
    const pathname = window.location.pathname;

    return [
        `Http error on ${pathname}`,
        [method, path, statusCode]
            .map(m => m && m.toString().trim())
            .filter(m => !!m).join(' ')
    ].join(' - ');
}
