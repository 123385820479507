import {RightCreationFormState} from "../index";
import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {customerContractsPostProof} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/postProof";
import * as lodash from 'lodash';

export type RequestActionCreatorPayload = {
    type: string,
    label: string,
    attachement: {
        fileName: string,
        base64EncodedContent: string,
    },
    personId: number | string,
    vehicleId: number | string,
    city: string,
}

const actions = newRequestActions<RequestActionCreatorPayload, RightCreationFormState, any>(
    "/upload_proof",
    customerContractsPostProof,
    (action, state) => ({
        ...state,
    }),
    (action, state) => {
        const proofPayload = action.apiCallResult.request.data;
        const { proofsToUpload } = state.options;

        lodash.remove(proofsToUpload, p => p === proofPayload.type)

        return {
            ...state,
            options: {
                ...state.options,
                proofsToUpload: [ ...proofsToUpload ]
            }
        }
    },
    (action, state) => ({
        ...state,
    })
);

export const UploadProofActionRequestAction = actions.request;
export const UploadProofActionSuccessAction = actions.success;
export const UploadProofActionFailureAction = actions.failure;
