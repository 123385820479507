import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import {Area} from "../../../../core-react/src/modules/api/utils/xts/city/modeling/area";
import fetchAll from "./actions/fetchAll";
import selectors from "./selectors";

export type DbAreaStateData = {
    loading: boolean, // All rights being fetched
    byId: {
        [id: string]: {
            loading: boolean, // One right being fetched
            area: Area,
        }
    }
};

const initialState: DbAreaStateData = {
    loading: false,
    byId: {}
};

const actions = { fetchAll };

const stateKey = "db.area";

const reducer = reducerFromActionsObject(initialState, actions);

export const DbAreaState = {
    actions, stateKey, reducer, selectors
};
