/**
 * Created by priano on 06/10/2020 at 16:40.
 */
import {UiProfileFormState, UiProfileFormStateData} from "./index";

const select = (state: any): UiProfileFormStateData => state[UiProfileFormState.stateKey];

export default {
    state: select,
    addressSuggestions: (state: any) => select(state).addressSuggestions,
    addressDetail: (state: any) => select(state).addressDetail,
}
