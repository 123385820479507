/**
 * Created by priano on 31/08/2020 at 16:51.
 */
import {Formater} from "../../../common/modeling";

export type ApiRightCatalogValidityResult = {
    validityStart: string
    validityEnd: string
    readOnlyStart: boolean
    readOnlyEnd: boolean
}

export type RightCatalogValidity = {
    validityStart: Date,
    validityEnd: Date,
    readOnlyStart: boolean
    readOnlyEnd: boolean
}

export const apiRightCatalogValidityResultToRightCatalogValidity: Formater<ApiRightCatalogValidityResult, RightCatalogValidity> = v => ({
    ...v,
    validityStart: new Date(v.validityStart),
    validityEnd: new Date(v.validityEnd),
});
