import { newRequestActions } from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData} from "../index";
import {customerContractsPostRight} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postRight";
import {SpecificField} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right-catalog";

export type RequestActionCreatorPayload = {
    label: string,
    reference?: string,
    validityStart: string,
    validityEnd: string,
    comments?: string,
    deliveryChannel: string, // "FRONTOFFICE"
    rightCatalogId: number | string,
    validityAreas: string[],
    profile: string,
    vehicleIds: number[],
    personId: number | string,
    allowsParking: boolean,
    priceIncludingVat: number,
    priceVatRate: number,
    pricePosition: number,
    parentRightId?: number | string,
    specificFields: Array<SpecificField>
    city: string
}

export default newRequestActions<RequestActionCreatorPayload, UiRightCreationWorkflowStateData, any>(
    "/ui/right-creation/real-create-right",
    customerContractsPostRight,
    (action, state) => ({
        ...state,
        rightCreationStatus: "sending",
    }),
    (action, state) => ({
        ...state,
        rightCreationStatus: "sent",
    }),
    (action, state) => ({
        ...state,
        rightCreationStatus: "failed-to-send",
    })
);
