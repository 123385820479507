/**
 * Created by priano on 26/11/2020 at 18:17.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {Right} from "./modeling/right";

const path = "/right/:id/cancel";

type Payload = {
    cancellationDate: string;
    cancellationReason: string;
    cancellationChannel: string;
};

export const customerContractsPutCancelRight = (id: number|string, payload: Payload): ApiCall<Right> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    urlParameters: {id},
    method: 'PUT',
    body: payload,
    formater: r => r,
    tokenRepository: 'xts',
});
