/**
 * Created by priano on 06/10/2020 at 16:35.
 */
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import autocompleteHints from "./actions/autocomplete-hints";
import getAddressDetail from "./actions/get-address-detail"
import fillAddressSuggestions from './actions/fill-address-suggestions';
import fillBillingAddress from "./actions/fill-billing-address";
import createAddress from './actions/create-address'
import updateAddress from './actions/update-address'
import setAddressDetails from "./actions/set-address-details";
import selectors from "./selectors";
import hooks from "./hooks";
import {AddressSuggestion} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/address-suggestion";
import {Address} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/address";
import sagas from './sagas'

export type UiProfileFormStateData = {
    loading: boolean,
    addressSuggestions: Array<AddressSuggestion>,
    addressDetail?: Address,
    currentAddressFieldToAutoComplete: string;
};

const initialState: UiProfileFormStateData = {
    loading: false,
    addressSuggestions: [],
    addressDetail: undefined,
    currentAddressFieldToAutoComplete: '',
};

const actions = { setAddressDetails ,autocompleteHints, getAddressDetail, fillAddressSuggestions, fillBillingAddress, createAddress, updateAddress };

const stateKey = "ui.profile-form";

const reducer = reducerFromActionsObject(initialState, actions);

export const UiProfileFormState = {
    actions, stateKey, reducer, selectors, hooks, sagas
};
