/**
 * Created by priano on 17/09/2020 at 16:25.
 */
import {ApiCall} from "../../../index";
import {apiRightToRight, Right} from "./modeling/right";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";

const path = "/right/:id/submit";

type Payload = {
    date: string,
    channel: "FRONTOFFICE"
};

export const customerContractsPutRightSubmit = (id: number|string, payload: Payload): ApiCall<Right> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: "PUT",
    urlParameters: {id},
    body: payload, // ou en url Param
    formater: apiRightToRight,
    tokenRepository: 'xts',
});
