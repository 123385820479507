import {createFormSlice, emptyFormStatus, FormState} from "../../../core-react/src/utils/form/redux";
import {customerContractsPostAccount} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/postAccount";
import {currentCity} from "../../../configuration/cities/_city";
import {CoreNotify} from "../../../core-react/src/modules/notifications/redux/state";
import {SessionAskAccountValidationAction} from "../session/actions/askAccountValidation";
import {validateEmail} from "../../../core-react/src/utils/validators";
import {getTranslation} from "../../../translations/useTranslation";

export type RegisterFormData = {
    email: string,
    password: string,
    password2: string,
    acceptedTOS: boolean
};

const initialRegisterFormData: RegisterFormData = {
    email: "",
    password: "",
    password2: "",
    acceptedTOS: false
};

export type RegisterFormState = FormState<RegisterFormData> & {
    //successfulSubmit?: boolean
    error?: string
};

const unavailableEmails: string[] = [];

// @ts-ignore
export const registerFormSlice = createFormSlice<RegisterFormData, any, RegisterFormState>(
    "/register_form",
    "register-form",
    {
        fields: {
            email: {
                required: true,
                validator: dto => {
                    const translations = getTranslation();

                    if (dto.email && ! validateEmail(dto.email)) {
                        return [translations.notValidEmail];
                    }

                    return unavailableEmails.indexOf(dto.email) > -1
                        ? [translations.thisEmailIsAlreadyUsed]
                        : [];
                }
            },
            password: {
                required: true,
                validator: dto => {
                    const translations = getTranslation();
                    const minLength = 6;

                    return dto.password && dto.password.length <= minLength
                        ? [translations.passwordIsTooShort.replace('#minLength', minLength.toString())]
                        : [];
                }
            },
            password2: {
                required: true,
                validator: dto => {
                    const translations = getTranslation();

                    return dto.password !== dto.password2
                        ? [translations.mustMatchFirstPassword]
                        : [];
                }
            },
            acceptedTOS: {
                required: false,
                validator: dto => {
                    const translations = getTranslation();

                    return !dto.acceptedTOS
                        ? [translations.termsOfServiceMustBeAccepted]
                        : [];
                }
            }
        }
    }, initialRegisterFormData, {
        // Dispatch via component à chaque render
        onArrived: (dispatch, state, action) => {

        },
        // Dispatch via component
        onReset: (dispatch, state, action) => {
            dispatch(
                registerFormSlice.actions.initialize({})
            );
        },
        // Dispatch via listeners
        onInitialize: (dispatch, state, action) => {
            dispatch(
                registerFormSlice.actions.initialized({
                    dto: initialRegisterFormData
                })
            );
        },
        onSubmit: (dispatch, state, action) => {
            if (!state[registerFormSlice.stateKey].submitting) {
                return;
            }

            const data: RegisterFormData = state[registerFormSlice.stateKey].dto;

            dispatch(
                registerFormSlice.actions.submitRequest({
                    apiCall: customerContractsPostAccount({
                        city: currentCity().id,
                        email: data.email,
                        username: data.email,
                        password: data.password
                    })
                })
            )
        },
        onSubmitSuccess: (dispatch, state, action) => {
            /*
            dispatch(
                CoreNotify.success("Your account has been created")
            );
             */
            dispatch(
                SessionAskAccountValidationAction({
                    email: state[registerFormSlice.stateKey].dto.email,
                    source: 'register',
                })
            );
        },
        onSubmitFailure: (dispatch, state, action) => {
            const errorResult: any = action.apiCallResult.data;

            if (errorResult[0] && errorResult[0].property_path === 'username' && errorResult[0].message === "This value is already used.") {
                const email = action.apiCallResult.request.data.username;

                unavailableEmails.push(email); // Sera refusée à la prochaine validation

                return dispatch(registerFormSlice.actions.changed({
                    field: 'email',
                    value: email
                }));
            }

            // dispatch(
            //     CoreNotify.failure("An error occured")
            // );
        }
    }, {
        submitFailure: (action, state) => ({
            ...state,
            status: emptyFormStatus(state.dto)
        })
        /*
        submitFailure: (action, state) => {
            const errorResult: any = action.apiCallResult.db;
            let error: string = 'An error occured';

            if (errorResult[0] && errorResult[0].property_path === 'username' && errorResult[0].message === "This value is already used.") {
                error = "Username not available";
            }

            return {
                ...state,
                error
            };
        }
         */
    }
);


