import {reducerFromActions} from "../../../core-react/src/utils/redux/reducerBuilder";
import {AskMailConfirmationArrivedAction} from "./actions/arrived";
import {AskMailConfirmationRequestAction} from "./actions/request";
import {AskMailConfirmationSuccessAction} from "./actions/success";
import {AskMailConfirmationFailureAction} from "./actions/failure";
import {registerAskMailConfirmationListeners} from "./listeners";
import {AskMailConfirmationUndefinedEmailAction} from "./actions/undefinedEmail";
import {AskMailConfirmationSetTitleAction} from "./actions/setTitle";

export type AskMailConfirmationState = {
    loading?: boolean,
    failure?: boolean,
    success?: boolean,
    title?: string,
}

const initialState: AskMailConfirmationState = {};

export const askMailConfirmationStateKey = "ask_mail_confirmation";

export const askMailConfirmationReducer = reducerFromActions(initialState,
    AskMailConfirmationArrivedAction,
    AskMailConfirmationRequestAction,
    AskMailConfirmationSuccessAction,
    AskMailConfirmationFailureAction,
    AskMailConfirmationSetTitleAction,
    AskMailConfirmationUndefinedEmailAction,
);

export const askMailConfirmationSelectors = (state: any) => {
    const selfState: AskMailConfirmationState = state[askMailConfirmationStateKey];

    return {
        title: () => selfState.title,
        state: () => selfState,
    }
};

registerAskMailConfirmationListeners();
