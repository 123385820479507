/**
 * Created by priano on 25/08/2020 at 15:45.
 * Edited by Nicolas at 6 am, at Montigny-le-Bretonneux (78).
 */
import {Formater} from "../../../common/modeling";
import {apiRightToRight, Right} from "./right";
import {apiVehicleDriverToDriver, VehicleDriver} from "./driver";

// RAW API RESULTS
export type ApiVehicleResult = {
    plate: string,
    country: string,
    brand: string,
    model: string,
    fuelType: string,
    state: string,
    vehicleType: string,
    color: string,
    literVolume: string,
    gkmc02: string,
    vin: string,
    weightTotal: string,
    driver: any,
    currentReplacement:  {
        "licensePlate": string,
        "startDate": string,
        "endDate": string,
        "started": boolean,
        "ended": boolean,
        "city": string,
        "created": string,
        "updated": string,
        "deleted": string,
    },
    id: string | number,
    created: string,
    updated: string,
    deleted: string,
}

// FORMATED API RESULTS
export type Vehicle = {
    id: number,
    plate: string,
    country: string,
    driver?: VehicleDriver,
    brand?: string,
    model?: string,
    fuelType?: string,
    vehicleType?: string,
    color?: string,
    kgWeightTotal?: string,
    literVolume?: string,
    gkmco2?: string,
    vin?: string,
    state?: string,
}

// FORMATING
export const apiVehicleToVehicle: Formater<ApiVehicleResult, Vehicle> = apiVehicle => ({
    id: Number(apiVehicle.id),
    plate: apiVehicle.plate,
    country: apiVehicle.country,
    driver: apiVehicle.driver ? apiVehicleDriverToDriver(apiVehicle.driver) : undefined,
    brand: apiVehicle.brand || undefined,
    model: apiVehicle.model || undefined,
    fuelType: apiVehicle.fuelType ||  undefined,
    vehicleType: apiVehicle.vehicleType || undefined,
    color: apiVehicle.color || undefined,
    state: apiVehicle.state || undefined,
});
