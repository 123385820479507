import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {
    UiRightCreationWorkflowStateData, UiVehicle
} from "../index";
import {customerContractsRightCatalogsSearch} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postRightCatalogSearch";
import {RightCatalog} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right-catalog";

type RequestActionCreatorPayload = {
    availablesForVehicleId?: string | number,
    availablesForPersonId?: string | number,
    parentRightId?: string,
    wishedStartDate?: string,
    city?: string,
    deliveryChannel?: string,
}

const actions = newRequestActions<RequestActionCreatorPayload, UiRightCreationWorkflowStateData, any>(
    "/ui/right-creation/vehicle/fetch_right_catalogs",
    customerContractsRightCatalogsSearch,
    (action, state) => {
        const vehicleId = action.apiCall.body.availablesForVehicleId;

        const vehicle: UiVehicle = {
            ...state.vehicles[vehicleId],
            status: 'checking',
        };

        return {
            ...state,
            vehicles: {
                ...state.vehicles,
                [vehicle.id]: vehicle,
            }
        }
    },
    (action, state) => {
        const results: any[] = action.apiCallResult.data.items;
        const vehicleId = action.apiCallResult.request.data.availablesForVehicleId;
        const choosedRightCatalogId = state.choosedRightCatalogId;

        // available si un des right catalogs trouvé dans la réponse correspond au right catalog choisi
        const isAvailable = !!results.find(r => r.id === choosedRightCatalogId);

        const vehicle: UiVehicle = {
            ...state.vehicles[vehicleId],
            status: isAvailable ? 'available' : 'not-available',
        };

        return {
            ...state,
            vehicles: {
                ...state.vehicles,
                [vehicle.id]: vehicle,
            }
        }
    },
    (action, state) => {
        return state;
    },
);

export default actions;
