import {DbProfilesStateData} from "../index";
import { newRequestActions } from "../../../../../core-react/src/utils/redux/actionBuilder";
import {businesscustomerCityProfilesSearch} from "../../../../../core-react/src/modules/api/utils/xts/city/postProfileSearch";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";
import { Profile } from "../../../../../core-react/src/modules/api/utils/xts/city/modeling/profile";

type RequestActionCreatorPayload = {
    city: string,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbProfilesStateData, Pagination<Profile>>(
    "/db/profiles/fetch-all",
    businesscustomerCityProfilesSearch,
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        loading: false,
        loaded: true,
        byId: formatProfiles(action.apiCallResult.data.items),
    }),
    (action, state) => ({
        ...state,
        loading: false,
        loaded: true,
    })
);

export default actions;

const formatProfiles = (profiles: Profile[]) => {
    const result: any = {};

    profiles.forEach(profile => {
        result[profile.id] = {
            loading: false,
            profile,
        };
    });

    return result;
};
