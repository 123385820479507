/**
 * Created by priano on 27/08/2020 at 14:11.
 */
import {createFormSlice, FormState} from "../../../core-react/src/utils/form/redux";
import {Vehicle} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/vehicle";
import {customerContractsPostVehicle} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/postVehicle";
import {CoreNotify} from "../../../core-react/src/modules/notifications/redux/state";
import {sessionSelectors} from "../session";
import {DbVehiclesState} from "../../modules_clean/db/vehicles";
import {CoreNavigationDemand} from "../../../core-react/src/modules/routing/redux/navigation/actions";
import {routes} from "../../../configuration/routes";
import {selectCurrentRoute} from "../../../core-react/src/modules/routing/redux/navigation/state";
import {currentCity} from "../../../configuration/cities/_city";
import {customerContractsPostVehicleSearch} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/postVehicleSearch";
import * as lodash from "lodash";
import {customerContractsPutVehicle} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/putVehicle";
import {navigator} from "../../../services/navigator";
import {CustomerContractsState} from "../../modules_clean/configuration/customerContracts";
import {isStateCountry} from "../../../services/countryState";
import {INIT_ACTION_TYPE} from "../../modules_clean/ui/initialization/sagas";
import {getTranslation} from "../../../translations/useTranslation";

export type VehicleFormData = {
    // id: string,
    brand: string,
    model: string,
    color: string,
    fuelType: string,
    vehicleType: string,
    plate: string,
    country: string,
    state: string,
    driverId: string,
    literVolume: string,
    gkmco2: string,
    vin: string,
    kgWeightTotal: string,
    personId: string,
}

const initialState: VehicleFormData = {
    brand: "",
    model: "",
    color: "",
    fuelType: "",
    vehicleType: "",
    plate: "",
    country: "",
    state: "",
    driverId: "",
    literVolume: "",
    gkmco2: "",
    vin: "",
    kgWeightTotal: "",
    personId: "",
};

export type VehicleFormState = FormState<VehicleFormData> & {
    context?: {
        on: 'new' | 'edit',
        vehicleId?: string | number,
        mode?: 'default' | 'minimized',
    }
};

// @ts-ignore
export const vehicleFormSlice = createFormSlice<VehicleFormData, Vehicle, VehicleFormState>(
    "/vehicle_form",
    "vehicle_form",
    {
        fields: {
            brand: {required: false},
            model: {required: false},
            color: {required: false},
            fuelType: {required: false},
            vehicleType: {required: false},
            plate: {
                required: true,
                validator: dto => {
                    const translations = getTranslation();

                    return !(/^[ a-zA-Z0-9\\-]*$/.test(dto.plate))
                        ? [translations.invalidPlate]
                        : [];
                }
            },
            state: {
                required: false,
                validator: dto => {
                    const translations = getTranslation();

                    return isStateCountry(dto.country) && ! dto.state
                        ? [translations.stateRequired]
                        : [];
                }
            },
            country: {required: false},
            driverId: {required: false},
            literVolume: {required: false},
            gkmco2: {required: false},
            vin: {required: false},
            kgWeightTotal: {required: false},
            personId: {required: false},
        }
    }, initialState, {
        // Dispatch via component à chaque render
        onArrived(dispatch, state, action) {
            /*
            console.log('onArrived');
            const customState = fromVehicleCustomState.selectState(state);

            if (! customState.vehicleTypesLoading && customState.vehicleTypes.length === 0) {
                dispatch(FetchVehicleTypeRequestAction());
            }

            if (! customState.fuelTypesLoading && customState.fuelTypes.length === 0) {
                dispatch(FetchFuelTypeRequestAction());
            }
             */
            const {name: routeName, params: routeParams, query: queryParams} = selectCurrentRoute(state);
            const previousContext: 'new' | 'edit' | undefined = vehicleFormSlice.select(state).context?.on;
            const nextContext = routeName === routes().EditVehicle.name ? 'edit' : 'new';

            // Pour savoir si on affichera un form réduit ou complet
            const mode = routeName === routes().CreateRight.name ? 'minimized' : 'default';


            if (previousContext === nextContext) {
                return;
            }

            dispatch(
                vehicleFormSlice.actions.initialize({
                    context: {
                        on: nextContext,
                        vehicleId: queryParams.vehicleId || routeParams.vehicleId,
                        mode
                    }
                })
            );
        },
        // Dispatch via component
        onReset(dispatch, state, action) {
            dispatch(
                vehicleFormSlice.actions.initialize({})
            );
        },
        // Dispatch via listeners
        onInitialize(dispatch, state, action) {
            if (! action.context) {
                return;
            }

            const {on, vehicleId} = action.context;

            // Get du country depuis la config
            const country = CustomerContractsState.selectors.country(state);

            if (on === 'new') {
                return dispatch(
                    vehicleFormSlice.actions.initialized({
                        dto: {
                            ...initialState,
                            country,
                        },
                    })
                );
            }

            dispatch(
                vehicleFormSlice.actions.initializeRequest({
                    apiCall: customerContractsPostVehicleSearch({
                        id: vehicleId,
                        city: currentCity().id,
                    })
                })
            );
        },
        onInitializeFailure(dispatch, state, action) {
            // dispatch(CoreNotify.failure(
            //     "An error happened. You have been redirected"
            // ));
            dispatch(CoreNavigationDemand({
                route: routes().PrivateHome,
            }));
            dispatch(vehicleFormSlice.actions.reset());
        },
        onInitializeSuccess(dispatch, state, action) {
            const vehicle: Vehicle = lodash.head(action.apiCallResult.data.items) as Vehicle;

            let dto: any = initialState;

            if (vehicle) {
                dto = {
                    ...vehicle,
                    driverId: vehicle.driver?.id || ''
                };
            }

            dispatch(
                vehicleFormSlice.actions.initialized({
                    dto
                })
            );
        },
        onSubmit(dispatch, state, action) {
            const s = vehicleFormSlice.select(state);

            if (!s.submitting) {
                return;
            }

            // Get de la personne courante
            const personId = sessionSelectors.customerId(state) as number;

            const needCountryState = isStateCountry(s.dto.country);

            const callPayload = {
                ...s.dto,

                // Je ne sais pas pourquoi mais côté EC2 ça a été fait comme ça
                plate: (s.dto.plate || '').toUpperCase().split('-').join('').split(' ').join(''),
                brand: (s.dto.brand || '').toUpperCase(),

                // Set de la personne courante
                personId,
            };

            // Si pas d'usa on remove state de la payload
            if (! needCountryState) {
                delete callPayload.state;
            }

            const {submitRequest} = vehicleFormSlice.actions;

            const context = s.context;

            const call = context.on === 'edit'
                ? () => customerContractsPutVehicle(context?.vehicleId, callPayload)
                : () => customerContractsPostVehicle(callPayload);

            dispatch(
                submitRequest({
                    apiCall: call()
                })
            )
        },
        onSubmitSuccess(dispatch, state, action) {
            const {data} = action.apiCallResult;
            const s = vehicleFormSlice.select(state);
            const translations = getTranslation();

            dispatch(DbVehiclesState.actions.writeOne({
                vehicle: data
            }));

            const context = s.context;

            // Lors de l'édition si la plaque reçue est une autre plaque que celle que l'on a envoyée
            // C'est que peut être on est dans une édition de la plaque ou que Etienne send une plaque random ET en dur
            // à chaque edition. Il faut dans tous les cas redirect vers la page de management de ce nouveau véhicule avec l'id reçu
            if (context.on === 'edit') {
                if (context?.vehicleId && (context?.vehicleId !== data.id)) {
                    console.log("!!!! Different plate detected: ", context?.vehicleId, data.id);
                    const newVehicleId = data.id;
                    navigator().EditVehicle({ vehicleId: newVehicleId });

                    // Dispatch l'action pour delete l'ancien véhicule du state
                    dispatch(DbVehiclesState.actions.localDeleteOne({ vehicleId: context?.vehicleId }));

                    // Refetch les permits après 2 sec
                    setTimeout(() => {
                        dispatch({ type: INIT_ACTION_TYPE });
                    }, 2000);
                }

                // Affiche la notification Changes saved
                dispatch(CoreNotify.success(translations.changesSaved));

                // Callback de success
                const onSubmitSuccess = vehicleFormSlice.select(state).onSubmitSuccess || (() => {});
                onSubmitSuccess();
            }

           if (context.on === 'new') {

               // Si le context vaut minimized on ne fait pas la redirection
               if (context.mode === 'default') {
                   dispatch(CoreNavigationDemand({
                       route: routes().VehiclesList
                   }));
               }

               dispatch( // reset
                   vehicleFormSlice.actions.initialized({
                       dto: initialState,
                   })
               );
           }
        },
        onSubmitFailure(dispatch, state, action) {
            const translations = getTranslation()
            const data = action.apiCallResult.data as any;
            if (data && data[0]?.message === 'PLATE_ALREADY_EXISTS') {
                return dispatch(
                    vehicleFormSlice.actions.setError({
                        field: 'plate',
                        error: translations.theLicensePlateIsAlreadyRegistered
                    })
                );
            }

            const onSubmitFailure = vehicleFormSlice.select(state).onSubmitFailure || (() => {});
            onSubmitFailure();
        }
    },
    {
        initialize: (action, state) => ({
            ...state,
            context: action.context,
        })
    }
)
