/**
 * Created by priano on 25/08/2020 at 16:19.
 */
import {Formater} from "../../../common/modeling";
import {ApiVehicleResult, apiVehicleToVehicle, Vehicle} from "./vehicle";
import {ApiPersonResult, apiPersonToPerson, Person} from "./person";
import {SpecificField} from "./right-catalog";

// RAW API RESULTS
export type ApiRightResult = {
    label: string,
    reference: string,
    validityStart: string,
    validityEnd: string,
    stopDate: string,
    state: string,
    comments: string,
    deliveryChannel: string,
    rightCatalog: any,
    parentId: number,
    childsId: any[],
    validityAreas: any[],
    profile: string,
    vehicles: ApiVehicleResult[],
    operations: any[],
    person: ApiPersonResult,
    allowsParking: boolean,
    priceIncludingVat: number,
    priceVatRate: number,
    pricePosition: number,
    city: string,
    id: string | number,
    created: string,
    updated: string,
    deleted: string,
    specificFields: Array<SpecificField>,
    transfertRootId?: string | number,
    transfertParentId?: string | number,
};

// FORMATED API RESULTS
export type Right = {
    label: string,
    reference: string,
    validityStart: Date,
    validityEnd: Date,
    stopDate?: Date,
    state: string,
    comments: string,
    deliveryChannel: string,
    rightCatalog: any,
    parentId: number,
    childsId: any[],
    validityAreas: any[],
    profile: string,
    vehicles: Vehicle[],
    operations: any[],
    person: Person,
    allowsParking: boolean,
    priceIncludingVat: number,
    priceVatRate: number,
    pricePosition: number,
    city: string,
    id: string | number,
    specificFields: Array<SpecificField>,
    created: string,
    updated: string,
    deleted: string,
    transfertRootId?: string | number,
    transfertParentId?: string | number,
}

// FORMATING
export const apiRightToRight: Formater<ApiRightResult, Right> = apiRight => ({
    ...apiRight,
    validityStart: new Date(apiRight.validityStart),
    validityEnd: new Date(apiRight.validityEnd),
    stopDate: apiRight.stopDate ? new Date(apiRight.stopDate) : undefined,
    person: apiPersonToPerson(apiRight.person),
    vehicles: apiRight.vehicles.map(apiVehicleToVehicle),
});
