import {createFormSlice, FormState} from "../../../../core-react/src/utils/form/redux";
import formConfig from "./formConfig";
import listeners from "./listeners";
import hooks from "./hooks";

export type PasswordChangeData = {
    password: string,
    confirmPassword: string,
}

type UiPasswordChangeStateData = FormState<PasswordChangeData>;

const initialDto: PasswordChangeData = {
    password: "",
    confirmPassword: "",
};

const {actions, reducer, select, stateKey} = createFormSlice<PasswordChangeData, string, UiPasswordChangeStateData>(
    "/ui/password-change",
    "ui.password_change",
    formConfig,
    initialDto,
    listeners
);

export const UiPasswordChangeFormState = {
    actions, stateKey, reducer,
    initialDto, hooks,
    selectors: { state: select },
};
