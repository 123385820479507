import {objectsAreEqual} from "./comparaisons";

export function createObjectWithKeys<DestinationKeyValue>(keys: string[], keyValue: DestinationKeyValue)
    : { [key: string] : DestinationKeyValue } {
    const result: any = {};

    for (let key of keys) {
        result[key] = keyValue;
    }

    return result;
}

export function createObjectWithSameKeysAsSource<Source, DestinationKeyValue>(sourceObject: Source, keyValue: DestinationKeyValue)
    : { [key in keyof Source]: DestinationKeyValue }  {
    return createObjectWithKeys(Object.keys(sourceObject), keyValue) as { [key in keyof Source]: DestinationKeyValue };
}

export const debugEquality = (name: string, firstObject: any, secondObject: any) =>
{
    //console.group("Debug Equality", name);

    const result = innerDebugEquality("", firstObject, secondObject, true);

    console.log(name, result);

    //console.groupEnd();
};

const innerDebugEquality = (name: string, firstValue: any, secondValue: any, ignoreCheck: boolean = false) =>
{
    const result: any = {
        name,
        status: 'OK',
        children: []
    };

    if (!ignoreCheck) {
        const shallowEquality = objectsAreEqual(firstValue, secondValue);
        if (!shallowEquality) {
            return {
                ...result,
                status: 'Just different objects',
                data: {firstValue, secondValue}
            };
            //console.log('%cDifferent objects', 'color:blue;', {first: firstValue, second: secondValue});
            //return;
        }

        const strictEquality = firstValue === secondValue;
        if (strictEquality) {
            return result;
            //console.log('Strict equality');
        } else {
            result.status = 'Not strictly equal';
            result.data = firstValue;
            //console.log('%cNot strictly equal', 'color:red;', firstValue);
        }
    } else {
        result.status = 'IGNORED';
    }

    if (!(typeof firstValue === 'object' && firstValue !== null)) {
        return result;
    }

    for (let key of Object.keys(firstValue)) {
        //console.group(`${name + (name ? '.' : '')}${key}`);
        const childResult = innerDebugEquality(`${name + (name ? '.' : '')}${key}`, firstValue[key], secondValue[key]);
        result.children.push(childResult);
        if (childResult.status !== 'OK' && (result.status === "OK" || result.status === 'IGNORED')) {
            result.status = 'Children error';
        }
        //console.groupEnd();
    }

    return result;
};

export const reactShallowEqual = (firstObject: any, secondObject: any) => {
    const is = (x: any, y: any) => {
        if (x === y) {
            return x !== 0 || y !== 0 || 1 / x === 1 / y;
        } else {
            return x !== x && y !== y;
        }
    };

    return ((objA: any, objB: any) => {
        if (is(objA, objB)) return true;

        if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
            return false;
        }

        var keysA = Object.keys(objA);
        var keysB = Object.keys(objB);
        if (keysA.length !== keysB.length) return false;

        for (var i = 0; i < keysA.length; i++) {
            if (!Object.prototype.hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
                return false;
            }
        }

        return true;
    })(firstObject, secondObject);
};
