import {RightCreationFormState} from "../index";
import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {Pagination} from "../../../../core-react/src/modules/api/utils/common/modeling";
import {Vehicle} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/vehicle";
import {customerContractsPostVehicleSearch} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/postVehicleSearch";

type RequestActionCreatorPayload = {
    id?: number | string,
    city: string,
}

const actions = newRequestActions<RequestActionCreatorPayload, RightCreationFormState, Pagination<Vehicle>>(
    "/fetch_vehicle",
    customerContractsPostVehicleSearch,
    (action, state) => ({
        ...state,
        options: {
            ...state.options,
            vehicleLoading: true,
        }
    }),
    (action, state) => ({
        ...state,
        value: {
            ...state.value,
            vehicle: action.apiCallResult.data.items[0], // On va cherché par Id dans ce module donc on prend le premier élement
            vehicleLoading: false,
            vehicleLoaded: false,
        }
    }),
    (action, state) => ({
        ...state,
    })
);

export const FetchVehicleRequestAction = actions.request;
export const FetchVehicleSuccessAction = actions.success;
export const FetchVehicleFailureAction = actions.failure;
