import {ApiCall} from "../../../index";
import {Address} from "../customer-contracts/modeling/address";
import {configuration} from "../../../../../../../configuration/configuration";

const path = '/api/alias/:id/name/:name'

export const enduserPaymentCybersourcePutAliasName = (id: string, name: string,): ApiCall<Address> => ({
    url: configuration().payment.url + path,
    method: 'PUT',
    urlParameters: {id, name},
    body: {id, name}, // ou en url Param
    formater: (s: any) => s,
    tokenRepository: "xts-or-xts-account"
});
