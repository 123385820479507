/**
 * Created by priano on 25/08/2020 at 10:58.
 */
import {newAction} from "../../../core-react/src/utils/redux/actionBuilder";
import {HomeContentState} from "./index";
import {ApiCall} from "../../../core-react/src/modules/api";
import {customerContractsGetPerson} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/getPerson";
import {customerContractsSearchRights} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/postRightSearch";
import * as lodash from 'lodash';
import {orderSearchInvoices} from "../../../core-react/src/modules/api/utils/xts/order/postInvoiceSearch";
import {orderPrintInvoice} from "../../../core-react/src/modules/api/utils/xts/order/getInvoicePrint";
import {Vehicle} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/vehicle";

type HomeContentArrivedActionPayload = {
    type: string,
}

type HomeContentArrivedActionCreatorPayload = void;

export const HomeContentArrivedAction =
    newAction<HomeContentArrivedActionPayload, HomeContentState, HomeContentArrivedActionCreatorPayload>({
        type: '/home_content/arrived',
        create: (type => ({ type })),
        reducer: (action, state) => state,
    })

type FetchPersonRequestActionPayload = {
    type: string,
    apiCall: ApiCall<any>
}

type FetchPersonRequestActionCreatorPayload = {
    personId: string | number,
}

export const FetchPersonRequestAction =
    newAction<FetchPersonRequestActionPayload, HomeContentState, FetchPersonRequestActionCreatorPayload>({
        type: '/home_content/fetch_person/request',
        create: (type, payload) => ({
            type,
            apiCall: customerContractsGetPerson(payload),
        }),
        reducer: (action, state) => ({ ...state, loading: true })
    })

export const FetchPersonRequestSuccessAction = newAction({
    type: '/home_content/fetch_person/success',
    reducer: (action: any, state) => {
        const { data } = action.apiCallResult;

        return {
            ...state,
            loading: false,
            loaded: true,
            vehicles: data.vehicles,
        }
    }
})

type FetchRightsRequestActionPayload = {
    type: string,
    apiCall: ApiCall<any>
}

type FetchRightsRequestActionCreatorPayload = {
    personId: string | number,
    city: string,
}

export const FetchRightsRequestAction = newAction<FetchRightsRequestActionPayload, HomeContentState, FetchRightsRequestActionCreatorPayload>({
    type: '/home_content/fetch_rights/request',
    create: (type, payload) => ({
        type,
        apiCall: customerContractsSearchRights(payload),
    }),
    reducer: (action, state) => ({ ...state, rightsLoading: true })
})

export const FetchRightsRequestSuccessAction = newAction({
    type: '/home_content/fetch_rights/success',
    reducer: (action: any, state) => {
        const { vehicles } = state;
        const { data } = action.apiCallResult;

        const rights  = data.items;

        return {
            ...state,
            rightsLoading: false,
            vehicles: vehicles.map(
                (vehicle: any) => {
                    const associatedRights = lodash.filter(rights, r => lodash.some(r.vehicles, v => v.id === vehicle.id))

                    return {
                        ...vehicle,
                        rights: associatedRights,
                    }
                }
            )
        }
    }
})

type FetchInvoicesRequestActionPayload = {
    type: string,
    apiCall: ApiCall<any>
}

type FetchInvoicesRequestActionCreatorPayload = {
    productId: string | number,
    productType: string,
}

export const FetchInvoiceRequestAction = newAction<FetchInvoicesRequestActionPayload, HomeContentState, FetchInvoicesRequestActionCreatorPayload>({
    type: '/home_content/fetch_invoice/request',
    create: (type, payload) => ({
        type,
        apiCall: orderSearchInvoices(payload),
    }),
    reducer: (action, state) => ({ ...state })
})

export const FetchInvoiceRequestSuccessAction = newAction({
    type: '/home_content/fetch_invoice/success',
    reducer: (action: any, state) => {
        const { vehicles } = state;
        const { data } = action.apiCallResult;

        const defaultState = {
            ...state,
        };

        if (! (data.items || data.items.length === 0)) {
            return defaultState;
        }

        const { request } = action.apiCallResult;

        const { productId } = request.data;

        const newVehicles = [ ...vehicles ];

        const foundVehicle = newVehicles.find((v: any) => lodash.some(v.rights, r => r.id === productId))

        if (! foundVehicle) {
            return defaultState;
        }

        const foundRightIndex = foundVehicle.rights.findIndex((r: any) => r.id === productId);

        if (foundRightIndex === -1) {
            return defaultState;
        }

        const foundRight = foundVehicle.rights[foundRightIndex];

        foundVehicle.rights[foundRightIndex] = {
            ...foundRight,
            invoice: data.items[0] // On considérera toujours que l'on aura une seule facture
        }

        return {
            ...state,
            vehicles: newVehicles,
        }
    }
});

type PrintInvoiceRequestActionPayload = {
    type: string,
    apiCall: ApiCall<any>
}

export type PrintInvoiceRequestActionCreatorPayload = {
    invoiceId: string | number,
}

export const PrintInvoiceRequestAction = newAction<PrintInvoiceRequestActionPayload, HomeContentState, PrintInvoiceRequestActionCreatorPayload>({
    type: '/home_content/print_invoice/request',
    create: (type, payload) => ({
        type,
        apiCall: orderPrintInvoice(payload),
    }),
    reducer: (action, state) => ({ ...state })
});

export const PrintInvoiceRequestSuccessAction = newAction({
    type: '/home_content/print_invoice/success',
    reducer: (action, state) => state,
});

type AddVehicleActionPayload = { type: string, newVehicle: Vehicle, }

type AddVehicleActionCreatorPayload = { newVehicle: Vehicle, }

export const AddNewVehicleAction = newAction<AddVehicleActionPayload, HomeContentState, AddVehicleActionCreatorPayload>({
    type: '/home_content/add_vehicle',
    create: (type, payload) => ({
        type,
        newVehicle: payload.newVehicle,
    }),
    reducer: (action, state) => {
        const vehicles = [ ...state.vehicles ];

        vehicles.push(action.newVehicle);

        return {
            ...state,
            vehicles,
        }
    },
})
