import {FranceConnectState, FranceConnectConfirmationStateModel} from "./index";

const selectState = (state: any): FranceConnectConfirmationStateModel => state[FranceConnectState.stateKey];

export default {
    state: selectState,
    loading: (state: any) => selectState(state)?.loading,
    confirmed: (state: any) => selectState(state)?.confirmed,
    error: (state: any) => selectState(state)?.error,
    franceConnect: (state: any) => selectState(state)?.franceConnect,
}
