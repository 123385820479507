import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbPrepaidAccountStateData} from "../index";
import {orderSearchPrepaidAccountOperations} from "../../../../../core-react/src/modules/api/utils/xts/order/postPrepaidAccountOperationsSearch";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";
import {currentCity} from "../../../../../configuration/cities/_city";
import {PrepaidAccountOperation} from "../../../../../core-react/src/modules/api/utils/xts/order/modeling/prepaidAccountOperation";

type RequestActionCreatorPayload = {
    limit?: string,
    offset?: string,
    externalAccount?: string,
    city?: string,
    updatedSince?: string,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbPrepaidAccountStateData, Pagination<PrepaidAccountOperation>>(
    "/db/prepaid-account/fetch-operations",
    payload => orderSearchPrepaidAccountOperations({
        ...payload,
        city: currentCity().id
    }),
    (action, state) => ({
        ...state,
        operationsLoading: true,
    }),
    (action, state) => {
        const { items: operations } = action.apiCallResult.data;

        return {
            ...state,
            operationsLoading: false,
            operations,
        }
    },
    (action, state) => ({
        ...state,
        operationsLoading: false,
    })
);

export default actions;
