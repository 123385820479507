import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {RightCreationFormState} from "../index";

type ActionPayload = {
    type: string,
};
type ActionCreatorPayload = void;

export const RightCreationFormArrivedAction = newAction<ActionPayload, RightCreationFormState, ActionCreatorPayload>({
    type: "/right_creation/arrived",
    create: (type, payload) => ({
        type
    }),
    reducer: (action, state) => ({
        ...state
    })
});
