import {Right} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";
import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbRightsStateData} from "../index";


type ActionPayload = {
    type: string,
    right: Right;
};

type ActionCreatorPayload = {
    right: Right,
};

export default newAction<ActionPayload, DbRightsStateData, ActionCreatorPayload>({
    type: "/db/rights/write-one",
    create: (type, payload) => ({
        type,
        ...payload,
    }),
    reducer: ({type, right}, state) => ({
        ...state,
        byId: {
            ...state.byId,
            [right.id]: {
                loading: false,
                right
            }
        }
    })
});
