import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import getCity from "../../../../../core-react/src/modules/api/utils/xts/order/getCity";
import {OrderConfigurationStateModel} from "../index";
import {OrderCityConfiguration} from "../../../../../core-react/src/modules/api/utils/xts/order/modeling/city";

type RequestActionCreatorPayload = {
    city: string,
};

export default newRequestActions<RequestActionCreatorPayload, OrderConfigurationStateModel, OrderCityConfiguration>(
    "/configuration/order/get-city",
    payload => getCity(payload.city),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        loading: false,
        loaded: true,
        configuration: action.apiCallResult.data,
    }),
    (action, state) => ({
        ...state,
        loading: false,
        loaded: true,
    })
);
