import {reducerFromActionsObject} from "../../../core-react/src/utils/redux/reducerBuilder";
import franceConnectAuthenticate from "./actions/franceConnectAuthenticate";
import undefinedParameters from "./actions/undefinedParameters";
import checkFranceConnectConfiguration from './actions/checkFranceConnectConfiguration';
import selectors from "./selectors";
import {FranceConnectData} from "../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/france-connect";

export type FranceConnectConfirmationStateModel = {
    loading?: boolean,
    confirmed?: boolean,
    error?: string,
    franceConnect?: FranceConnectData,
    franceConnectChecked?: boolean,
}

const initialState: FranceConnectConfirmationStateModel = {};

const stateKey = 'sso.franceConnect';

const actions = { franceConnectAuthenticate, undefinedParameters, checkFranceConnectConfiguration };

export const reducer = reducerFromActionsObject(initialState, actions);

export const FranceConnectState = {
    actions, reducer, stateKey, selectors
}
