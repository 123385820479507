/**
 * Created by priano on 20/08/2020 at 19:19.
 */
export function getBrowserLanguage() {
    const w = window as any;

    const locale =
        [
            ...(w.navigator.languages || []),
            w.navigator.language,
            w.navigator.browserLanguage,
            w.navigator.userLanguage,
            w.navigator.systemLanguage
        ]
            .filter(Boolean)
            .map(language => language.substr(0, 2))

    return locale[0];
}
