import {ApiCall} from "../../../index";

import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {Pagination} from "../../common/modeling";
import {PrepaidAccountOperation} from "./modeling/prepaidAccountOperation";

const path = "/prepaid/operations/search";

type Payload = {
    limit?: string,
    offset?: string,
    externalAccount?: string,
    city?: string,
    updatedSince?: string,
};

export const orderSearchPrepaidAccountOperations = (payload: Payload): ApiCall<Pagination<PrepaidAccountOperation>> => ({
    url: coreConfiguration.api.xts.orderUrl + path,
    body: payload,
    method: 'POST',
    formater: r => r,
    tokenRepository: 'xts',
});
