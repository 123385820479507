import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData, UiVehicle} from "../index";

type ActionPayload = {
    type: string,
    endDate: Date,
};

type ActionCreatorPayload = {
    endDate: Date,
};

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/validity-period/update-end-date",
    create: (type, { endDate }) => ({ type, endDate }),
    reducer: (action, state) => {
        const v: any = { ...state.validityPeriod, validityEnd: action.endDate }
        return {
            ...state,
            validityPeriod: v,
        }
    }
});
