import {UiProfileFormStateData} from "../index";
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {customerContractsGetAddressAutocompleteByOriginAndToken} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/postAddressAutocompleteByOriginAndToken";
import {Address} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/address";


type RequestActionCreatorPayload = {
    city: string,
    name: string,
    origin: string,
    token: string,
    fieldName: string,
}

const actions = newRequestActions<RequestActionCreatorPayload, UiProfileFormStateData, Address>(
    "/ui/profile_form/get_address_detail",
    (payload) => ({
        ...customerContractsGetAddressAutocompleteByOriginAndToken(payload),
        meta: {
            name: payload.name,
            fieldName: payload.fieldName,
        },

    }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        ...state,
        addressDetail: action.apiCallResult.data,
        loading: false,
    }),
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;
