/**
 * Created by priano on 17/09/2020 at 09:19.
 */
import {DbInvoicesState, DbInvoicesStateData} from "./index";
import {createSelector} from "reselect";
import {Invoice} from "../../../../core-react/src/modules/api/utils/xts/order/modeling/invoice";

const select = (state: any): DbInvoicesStateData => state[DbInvoicesState.stateKey];

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    loaded: (state: any) => select(state).loaded,
    all: (state: any) => select(state).byId,
    allAsArray: createSelector(
        (state: any) => select(state).byId,
        all => Object.values(all).map(v => v.invoice) as Invoice[]
    ),
    one: (id: string|number) => (state: any) => select(state).byId[id],
    orderAccountId: (state: any) => {
        const invoices = Object.values(select(state).byId).map(c => c.invoice) as Invoice[];

        for (let invoice of invoices) {
            if (invoice.externalAccount.id) {
                return invoice.externalAccount.id;
            }
        }

        return undefined;
    },
    orderAccount: (state: any) => {
        const invoices = Object.values(select(state).byId).map(c => c.invoice) as Invoice[];

        for (let invoice of invoices) {
            if (invoice.externalAccount.id) {
                return invoice.externalAccount;
            }
        }

        return undefined;
    },
    ofRightId: (id: string|number) => createSelector(
        (state: any) => select(state).byId,
        invoices => {
            for (let invoice of Object.values(invoices)) {
                if (invoice.invoice && invoice.invoice.lines && invoice.invoice.lines.filter(l => Number(l.productId) === Number(id)).length) {
                    return invoice;
                }
            }

            return undefined;
        }
    )
};
