import {PersonStateData} from "../index";
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {Person} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/person";
import {customerContractsGetPerson} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/getPerson";

type RequestActionCreatorPayload = {
    id: number,
};

const actions = newRequestActions<RequestActionCreatorPayload, PersonStateData, Person>(
    "/db/person/fetch-person",
    (payload) => customerContractsGetPerson({ personId: payload.id }),
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => ({
        loading: false,
        data: action.apiCallResult.data,
    }),
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;
