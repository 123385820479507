import {listener} from "../../../core-react/src/utils/redux/middlewares/listeningMiddleware";
import {AskMailConfirmationArrivedAction} from "./actions/arrived";
import {AskMailConfirmationRequestAction} from "./actions/request";
import {currentCity} from "../../../configuration/cities/_city";
import {routes} from "../../../configuration/routes";
import {sessionSelectors} from "../session";
import {CoreNotify} from "../../../core-react/src/modules/notifications/redux/state";
import {AskMailConfirmationUndefinedEmailAction} from "./actions/undefinedEmail";
import {askMailConfirmationSelectors} from "./index";
import {selectCurrentRoute} from "../../../core-react/src/modules/routing/redux/navigation/state";
import {AskMailConfirmationSetTitleAction} from "./actions/setTitle";
import {getTranslation} from "../../../translations/useTranslation";

export const registerAskMailConfirmationListeners = () => {
    listener.onActionEnd(AskMailConfirmationArrivedAction, (dispatch, state, action) => {
        const translations = getTranslation();
        const source = selectCurrentRoute(state).query.from;
        const loginEmail = selectCurrentRoute(state).query.email;
        if (["register", "login", "reload"].indexOf(source) > -1) {
            const currentTitle = askMailConfirmationSelectors(state).title();

            // FIXME: A traduire et trouver un moyenr de traduire + à traduire
            const newTitle = {
                login: translations.yourEmailNeedsToBeConfirmed,
                register: translations.yourAccountHasBeenCreated,
                reload: translations.anotherEmailHasBeenSent,
            }[source as 'login'];

            if (currentTitle !== newTitle) {
                dispatch(AskMailConfirmationSetTitleAction({
                    title: newTitle
                }));
            }
        }

        const {loading, failure, success} = askMailConfirmationSelectors(state).state();
        if (loading || failure || success) {
            return;
        }

        const email = loginEmail || sessionSelectors.accountEmail(state);

        if (!email) {
            dispatch(CoreNotify.failure(translations.emailIsUnknown));
            dispatch(AskMailConfirmationUndefinedEmailAction());
            return;
        }

        dispatch(
            AskMailConfirmationRequestAction({
                city: currentCity().id,
                confirmationUrl: window.origin + routes().AccountValidation.path,
                email
            })
        );
    });
};
