/**
 * Created by priano on 14/10/2020 at 11:51.
 */
import {createFormSlice, FormState} from "../../../../core-react/src/utils/form/redux";
import {CoreNotify} from "../../../../core-react/src/modules/notifications/redux/state";
import {customerContractsPatchEmail} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/patchAccountEmail";
import {sessionSelectors} from "../../../modules/session";
import hooks from "./hooks";
import {SessionUpdateEmailAction} from "../../../modules/session/actions/updateEmail";
import {validateEmail} from "../../../../core-react/src/utils/validators";
import {getTranslation} from "../../../../translations/useTranslation";

export type AccountEmailChangeFormData = {
    email: string;
}

const initialDto: AccountEmailChangeFormData = {
    email: '',
}

const {actions, reducer, select, stateKey} = createFormSlice<AccountEmailChangeFormData, any, FormState<AccountEmailChangeFormData>, any>(
    "/update_mail_form",
    "update_mail_form",
    {
        fields: {
            email: {
                required: true,
                validator: dto => {
                    const translations = getTranslation();

                    if (dto.email && ! validateEmail(dto.email)) {
                        return [translations.notValidEmail];
                    }
                    return [];
                }
            },
        }
    }, initialDto, {
        // Dispatch via component à chaque render
        onArrived(dispatch, state, action) {

        },
        // Dispatch via component
        onReset(dispatch, state, action) {

        },
        // Dispatch via listeners
        onInitialize(dispatch, state, action) {
            dispatch(
                actions.initialized({
                    dto: initialDto,
                })
            );
        },
        onSubmit(dispatch, state, action) {
            const { submitting, dto } = select(state);

            if (! submitting) {
                return;
            }

             const {submitRequest} = actions;

            const accountId = sessionSelectors.accountId(state) as string;

            dispatch(
                submitRequest({
                    apiCall: customerContractsPatchEmail(accountId, {
                        email: dto.email,
                    }),
                })
            );
        },
        onSubmitSuccess(dispatch, state, action) {
            const translations = getTranslation();
            const { dto } = select(state);

            dispatch(SessionUpdateEmailAction({ email: dto.email }));

            dispatch(CoreNotify.success(translations.changesSaved));
        },
        onSubmitFailure(dispatch, state, action) {
            // return dispatch(CoreNotify.failure('An error occured'));
        }
    }
);

export const UiAccountEmailChange = {
    actions, stateKey, reducer,
    initialDto, hooks,
    selectors: { state: select },
};

