import {MailConfirmationState} from "../index";
import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {customerContractsPostAccountValidationEmail} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/postAccountValidationEmail";

type RequestActionCreatorPayload = {
    id: string,
    code: string,
}

const actions = newRequestActions<RequestActionCreatorPayload, MailConfirmationState, string>(
    "/mail-confirmation",
    customerContractsPostAccountValidationEmail,
    (action, state) => ({
        ...state,
        loading: true
    }),
    (action, state) => ({
        ...state,
        confirmed: true,
        loading: false,
    }),
    (action, state) => ({
        ...state,
        error: "An error occured",
        loading: false,
    })
);

export const MailConfirmationRequestAction = actions.request;
export const MailConfirmationSuccessAction = actions.success;
export const MailConfirmationFailureAction = actions.failure;
