import {AnyAction, Reducer, Store} from "redux";
import {Action} from "./actionBuilder";
import {act} from "react-dom/test-utils";

class ReducerBuilder<Store> {
    private readonly reductions: any = {};

    constructor(
        private readonly initialState: Store
    ) {}

    when<Action extends AnyAction = AnyAction>(actionType: string, reduction: (action: Action, state: Store) => Store) {
        this.reductions[actionType] = [...(this.reductions[actionType] || []), reduction];

        return this;
    }

    done(): Reducer<Store> {
        return (state: Store|undefined, action: AnyAction) => {
            if (this.reductions[action.type]) {
                let newState;
                for (let reduction of this.reductions[action.type]) {
                    newState = reduction(action, newState || state);
                }
                return newState;
            }

            return state || this.initialState;
        }
    }
}

export function reducer<Store>(initialState: Store) {
    return new ReducerBuilder<Store>(initialState);
}

export function reducerFromActions<State>(initialState: State, ...actions: Action<any, State, any>[]): Reducer<State> {
    const _reducer = reducer(initialState);

    for (let action of actions) {
        _reducer.when(action.type, action.reducer);
    }

    return _reducer.done();
}

type ActionsObject<State> = {
    [key: string]: Action<any, State, any> | {
        [key: string]: Action<any, State, any>
    }
};

export function reducerFromActionsObject<State>(initialState: State, actions: ActionsObject<State>, bonus?: {type: string, reducer: (...args: any[]) => any}): Reducer<State> {
    const _reducer = reducer(initialState);

    for (let key of Object.keys(actions)) {
        if (actions[key].type === undefined) {
            for (let subKey of Object.keys(actions[key])) {
                const action = (actions as any)[key][subKey];

                _reducer.when(action.type, action.reducer);
            }
            continue;
        }

        const action = actions[key] as any;

        _reducer.when(action.type, action.reducer);
    }

    if (bonus) {
        _reducer.when(bonus.type, bonus.reducer);
    }

    return _reducer.done();
}

export function reducerFromActionsWithAdditionalReducers<State>(initialState: State, actions: Action<any, State, any>[], additionalReducers?: {type: string, reducer: () => any}[]) {
    const _reducer = reducer(initialState);

    for (let action of actions) {
        _reducer.when(action.type, action.reducer);
    }

    for (let additionalReducer of additionalReducers || []) {
        _reducer.when(additionalReducer.type, additionalReducer.reducer);
    }

    return _reducer.done();
}
