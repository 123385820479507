import {SessionState} from "../index";
import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {customerContractsGetAccount} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/getAccount";
import {Account} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/account";

type RequestActionCreatorPayload = {
    id: string | number
};

const actions = newRequestActions<RequestActionCreatorPayload, SessionState, Account>(
    "/session/get-account-matching-customers",
    customerContractsGetAccount,
    (action, state) => state,
    (action, state) => ({
        ...state,
        connection: {
            persons: action.apiCallResult.data.persons,
            loading: true, // Still things to do
        }
    }),
    (action, state) => ({
        ...state,
        connection: {
            persons: [],
            loading: false
        }
    })
);

export const SessionGetAccountMatchingCustomersRequestAction = actions.request;
export const SessionGetAccountMatchingCustomersSuccessAction = actions.success;
export const SessionGetAccountMatchingCustomersFailureAction = actions.failure;
