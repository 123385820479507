import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData, UiVehicle} from "../index";
import {Area} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/area";

type ActionPayload = {
    type: string,
    area: Area,
};

type ActionCreatorPayload = {
    area: Area,
};

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/choose-area",
    create: (type, { area }) => ({ type, area }),
    reducer: (action, state) => {
        return {
            ...state,
            choosedArea: action.area,
        }
    }
});
