import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import {Provider as ReduxProvider} from "react-redux";
import {CoreThemeProvider} from "./core-react/src/modules/theme/components/themeProvider/themeProvider";
import bootstrap from "./bootstrap";
import {CoreNotificationsProvider} from "./core-react/src/modules/notifications/components/notificationsProvider";
import {CoreRouterContainer} from "./core-react/src/modules/routing/components/router/coreRouterContainer";

bootstrap()
    .then(({store}) => {
        ReactDOM.render(
            <ReduxProvider store={store}>
                <CoreThemeProvider>
                    <CoreNotificationsProvider>
                        <CoreRouterContainer/>
                    </CoreNotificationsProvider>
                </CoreThemeProvider>
            </ReduxProvider>,
            document.getElementById('root')
        );
    })
    .catch(console.error);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Token warin_m expiré
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoid2FyaW5fbSIsImdyb3VwcyI6WyJFeHRlbnNvIl0sImNpdHlJZHMiOlsiQW5kZXJsZWNodCIsIkFuZGVyVEVTVCIsIkFuZGVyVEVTVDIiLCJNb2xlbmJlZWsiLCJGb3Jlc3QiLCJHYW5zaG9yZW4iLCJCZXJjaGVtIiwiSmV0dGUiLCJFdmVyZSIsIkl4ZWxsZXMiLCJCcnV4ZWxsZXMiLCJBdWRlcmdlbSIsIlNhaW50IGdpbGxlcyIsIlVjY2xlIiwiS29la2VsYmVyZyIsIldhdGVybWFlbCIsIlNjaGFlcmJlZWsiLCJBbmRlcmxlY2h0IEFjY2VwdGFuY2UiLCJBbmRlcmxlY2h0QWNjZXB0YW5jZSJdLCJjbGFpbXMiOlt7InNlcnZpY2VOYW1lIjoiKiIsInJvdXRlSWQiOiIqIn1dLCJjb25uZWN0aW9uU3RyYXRlZ3kiOiJqd3QiLCJpYXQiOjE1ODcxMzcxMTMsImV4cCI6MTU4NzE4MDMxM30.lO3vGzX79Z4MJPbubsy3ZzAHD5xcKP9BFmgE2aPihCQ

