import {FranceConnectConfirmationStateModel} from "../index";
import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import postFranceConnectAuthenticate
    from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/postFranceConnectAuthenticate";
import {currentCity} from "../../../../configuration/cities/_city";

type RequestActionCreatorPayload = {
    code: string,
}

export default newRequestActions<RequestActionCreatorPayload, FranceConnectConfirmationStateModel, { token: string }>(
    "/france-connect/confirmation",
    payload => postFranceConnectAuthenticate(currentCity().id, payload.code),
    (action, state) => ({
        ...state,
        loading: true
    }),
    (action, state) => ({
        ...state,
        confirmed: true,
        loading: false,
    }),
    (action, state) => ({
        ...state,
        error: "An error occured",
        loading: false,
    })
);

