import {FormStatus, formStatusComputer, FormStatusConfiguration} from "./index";
import {Action, newAction} from "../redux/actionBuilder";
import {ApiCall, ApiCallResult} from "../../modules/api";
import {reducerFromActions, reducerFromActionsWithAdditionalReducers} from "../redux/reducerBuilder";
import {createObjectWithSameKeysAsSource} from "../object";
import {Dispatch} from "redux";
import {listener} from "../redux/middlewares/listeningMiddleware";

export type FormState<DTO> = {
    submitting: boolean,
    initializing: boolean,
    initializationFailed: boolean,
    dto: DTO,
    status: FormStatus<DTO>,
    configuration: FormStatusConfiguration<DTO>,
    onSubmitSuccess?: () => void,
    onSubmitFailure?: () => void,
};

export function createFormSlice<DTO, SubmitResultDTO, State extends FormState<DTO>, InitDto = any>(actionsPrefix: string, stateKey: string, formConfig: FormStatusConfiguration<DTO>, initialDto: DTO, listeners: Listeners<DTO, SubmitResultDTO, InitDto>, additionalReducers?: Reducers<State, DTO, SubmitResultDTO>) {
    const actions = buildActions(actionsPrefix, formConfig);
    const initialState = buildInitialState(initialDto, formConfig);
    const additionalReducersDefinitions = buildAdditionalReducers(actions, additionalReducers as any) as any;
    const reducer = reducerFromActionsWithAdditionalReducers(initialState, Object.values(actions), additionalReducersDefinitions);
    const select = (state: any): State => state[stateKey];

    registerListeners(listeners, actions as any);

    return { stateKey, reducer, actions, select };
}

export function emptyFormStatus<DTO>(dto: DTO) {
    return {
        valid: false,
        fields: createObjectWithSameKeysAsSource(dto, {
            changed: false,
            errors: []
        })
    }
}

type ListenerHandle<Action> = (dispatch: Dispatch, state: any, action: Action) => void;

export type Listeners<DTO, SubmitResultDTO, InitDto> = {
    onArrived?: ListenerHandle<ArrivedPayload>,
    onReset?: ListenerHandle<ResetPayload>,
    onInitialize?: ListenerHandle<InitializePayload>,
    onInitializeRequest?: ListenerHandle<InitializeRequestPayload<InitDto>>,
    onInitializeSuccess?: ListenerHandle<InitializeSuccessPayload<InitDto>>,
    onInitializeFailure?: ListenerHandle<InitializeFailurePayload<InitDto>>,
    onInitialized?: ListenerHandle<InitializedPayload<DTO>>,
    onChanged?: ListenerHandle<ChangedPayload<DTO>>,
    onSubmit?: ListenerHandle<SubmitPayload>,
    onSubmitRequest?: ListenerHandle<SubmitRequestPayload<SubmitResultDTO>>,
    onSubmitSuccess?: ListenerHandle<SubmitSuccessPayload<SubmitResultDTO>>,
    onSubmitFailure?: ListenerHandle<SubmitFailurePayload<SubmitResultDTO>>
};

type Reducer<Action, State> = (action: Action, state: State) => State;

type Reducers<State, DTO, SubmitResultDTO> = {
    initialize?: Reducer<InitializePayload, State>,
    initialized?: Reducer<InitializedPayload<DTO>, State>,
    submitSuccess?: Reducer<SubmitSuccessPayload<SubmitResultDTO>, State>,
    submitFailure?: Reducer<SubmitFailurePayload<SubmitResultDTO>, State>,
}

type FormActions<DTO, SubmitResultDTO, State extends FormState<DTO>, InitDto> = {
    arrived: Action<ArrivedPayload, State, ArrivedCreatorPayload>,
    reset: Action<ResetPayload, State, ResetCreatorPayload>,
    initialize: Action<InitializePayload, State, InitializeCreatorPayload>,
    initializeRequest: Action<InitializeRequestPayload<InitDto>, State, InitializeRequestCreatorPayload<InitDto>>,
    initializeSuccess: Action<InitializeSuccessPayload<InitDto>, State>,
    initializeFailure: Action<InitializeFailurePayload<InitDto>, State>,
    initialized: Action<InitializedPayload<DTO>, State, InitializedCreatorPayload<DTO>>,
    changed: Action<ChangedPayload<DTO>, State, ChangedCreatorPayload<DTO>>,
    submit: Action<SubmitPayload, State, SubmitCreatorPayload>,
    submitRequest: Action<SubmitRequestPayload<SubmitResultDTO>, State, SubmitRequestCreatorPayload<SubmitResultDTO>>,
    submitSuccess: Action<SubmitSuccessPayload<SubmitResultDTO>, State, void>,
    submitFailure: Action<SubmitFailurePayload<SubmitResultDTO>, State, void>,
    setError: Action<SetErrorPayload<DTO>, State, SetErrorCreatorPayload<DTO>>,
};

function registerListeners<DTO, SubmitResultDTO, State extends FormState<DTO>, InitDto>(listeners: Listeners<DTO, SubmitResultDTO, InitDto>, actions: FormActions<DTO, SubmitResultDTO, State, InitDto>) {
    const nothing = () => {};

    listener.onActionEnd(actions.arrived, listeners.onArrived || nothing);
    listener.onActionEnd(actions.reset, listeners.onReset || nothing);
    listener.onActionEnd(actions.initialize, listeners.onInitialize || nothing);
    listener.onActionEnd(actions.initializeRequest, listeners.onInitializeRequest || nothing);
    listener.onActionEnd(actions.initializeSuccess, listeners.onInitializeSuccess || nothing);
    listener.onActionEnd(actions.initializeFailure, listeners.onInitializeFailure || nothing);
    listener.onActionEnd(actions.initialized, listeners.onInitialized || nothing);
    listener.onActionEnd(actions.changed, listeners.onChanged || nothing);
    listener.onActionEnd(actions.submit, listeners.onSubmit || nothing);
    listener.onActionEnd(actions.submitRequest, listeners.onSubmitRequest || nothing);
    listener.onActionEnd(actions.submitSuccess, listeners.onSubmitSuccess || nothing);
    listener.onActionEnd(actions.submitFailure, listeners.onSubmitFailure || nothing);
}

function buildInitialState<DTO>(initialDTO: DTO, config: FormStatusConfiguration<DTO>): FormState<DTO> {
    return {
        submitting: false,
        initializing: true,
        initializationFailed: false,
        dto: initialDTO,
        status: emptyFormStatus(initialDTO),
        configuration: config
    };
}

function buildAdditionalReducers<State extends FormState<DTO>, DTO, SubmitResultDTO, InitDto>(actions: FormActions<DTO, SubmitResultDTO, State, InitDto>, additionalReducers?: Reducers<State, DTO, SubmitResultDTO>) {
    const reducers = [];

    if (additionalReducers?.initialize) {
        reducers.push({ type: actions.initialize.type, reducer: additionalReducers.initialize });
    }
    if (additionalReducers?.initialized) {
        reducers.push({ type: actions.initialized.type, reducer: additionalReducers.initialized });
    }
    if (additionalReducers?.submitSuccess) {
        reducers.push({ type: actions.submitSuccess.type, reducer: additionalReducers.submitSuccess });
    }
    if (additionalReducers?.submitFailure) {
        reducers.push({ type: actions.submitFailure.type, reducer: additionalReducers.submitFailure });
    }

    return reducers;
}

function buildActions<DTO, SubmitResultDTO, State extends FormState<DTO>, InitDto>(prefix: string, formConfig: FormStatusConfiguration<DTO>): FormActions<DTO, SubmitResultDTO, State, InitDto> {
    return {
        arrived: newAction({
            type: prefix + '/form/arrived',
            create: (type, payload: any) => ({ type }),
            reducer: (action, state) => state
        }),
        reset: newAction({
            type: prefix + '/form/reset',
            create: (type, payload: any) => ({ type }),
            reducer: (action, state) => state
        }),
        initialize: newAction({
            type: prefix + '/form/initialize',
            create: ((type, payload: any) => ({ type, ...payload })),
            reducer: (action, state) => ({
                ...state,
                initializing: true,
            })
        }),
        initializeRequest: newAction({
            type: prefix + '/form/initialize/request',
            create: ((type, payload: any) => ({ type, ...payload })),
            reducer: (action, state) => state,
        }),
        initializeSuccess: newAction({
            type: prefix + '/form/initialize/success',
            reducer: (action, state) => state,
        }),
        initializeFailure: newAction(({
            type: prefix + '/form/initialize/failure',
            reducer: (action, state) => state,
        })),
        initialized: newAction({
            type: prefix + '/form/initialized',
            create: (type: any, payload: any) => ({ type, dto: payload.dto }),
            reducer: ({dto}, state) => ({
                ...state,
                submitting: false,
                initializing: false,
                initializationFailed: false,
                status: formStatusComputer(dto, {}, formConfig),
                dto,
            })
        }),
        changed: newAction({
            type: prefix + '/form/changed',
            create: (type, {field, value}: any) => ({ type, field, value }),
            reducer: (action: any, state) => {
                const dto = {
                    ...state.dto,
                    [action.field]: action.value
                };

                return {
                    ...state,
                    dto,
                    status: formStatusComputer(dto, {
                        fieldChanged: action.field
                    }, formConfig, state.status)
                }
            }
        }),
        submit: newAction({
            type: prefix + '/form/submit',
            create: (type, { overriddenData, onSubmitSuccess, onSubmitFailure }: { overriddenData?: any, onSubmitSuccess?: () => void, onSubmitFailure?: () => void } = {}) => ({ type, overriddenData, onSubmitSuccess, onSubmitFailure }),
            reducer: (action: any, state) => {
                const status = formStatusComputer(state.dto, {
                    formSubmitted: true
                }, formConfig, state.status);

                return {
                    ...state,
                    // Override du to avec les données données pour override si existantes
                    dto: {
                        ...state.dto,
                        ...action?.overriddenData,
                    },
                    status,
                    submitting: status.valid, // On va lancer le submitting si form est valid
                    submitted: false,
                    onSubmitSuccess: action?.onSubmitSuccess,
                    onSubmitFailure: action?.onSubmitFailure,
                }
            }
        }),
        submitRequest: newAction({
            type: prefix + '/form/submit/request',
            create: (type, {apiCall}: any) => ({ type, apiCall }),
            reducer: (action, state) => state,
        }),
        submitSuccess: newAction({
            type: prefix + '/form/submit/success',
            reducer: (action, state) => ({
                ...state,
                submitting: false,
                submitted: true,
            })
        }),
        submitFailure: newAction({
            type: prefix + '/form/submit/failure',
            reducer: (action, state) => ({
                ...state,
                submitting: false,
                submitted: true,
            })
        }),
        setError: newAction({
            type: prefix + '/form/set-error',
            create: (type, {error, field}: SetErrorCreatorPayload<DTO>) => ({ type, field, error }),
            reducer: (action: any, state) => ({
                ...state,
                status: {
                    ...state.status,
                    fields: {
                        ...state.status.fields,
                        [action.field]: {
                            changed: true,
                            validity: 'invalid',
                            errors: [action.error]
                        }
                    }
                }
            })
        })
    } as any
}

type ArrivedPayload = { type: string };
type ArrivedCreatorPayload = void;
type ResetPayload = { type: string };
type ResetCreatorPayload = void;
type InitializePayload = any;
type InitializeCreatorPayload = any;
type InitializeRequestPayload<InitDto> = { type: string, apiCall: ApiCall<InitDto> };
type InitializeRequestCreatorPayload<InitDto> = { apiCall: ApiCall<InitDto> };
type InitializeSuccessPayload<InitDto> = { type: string, apiCallResult: ApiCallResult<InitDto> };
type InitializeFailurePayload<InitDto> = { type: string, apiCallResult: ApiCallResult<InitDto> };
type InitializedPayload<DTO> = { type: string, dto: DTO };
type InitializedCreatorPayload<DTO> = { dto: DTO };
type ChangedPayload<DTO> = { type: string, field: keyof DTO, value: any };
type ChangedCreatorPayload<DTO> = { field: keyof DTO, value: any };
type SubmitPayload = { type: string };
type SetErrorPayload<DTO> = { type: string, field: keyof DTO, value: string };
type SubmitCreatorPayload = void;
type SubmitRequestPayload<SubmitResultDTO> = { type: string, apiCall: ApiCall<SubmitResultDTO> };
type SubmitRequestCreatorPayload<SubmitResultDTO> = { apiCall: ApiCall<SubmitResultDTO> };
type SubmitSuccessPayload<SubmitResultDTO> = { type: string, apiCallResult: ApiCallResult<SubmitResultDTO> };
type SubmitFailurePayload<SubmitResultDTO> = { type: string, apiCallResult: ApiCallResult<SubmitResultDTO> };
type SetErrorCreatorPayload<DTO> = { field: keyof DTO, error: string };







