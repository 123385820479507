import { Profile } from "../../../../core-react/src/modules/api/utils/xts/city/modeling/profile";
import fetchAll from "./actions/fetchAll";
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import selectors from "./selectors";

export type DbProfilesStateData = {
    loading: boolean,
    loaded: boolean,
    byId: {
        [id: string]: {
            loading: boolean, // One right being fetched
            profile: Profile,
        }
    }
}

const initialState: DbProfilesStateData = {
    loading: false,
    loaded: false,
    byId: {}
};

const actions = { fetchAll };

const stateKey = "db.profiles";

const reducer = reducerFromActionsObject(initialState, actions);

export const DbProfilesState = {
    actions, stateKey, reducer, selectors
};
