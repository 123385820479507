import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {ApiCall} from "../../../index";

const path = '/france-connect/:city/authenticate/:code';

export default (city: string, code: string): ApiCall<{ token: string }> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path.replace(':city', city).replace(':code', code),
    method: 'POST',
    formater: r => r,
    tokenRepository: "xts"
});
