/**
 * Created by priano on 16/09/2020 at 11:29.
 */
import {DbRightsState, DbRightsStateData} from "./index";
import {createSelector} from "reselect";
import {Right} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";

const select = (state: any): DbRightsStateData => state[DbRightsState.stateKey];
const getAllAsArray = createSelector(
    (state: any) => select(state).byId,
    all => Object.values(all).map(v => v.right) as Right[]
);

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    all: (state: any) => select(state).byId,
    allAsArray: getAllAsArray,
    one: (id: string | number) => (state: any) => select(state).byId[id],
    rightsInvoiced: createSelector(
        getAllAsArray,
        rights => rights.filter(r => r.state === 'invoiced')
    ),
    rightsSubmitted: createSelector(
        getAllAsArray,
        rights => rights.filter(r => r.state === 'submitted')
    ),
    completionPercentage: (state: any) => select(state).completionPercentage,
}
