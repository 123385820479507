import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData} from "../index";

type ActionPayload = {
    type: string,
    index: number,
};

type ActionCreatorPayload = {
    index: number,
};

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/mark-index-as-completed",
    create: (type, { index}) => ({ type, index }),
    reducer: (action, state) => {
        // si index déjà completed on ne fait rien
        if (state.completedIndexes.indexOf(action.index) !== -1) {
            return {
                ...state
            };
        }

        // sinon on le rajoute dans le liste des indexes complétés
        const completedIndexes = [...state.completedIndexes, action.index];

        return {
            ...state,
            completedIndexes,
        };
    }
});
