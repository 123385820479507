import {all, call, put, select, take, takeEvery} from "redux-saga/effects";
import fetchRightCatalogs from "./actions/fetchRightCatalogs";
import {CoreNotify} from "../../../../core-react/src/modules/notifications/redux/state";
import {DbVehiclesState} from "../../db/vehicles";
import rightCreationSetVehicles from "./actions/rightCreationSetVehicles";
import {rightCreationWorkflowSelectors, UiVehicle} from "./index";
import fetchRightCatalogsForVehicle from "./actions/fetchRightCatalogsForVehicle";
import {sessionSelectors} from "../../../modules/session";
import {currentCity} from "../../../../configuration/cities/_city";
import rightCreationVehicleSelectionArrived from "./actions/rightCreationVehicleSelectionArrived";
import createRight, {CreateRightActionPayload} from "./actions/createRight";
import {mapProvidedProofToProofPayload} from "../../../../components/content/domain/right-creation-workflow/utils";
import uploadProof from "./actions/uploadProof";
import realCreateRight from "./actions/realCreateRight";
import submitRightToApprobation from "./actions/submitRightToApprobation";
import {DbRightsState} from "../../db/rights";
import {navigator} from "../../../../services/navigator";
import markRightCatalogIdAsChoosed from "./actions/markRightCatalogIdAsChoosed";
import chooseRightCatalogArea from "./actions/chooseRightCatalogArea";
import markVehicleAsChecked from "./actions/markVehicleAsChecked";
import {vehicleFormSlice} from "../../../modules/vehicle-form";
import {mapVehicleToUiVehicle} from "./utils";
import rightCreationAddVehicles from "./actions/rightCreationAddVehicles";
import {getTranslation} from "../../../../translations/useTranslation";
import {parseQuery} from "../../../../core-react/src/modules/routing/redux/navigation/utils";

export default function* sagas() {
    yield takeEvery(fetchRightCatalogs.failure.type, function* (action: any) {
        // yield put(CoreNotify.failure("An error occured"));
    });

    // Intercepter lors du choix du right catalog pour auto sélectionner les areas
    yield takeEvery(markRightCatalogIdAsChoosed.type, function* () {
        // si auto skip disabled pour la ville on ne fait rien
        if (!currentCity().rightWorkflowAutoSkipEnabled) {
            return;
        }

        const rightCatalog = yield select(rightCreationWorkflowSelectors.getChoosedRightCatalog);
        const { areas } = rightCatalog;

        // Si une seule variation de zone est disponible, la cocher automatiquement
        if (Array.isArray(areas) && areas.length === 1) {
            yield put(chooseRightCatalogArea({ area: areas[0] }));
        }
    });

    // On arrived sur la sélection des véhicules on vérifie la disponibilité des véhicules par rapprot au right catalog
    yield takeEvery(rightCreationVehicleSelectionArrived.type, function* () {
        const vehicles = yield select(DbVehiclesState.selectors.allAsArray);

        const mapped: UiVehicle[] = vehicles.map(mapVehicleToUiVehicle);

        // On récupère les query depuis window.location.search (wishedStartDate est modifié dans l'étaoe de sélection des des rightCatalog)
        const {wishedStartDate, parentRightId, renewal, vehicleId} = parseQuery(window.location.search);

        const isRenewal = renewal === "true"

        yield put(
            rightCreationSetVehicles({
                vehicles: mapped,
            })
        );

        const personId = yield select(sessionSelectors.customerId);

        const alls = vehicles.map(
            (v: any) => (
                put(
                    fetchRightCatalogsForVehicle.request({
                        availablesForPersonId: personId,
                        availablesForVehicleId: v.id,
                        city: currentCity().id,
                        deliveryChannel: 'FRONTOFFICE',
                        wishedStartDate,
                        ...isRenewal && {parentRightId},
                    })
                )
            )
        );

        yield all(alls);

        for (const _ of vehicles) {
            yield take([fetchRightCatalogsForVehicle.success.type, fetchRightCatalogsForVehicle.failure.type]);
        }

        const uiVehicles = yield select(rightCreationWorkflowSelectors.vehiclesAsArray);
        const uiAvailableVehicles = yield select(rightCreationWorkflowSelectors.vehiclesFilteredByStatus('available'));

        // si on vient d’une fiche véhicule, on preselect ce véhicule par défaut
        // Si le vehicle est disponible on le coche sinon rien
        const defaultCheckedVehicle = uiAvailableVehicles.find((v: UiVehicle) => v.id === vehicleId);
        if (defaultCheckedVehicle) {
            yield put(markVehicleAsChecked({ vehicleId }));
        }

        // si auto skip disabled pour la ville on ne fait rien
        // On bloque ici car la suite n'est que test pour auto select le véhicule or rightWorkflowAutoSkipEnabled=false on ne veut pas de ça
        if (!currentCity().rightWorkflowAutoSkipEnabled) {
            return;
        }

        // si le nb de véhicule ET nb de véhicule disponible = 1 et que pas de véhicule à check par défaut
        if ((uiVehicles.length === 1 && uiAvailableVehicles.length === 1) && !defaultCheckedVehicle) {
            const vehicle = uiAvailableVehicles[0];
            yield put(markVehicleAsChecked({ vehicleId: vehicle.id }));
        }
    });

    yield takeEvery(vehicleFormSlice.actions.submitSuccess.type, function* (action: any) {
        const {data: vehicle} = action.apiCallResult;

        const mapped = mapVehicleToUiVehicle(vehicle);

        // Dispatch rightCreationAddVehicles
        yield put(
            rightCreationAddVehicles({
                vehicle: mapped,
            })
        );

        const personId = yield select(sessionSelectors.customerId);

        yield put(
            fetchRightCatalogsForVehicle.request({
                availablesForPersonId: personId,
                availablesForVehicleId: vehicle.id,
                city: currentCity().id,
                deliveryChannel: 'FRONTOFFICE',
            })
        );

        yield take([fetchRightCatalogsForVehicle.success.type, fetchRightCatalogsForVehicle.failure.type]);

        // On récupère tous les vehicles disponible
        const uiAvailableVehicles = yield select(rightCreationWorkflowSelectors.vehiclesFilteredByStatus('available'));

        // on map uiAvailableVehicles pour avoir un array de id de vehicle
        const uiAvailableVehiclesId = uiAvailableVehicles.map((vehicle: UiVehicle) => vehicle.id);

        // Si l'id du vehicle est diponible on le coche sinon rien
        if (uiAvailableVehiclesId.indexOf(vehicle.id.toString()) !== -1){
            yield put(markVehicleAsChecked({ vehicleId: vehicle.id }));
        }

    });

    // Intercepter la tentative de création de droit.
    // Les instructions suivantes concentre toutes les logiques pour la création d'un nouveau droit
    // upload de tous les proofs - post de la payload de création de droit - submit du nouveau droit créé etc.
    yield takeEvery(createRight.type, function* (action) {
        const translations = getTranslation();
        const {personId, proofs, right, vehicleId} = action as CreateRightActionPayload;

        // Upload de tous les proofs lors de la demande de création de droit
        for (let proof of proofs) {
            const proofPayload = yield call(mapProvidedProofToProofPayload, proof, vehicleId, personId);

            yield put(uploadProof.request(proofPayload));
            yield take([uploadProof.success.type, uploadProof.failure.type]);
        }

        // Post réel de la payload sur l'api une fois tous les proofs uploaded
        yield put(realCreateRight.request(right));

        // Récupération du résultat du POST
        const rightCreationResult = yield take([realCreateRight.success.type, realCreateRight.request.type]);

        // si erreur lors de la création de droit
        if (rightCreationResult.apiCallResult.errored) {
            // yield put(CoreNotify.failure("An error occured"));
            return;
        }

        // Si succès de la création
        const newRight = rightCreationResult.apiCallResult.data;

        // On lance le submit du right qui vient d'être créé
        yield put(
            submitRightToApprobation.request({
                rightId: newRight.id,
                channel: "FRONTOFFICE",
                date: new Date().toISOString().slice(0, 19) + '+00:00',
            })
        );

        // Récupération du résultat
        const rightSubmitResult = yield take([submitRightToApprobation.success.type, submitRightToApprobation.request.type]);

        // si erreur lors de la création de droit
        if (rightSubmitResult.apiCallResult.errored) {
            // yield put(CoreNotify.failure("An error occured"));
            return;
        }

        // Si succès du submit
        const submittedRight = rightSubmitResult.apiCallResult.data;

        yield put(CoreNotify.success(translations.permitRequested));
        yield put(
            DbRightsState.actions.writeOne({
                right: submittedRight,
            })
        )

        navigator().PermitsList({ selectedPermit: newRight.id });
    });
}
