import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiRightCreationWorkflowStateData, initialState} from "../";

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = void;

export default newAction<ActionPayload, UiRightCreationWorkflowStateData, ActionCreatorPayload>({
    type: "/ui/right-creation/reset",
    create: (type, payload) => ({
        type
    }),

    /**
     *  Reduce du state avec les valeurs initialState
     * @param action
     * @param state
     */
    reducer: (action, state) => initialState
});
