import {DbVehiclesStateData, DbVehiclesState} from "./index";
import {Vehicle} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/vehicle";
import {createSelector} from "reselect";

const select = (state: any): DbVehiclesStateData => state[DbVehiclesState.stateKey];

const allAsArray = createSelector(
    (state: any) => select(state).byId,
    all => Object.values(all).map(v => v.vehicle) as Vehicle[]
);

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    all: (state: any) => select(state).byId,
    allAsArray,
    count: createSelector(
        allAsArray,
        (arr) => arr.length
    ),
    one: (id: string|number) => (state: any) => select(state).byId[id],
}
