import {ApiCall} from "../../../index";
import {configuration} from "../../../../../../../configuration/configuration";
import {Alias} from "../../../../../../../redux/modules_clean/db/aliases";

const path = '/api/alias/:id/main'

export const enduserPaymentCybersourcePutAliasMain = (id: string): ApiCall<Alias> => ({
    url: configuration().payment.url + path,
    method: 'PUT',
    urlParameters: {id},
    body: {id}, // ou en url Param
    formater: (s: any) => s,
    tokenRepository: "xts-or-xts-account"
});
