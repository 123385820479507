import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {apiJwtToJwt, Jwt} from "./modeling/jwt";

const path = "/account/login/account";

type Payload = {
    username: string,
    password: string,
    city: string,
};

export const customerContractsPostAccountLoginWithAccount = (payload: Payload): ApiCall<Jwt> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: apiJwtToJwt
});
