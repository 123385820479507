import {createBrowserHistory} from "history";

export type CoreRoutingConfiguration = {
    [key: string]: Route|RouteGroup|undefined;
}

export type RouteGroup = {
    name: string,
    routes: {
        [key: string]: Route
    }
}

export type Route = {
    name: string,
    menu?: 'alwaysShown' /*DEFAULT*/ | 'hidden' | 'history' | 'alwaysShownAndLinksToHistoryRoute',
    path?: string,
    args?: string[], // Args to build the path and the name
    component?: React.ComponentType | (() => React.ReactNode),
    render?: () => React.ReactNode,
    history?: {
        key: string,
        confirmReplacement: boolean
    },
    private?: boolean,
}

export const routingHistory = createBrowserHistory();
