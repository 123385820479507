/**
 * Created by priano on 17/09/2020 at 09:17.
 */
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {orderSearchInvoices} from "../../../../../core-react/src/modules/api/utils/xts/order/postInvoiceSearch";
import {DbInvoicesStateData} from "../index";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";
import {currentCity} from "../../../../../configuration/cities/_city";
import {Invoice} from "../../../../../core-react/src/modules/api/utils/xts/order/modeling/invoice";

type RequestActionCreatorPayload = {
    id: string | number,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbInvoicesStateData, Pagination<Invoice>>(
    "/db/invoices/fetch-one",
    payload => orderSearchInvoices({
        id: payload.id as string,
        city: currentCity().id
    }),
    (action, state) => ({
        ...state,
    }),
    (action, state) => {
        const invoices = formatInvoices(action.apiCallResult.data.items);

        return {
            ...state,
            byId: {
                ...state.byId,
                ...invoices,
            }
        }
    },
    (action, state) => ({
        ...state,
    })
);

export default actions;

const formatInvoices = (invoices: Invoice[]) => {
    const result: any = {};

    invoices.forEach(invoice => {
        result[invoice.id] = {
            loading: false,
            invoice,
        };
    });

    return result;
};
