/**
 * Created by priano on 05/10/2020 at 16:55.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {Address} from "./modeling/address";

const path = "/address/autocomplete/address";

type Payload = {
    city: string,
    origin: string,
    token: string,
};

export const customerContractsGetAddressAutocompleteByOriginAndToken = ({city, origin, token}: Payload): ApiCall<Address> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: {
        city,
        address: token,
        origin,
    },
    formater: (s: any) => {
        return {
            ...s,
            number: s.number
        };
    },
    tokenRepository: 'xts-or-xts-account'
});
