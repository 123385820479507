/**
 * Created by priano on 05/10/2020 at 16:55.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {Address} from "./modeling/address";

const path = "/address";

type Payload = {
    city: string,
    area: string,
    street: string,
    number: string,
    postalCode: string,
    state: string,
    locality: string,
    addressDetails: string,
    country: string,
    usage: string,
    personId: number,
    lat: number,
    lng: number,
};

export const customerContractsPostAddress = (payload: Payload): ApiCall<Address> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: (s: any) => s,
    tokenRepository: "xts-or-xts-account"
});
