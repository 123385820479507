import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import { SetFailedLoginAttempt } from "./actions/setFailedLoginAttempt";
import selectors from "./selectors"

export type UiLoginAttemptFailureStateData = {
    reason: string,
    email: string,
}

const initialState: UiLoginAttemptFailureStateData = {
    reason: '',
    email: '',
};

const actions = { SetFailedLoginAttempt }

const stateKey = "ui.login-attempt-failure";

const reducer = reducerFromActionsObject(initialState, actions);


export const UiLoginAttemptFailureState = {
    stateKey, reducer, actions, selectors
};
