import {SessionState} from "../index";
import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {customerContractsPostAccountLoginCustomer} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/postAccountLoginCustomer";
import {Jwt} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/jwt";
import jwt_decode from "jwt-decode";

type RequestActionCreatorPayload = {
    id: string | number
}

const actions = newRequestActions<RequestActionCreatorPayload, SessionState, Jwt>(
    "/session/end-customer-connection-process",
    (payload) => ({
        ...customerContractsPostAccountLoginCustomer(payload),
        meta: {
            id: payload.id
        }
    }),
    (action, state) => state,
    (action, state) =>  {
        const decodedToken: any = jwt_decode(action.apiCallResult.data.token);
        const tokenExpirationTimestamp = decodedToken.exp;

        const persons = state?.connection?.persons || [];
        const personId = action.apiCallResult.meta.id;
        const person = persons.find(p => p.id === personId);

        return {
            ...state,
            connection: {
                persons,
                loading: false,
            },
            customer: {
                id: personId,
                token: action.apiCallResult.data.token,
                tokenExpirationTimestamp,
                language: person?.language,
            }
        };
    },
    (action, state) => ({
        ...state,
        connection: {
            persons: state.connection?.persons || [],
            loading: false,
        }
    }),
);

export const SessionEndCustomerConnectionProcessRequestAction = actions.request;
export const SessionEndCustomerConnectionProcessSuccessAction = actions.success;
export const SessionEndCustomerConnectionProcessFailureAction = actions.failure;
