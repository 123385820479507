import {Right} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";
import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbRightsStateData} from "../index";
import {customerContractsPutRightVehiculeSwap} from "../../../../../core-react/src/modules/api/utils/xts/customer-contracts/putRightVehiculeSwap";

type RequestActionCreatorPayload = {
    id: string,
    date: Date,
    channel: string,
    oldVehicleId: string|number,
    newVehicleId: string|number,
}

const actions = newRequestActions<RequestActionCreatorPayload, DbRightsStateData, Right>(
    "/db/rights/swap-one",
    (payload) => {
        return customerContractsPutRightVehiculeSwap(
            payload.id, payload
        );
    },
    (action, state) => ({
        ...state,
        loading: true,
    }),
    (action, state) => {
        const cancelledRight = action.apiCallResult.data;
        const rightId = cancelledRight.id;
        const byId = state.byId;
        const currentRight = byId[rightId];

        return {
            ...state,
            loading: false,
            byId: {
                ...byId,
                [rightId]: {
                    loading: false,
                    right: {
                        ...currentRight,
                        ...cancelledRight,
                    }
                }
            }
        }
    },
    (action, state) => ({
        ...state,
        loading: false,
    })
);

export default actions;
