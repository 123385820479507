/**
 * Created by priano on 28/08/2020 at 09:06.
 */
import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {apiVehicleToVehicle, Vehicle} from "./modeling/vehicle";

const path = "/vehicle";

type Payload = {
    plate: string,
    country?: string,
    brand: string,
    model?: string,
    fuelType?: string,
    vehicleType?: string,
    color?: string,
    literVolume?: string,
    gkmco2?: string,
    vin?: string,
    kgWeightTotal?: string,
    personId?: string | number,
};

export const customerContractsPostVehicle = (payload: Payload): ApiCall<Vehicle> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: apiVehicleToVehicle,
    tokenRepository: 'xts',
});
