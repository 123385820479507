/**
 * Created by priano on 16/09/2020 at 11:29.
 */
import {CustomerProof} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/customer-proof";
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import fetchAll from "./actions/fetchAll";
import downloadOne from "./actions/downloadOne";

import selectors from "./selectors";


export type DbProofsStateData = {
    loading: boolean, // All rights being fetched
    byId: {
        [id: string]: {
            loading: boolean, // One proof being fetched
            proof?: CustomerProof
        }
    },
    downloadQueue?: { [key: string]: boolean }
}

const initialState: DbProofsStateData = {
    loading: false,
    byId: {},
    downloadQueue: {}
};

const actions = { fetchAll, downloadOne };

const stateKey = "db.proofs";

const reducer = reducerFromActionsObject(initialState, actions);

export const DbProofsState = {
    actions, stateKey, reducer, selectors
};
