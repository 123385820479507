import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {UiProfileFormStateData} from "../index";

type ActionPayload = {
    type: string,
    address: string,
    fieldName: string,
}

type ActionCreatorPayload = {
    address: string,
    fieldName: string,
}

const actions = newAction<ActionPayload, UiProfileFormStateData, ActionCreatorPayload>({
    type: '/ui/profile_form/set_address_details',
    create: (type, payload) =>({
        type,
        address: payload.address,
        fieldName: payload.fieldName
    })
});

export default actions;
