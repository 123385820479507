import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {LogsStateData} from "../types";
import {
    internalLogsPostLogs,
    PostLogPayload
} from "../../../../core-react/src/modules/api/utils/xts/internal-logs/postLogs";

type RequestActionCreatorPayload = PostLogPayload;

const actions = newRequestActions<RequestActionCreatorPayload, LogsStateData, void>(
    "/logs/post-log",
    internalLogsPostLogs,
    (action, state) => state,
    (action, state) => state,
    (action, state) => state
);

export default actions;

export const actionTypes = [
    actions.request.type,
    actions.success.type,
    actions.failure.type,
];
