/**
 * Created by priano on 25/08/2020 at 10:58.
 */
import {listener} from "../../../core-react/src/utils/redux/middlewares/listeningMiddleware";
import {
    FetchPersonRequestSuccessAction,
    FetchRightsRequestSuccessAction,
    HomeContentArrivedAction,
    PrintInvoiceRequestSuccessAction
} from "./actions";
import {
    homeContentArrived,
    homeContentFetchPersonSuccess,
    homeContentFetchRightsSuccess, homeContentPrintInvoiceSuccess
} from "./action-listener-handlers";

export const registerListener = () => {
    listener.onActionEnd(HomeContentArrivedAction, homeContentArrived);
    listener.onActionEnd(FetchPersonRequestSuccessAction, homeContentFetchPersonSuccess);
    listener.onActionEnd(FetchRightsRequestSuccessAction, homeContentFetchRightsSuccess);
    listener.onActionEnd(PrintInvoiceRequestSuccessAction, homeContentPrintInvoiceSuccess)
}
