
export const objectsAreEqual = (firstObject: any, secondObject: any) => {
    if (firstObject === secondObject) {
        return true;
    }

    const result = JSON.stringify(firstObject) === JSON.stringify(secondObject);

    //console.log(firstObject, secondObject, result);

    //console.log('Objects are equal (===, json)?', firstObject === secondObject, result);

    return result;
};
