import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {Account, apiAccountToAccount} from "./modeling/account";

const path = "/account/:id";

type Payload = {
    id: string|number
};

export const customerContractsGetAccount = (payload: Payload): ApiCall<Account> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    urlParameters: payload,
    method: 'GET',
    formater: apiAccountToAccount,
    tokenRepository: "xts-account"
});
