import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {SessionState} from "../index";

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = void;

export const SessionDisconnectAction = newAction<ActionPayload, SessionState, ActionCreatorPayload>({
    type: "/session/disconnect",
    create: (type, payload) => ({
        type
    }),
    reducer: (action, state) => ({
        // reset state
    })
});
