import {put, select, takeEvery} from "redux-saga/effects";
import {DbRightsState} from "./index";
import {Pagination} from "../../../../core-react/src/modules/api/utils/common/modeling";
import {Right} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/right";

export default function* sagas() {
    yield takeEvery(
        DbRightsState.actions.fetchAll.success.type, function* (action: any) {
            const { apiCallResult: { errored, data, request: { data: { personId } } } } = action;

            // Échec
            if (errored) {
                return;
            }

            const { itemsAvailable: totalItems, itemsOffset: offset, itemsLimit: limit } = data as Pagination<Right>;

            // check si need données en plus
            const rights = yield select(DbRightsState.selectors.allAsArray);
            const ended = rights.length >= totalItems;

            // Fin
            if (ended) {
                return;
            }

            const newOffset = offset + limit;

            yield put(
                 DbRightsState.actions.fetchAll.request({ personId,  offset: `${newOffset}`, limit: `${limit}` })
            )
        }
    );
}
