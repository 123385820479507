import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {LogsStateData} from "../types";

type ActionPayload = {
    type: string,
    args: any[],
};

type ActionCreatorPayload = {
    args: any[],
};

/**
 * Cette action est intercépté dans la saga des logs
 */
export default newAction<ActionPayload, LogsStateData, ActionCreatorPayload>({
    type: "/logs/console-error",
    create: (type, payload) => ({
        type,
        ...payload,
    })
});
