import {City} from "../../../../core-react/src/modules/api/utils/xts/city/modeling/city";
import loadConfiguration from './actions/loadConfiguration';
import {reducerFromActionsObject} from "../../../../core-react/src/utils/redux/reducerBuilder";
import selectors from "./selectors";


export type BusinessCityConfigurationStateModel = {
    configuration?: City,
    loading: boolean,
    loaded: boolean,
}

const initialState: BusinessCityConfigurationStateModel = {
    loading: false,
    loaded: false,
    configuration: undefined,
};

const actions = { loadConfiguration }

export const stateKey = 'configuration.businessCity';

const reducer = reducerFromActionsObject(initialState, actions);

export const BusinessCityConfigurationState = {
    actions, stateKey, reducer, selectors
}
