import {takeEvery, call, put, take} from "redux-saga/effects";
import {AbstractCreateRight, AbstractCreateRightActionPayload} from "./actions/AbstractCreateRight";
import * as blobUtil from "blob-util";
import {currentCity} from "../../../configuration/cities/_city";
import {
    UploadProofActionFailureAction,
    UploadProofActionRequestAction,
    UploadProofActionSuccessAction
} from "./actions/UploadProofAction";
import {CreateRightRequestAction} from "./actions/CreateRightAction";

export default function* () {
    yield takeEvery(AbstractCreateRight.type, function* (_action) {
        const action = _action as any as AbstractCreateRightActionPayload;

        for (let proof of action.proofs) {
            const proofPayload = yield call(mapProvidedProofToProofPayload, proof, action.vehicleId, action.personId);

            yield put(UploadProofActionRequestAction(proofPayload));
            yield take([UploadProofActionSuccessAction.type, UploadProofActionFailureAction.type]);
        }

        yield put(CreateRightRequestAction(action.right));
        // le listener fera le submit
    });
}


const mapProvidedProofToProofPayload =  async (p: { index: number, type: string, label: string, file: File }, vehicleId: any, personId: any) => {
    // strip prefix et garder que le texte en base64 string
    const base64EncodedContent = await blobUtil.blobToBase64String(p.file);

    return {
        type: p.type,
        label: p.label,
        attachement: {
            fileName: p.file.name,
            base64EncodedContent
        },
        vehicleId,
        personId,
        city: currentCity().id,
    }
}


const createProofsPayload = (
    providedProofs: { index: number, type: string, label: string, file: File }[],
    personId: number | string,
    vehicleId: number | string,
) => {
    const mapProvidedProofToProofPayload =  async (p: { index: number, type: string, label: string, file: File }) => {
        // strip prefix et garder que le texte en base64 string
        const base64EncodedContent = await blobUtil.blobToBase64String(p.file);

        return {
            type: p.type,
            label: p.label,
            attachement: {
                fileName: p.file.name,
                base64EncodedContent
            },
            // vehicleId: 7223,
            // personId: 5702,
            vehicleId,
            personId,
            city: currentCity().id,
        }
    }

    return Promise.all(providedProofs.map(mapProvidedProofToProofPayload));
}