/**
 * Created by priano on 25/08/2020 at 10:57.
 */
import {reducerFromActions} from "../../../core-react/src/utils/redux/reducerBuilder";
import {
    AddNewVehicleAction,
    FetchInvoiceRequestAction, FetchInvoiceRequestSuccessAction,
    FetchPersonRequestAction,
    FetchPersonRequestSuccessAction,
    FetchRightsRequestAction, FetchRightsRequestSuccessAction,
    HomeContentArrivedAction, PrintInvoiceRequestAction, PrintInvoiceRequestSuccessAction
} from "./actions";
import {registerListener} from "./listeners";

export type HomeContentState = {
    loading: boolean,
    loaded: boolean,
    vehicles: any[],
    rightsLoading: boolean,
};

const initialState: HomeContentState = {
    loading: false,
    loaded: false,
    vehicles: [],
    rightsLoading: false,
};

export const homeContentStateKey = 'home_content';

export const selectState = (state: any): HomeContentState => {
    return state[homeContentStateKey];
}

export const homeContentReducer = reducerFromActions(
    initialState,
    HomeContentArrivedAction,
    FetchPersonRequestAction,
    FetchPersonRequestSuccessAction,
    FetchRightsRequestAction,
    FetchRightsRequestSuccessAction,
    FetchInvoiceRequestAction,
    FetchInvoiceRequestSuccessAction,
    PrintInvoiceRequestAction,
    PrintInvoiceRequestSuccessAction,
    AddNewVehicleAction,
)

registerListener();
