import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {SessionState} from "../index";

type ActionPayload = {
    type: string;
    email: string;
};

type ActionCreatorPayload = {
    email: string;
};

export const SessionUpdateEmailAction = newAction<ActionPayload, SessionState, ActionCreatorPayload>({
    type: "/session/update-email",
    create: (type, { email }) => ({ type, email }),
    reducer: (action, state) => ({
        ...state,
        account: {
            ...state.account,
            email: action.email,
        }
    })
});
