import {newRequestActions} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbPrepaidAccountStateData} from "../index";
import {orderSearchPrepaidAccount} from "../../../../../core-react/src/modules/api/utils/xts/order/postPrepaidAccountSearch";
import {Pagination} from "../../../../../core-react/src/modules/api/utils/common/modeling";
import {currentCity} from "../../../../../configuration/cities/_city";
import {PrepaidAccount} from "../../../../../core-react/src/modules/api/utils/xts/order/modeling/prepaidAccount";

type RequestActionCreatorPayload = {
    limit?: string,
    offset?: string,
    externalAccount?: string,
    city?: string,
    updatedSince?: string,
};

const actions = newRequestActions<RequestActionCreatorPayload, DbPrepaidAccountStateData, Pagination<PrepaidAccount>>(
    "/db/prepaid-account/fetch-account",
    payload => orderSearchPrepaidAccount({
        ...payload,
        city: currentCity().id
    }),
    (action, state) => ({
        ...state,
        accountLoading: true,
    }),
    (action, state) => {
        const { items } = action.apiCallResult.data;

        return {
            ...state,
            accountLoading: false,
            account: items[0],
        }
    },
    (action, state) => ({
        ...state,
        accountLoading: false,
    })
);

export default actions;
