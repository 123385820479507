import {UiPasswordChangeDemandState, UiPasswordChangeDemandStateData} from "./index";

const select = (state: any): UiPasswordChangeDemandStateData => state[UiPasswordChangeDemandState.stateKey];

export default {
    state: select,
    loading: (state: any) => select(state).loading,
    done: (state: any) => select(state).done,
    emailNotFound: (state: any) => select(state).emailNotFound,
};
