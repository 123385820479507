import {newAction} from "../../../../utils/redux/actionBuilder";
import {CoreSessionState} from "../state";

type ActionPayload = { city: string };
type CreationPayload = { city: string };

export const CoreSessionCitySelection = newAction<ActionPayload, CoreSessionState, CreationPayload>({
    type: "/core/session/city-selection",
    create: (type, {city}) => ({
        type,
        city
    }),
    reducer: (action, state) => state
        ? { ...state, city: action.city }
        : null
});
