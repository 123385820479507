import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {FranceConnectConfirmationStateModel} from "../index";
import {FranceConnectData} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/france-connect";
import getFranceConnect from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/getFranceConnect";

type RequestActionCreatorPayload = {
    city: string,
};

export default newRequestActions<RequestActionCreatorPayload, FranceConnectConfirmationStateModel, FranceConnectData>(
    "/france-connect/check",
    payload => getFranceConnect(payload.city),
    (action, state) => ({
        ...state,
    }),
    (action, state) => ({
        ...state,
        franceConnect: action.apiCallResult.data,
        franceConnectChecked: true,
    }),
    (action, state) => ({
        ...state,
        franceConnectChecked: true,
    })
);
