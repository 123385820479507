import {newAction} from "../../../../../core-react/src/utils/redux/actionBuilder";
import {DbVehicleDriversStateData} from "../index";

type ActionPayload = {

}

type ActionCreatorPayload = {

}

export default newAction<ActionPayload, DbVehicleDriversStateData, ActionCreatorPayload>({
        type: "db/vehicleDriver/clean-last-created-driver",
        create: (type) => ({type}),

        reducer: (action, state: any) => {

            return {
                ...state,
                lastCreatedDriver: undefined,
            }
        }
    }
)
