/**
 * Created by priano on 07/10/2020 at 09:19.
 */
import {select, put, takeEvery, takeLatest, all, take} from 'redux-saga/effects'
import {UiProfileFormState} from "./index";
import {ProfileFormData, profileFormSlice} from "../../../modules/profile-form";
import {
    SessionEndCustomerConnectionProcessFailureAction,
    SessionEndCustomerConnectionProcessRequestAction,
    SessionEndCustomerConnectionProcessSuccessAction
} from "../../../modules/session/actions/endCustomerConnectionProcess";
import {CoreNotify} from "../../../../core-react/src/modules/notifications/redux/state";
import {CoreNavigationDemand} from "../../../../core-react/src/modules/routing/redux/navigation/actions";
import {routes} from "../../../../configuration/routes";
import {getTranslation} from "../../../../translations/useTranslation";
import {CustomerContractsState} from "../../configuration/customerContracts";
import {Address} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/address";
const { changed, setError } =  profileFormSlice.actions;

export default function* sagas() {
    yield takeLatest(
        UiProfileFormState.actions.autocompleteHints.success.type,
        function* (action: any) {
            const { data } = action.apiCallResult;

            yield put({
                type: UiProfileFormState.actions.fillAddressSuggestions.type,
                suggestions: data,
            });
        }
    );

    yield takeEvery(
        UiProfileFormState.actions.setAddressDetails.type,
        function* (action: any) {
            const country = yield select(CustomerContractsState.selectors.country);
            const { dto } = yield select(profileFormSlice.select);
            const isBillingAddressSameAsHomeAddress = dto.billingAddressIsSameAsHomeAddress;
            const shallRequireLocalizedAddress = yield select(CustomerContractsState.selectors.shallRequireLocalizedAddress);

            // Si shallRequireLocalizedAddress false on autorise la saisi libre de l'adresse, sinon non
            if (shallRequireLocalizedAddress) {
                return;
            }

            yield put(
                changed.create({
                    field: action.fieldName,
                    value: {
                        ...(dto[action.fieldName]?.id && {id: dto[action.fieldName].id}),
                        street: action.address,
                        country: country.toUpperCase(),
                        locality: ""
                    }
                })
            );

            if (isBillingAddressSameAsHomeAddress && action.fieldName == 'homeAddress') {
                yield put(
                    changed.create({
                        field: 'billingAddressText',
                        value: action.address
                    })
                );

                yield put(
                    changed.create({
                        field: 'billingAddress',
                        value: {
                            ...(dto.billingAddress?.id && {id: dto.billingAddress.id}),
                            street: action.address,
                            country: country.toUpperCase(),
                            locality: ""
                        }
                    })
                );
            }
        }
    );

    yield takeEvery(
        UiProfileFormState.actions.getAddressDetail.success.type,
        function* (action: any) {
            const {data, meta} = action.apiCallResult;

            const { dto } = profileFormSlice.select(yield select());

            const isBillingAddressSameAsHomeAddress = dto.billingAddressIsSameAsHomeAddress;

            yield put({
                type: changed.type,
                field: meta.fieldName,
                value: {
                    ...dto[meta.fieldName],
                    ...data,
                }
            });

            if (isBillingAddressSameAsHomeAddress && meta.fieldName == 'homeAddress') {
                yield put({
                    type: changed.type,
                    field: 'billingAddressText', value: meta.name
                });

                yield put({
                    type: changed.type,
                    field: 'billingAddress', value: {
                        ...dto['billingAddress'],
                        ...data
                    }
                });
            }
        }
    )

    yield takeEvery(
        UiProfileFormState.actions.fillBillingAddress.type,
        function* () {

            const { dto } = yield select(profileFormSlice.select);

            const isBillingAddressSameAsHomeAddress = dto.billingAddressIsSameAsHomeAddress;

            // Si isBillingAddressSameAsHomeAddress true on rempli billingAddressText avec l'adresse de homeAddress
            if (isBillingAddressSameAsHomeAddress) {
                if (dto.homeAddressText) {
                    yield put(
                        changed.create({
                            field: 'billingAddressText',
                            value: dto.homeAddressText
                        })
                    );
                }

                // On affect à billingAddress les infos de homeAddress en gardant l'id de billingAddress
                if (dto.homeAddressDetails) {
                    yield put(
                        changed.create({
                            field: 'billingAddressDetails',
                            value: dto.homeAddressDetails,
                        })
                    );
                }

                if (dto.homeAddress) {
                    yield put({
                        type: changed.type,
                        field: 'billingAddress', value: {
                            ...dto.homeAddress,
                            id: dto.billingAddress && dto.billingAddress.id,
                        }
                    });
                }
            }
        }
    )

    yield takeEvery(
        UiProfileFormState.actions.getAddressDetail.failure.type,
        function* (action: any) {
            const {data, meta} = action.apiCallResult;
            const translations = getTranslation();

            // Si error lors du getAddressDetail action on set manuellement la validity du fieldName pour le signaler au front
            // dans mon cas je met invalid address car si pas de code postal par ex. dans l'adresse le back renvoi un 404
            // il ne faut donc pas permettre le save de ce genre d'address
            yield put({
                type: setError.type,
                field: meta.fieldName,
                error: translations.invalidAddressEnsureThePresenceOfTheZipCode,
            });
        }
    )

    yield takeLatest(
        profileFormSlice.actions.submitSuccess.type,
        function* (action: any) {
            const translations = getTranslation();
            const state = yield select();
            const form = profileFormSlice.select(state);
            const context = form.context;
            const dto: ProfileFormData = form.dto;

            const personId = action.apiCallResult.data.id;

            // Formattage des payloads des addresses
            const homeAddressPayload = {
                ...dto.homeAddress,
                addressDetails: dto.homeAddressDetails,
                locality: dto.homeAddress.locality.toUpperCase(),
                usage: 'DEFAULT',
                personId,
            };

            const billingAddressPayload = {
                ...dto.billingAddress,
                addressDetails: dto.billingAddressDetails,
                locality: dto.billingAddress.locality.toUpperCase(),
                usage: 'INVOICE',
                personId,
            };

            let secondaryAddressPayload;

            if (dto.secondaryAddress) {
                secondaryAddressPayload = {
                    ...dto.secondaryAddress,
                    addressDetails: dto.secondaryAddressDetails,
                    locality: dto.secondaryAddress.locality.toUpperCase(),
                    usage: 'SECONDARY',
                    personId,
                };
            }

            // builds des effets à utiliser ici
            const effects = [];

            // Get la personne courante uniquement lors d'une création pour recharger les addresses
            if (context.on === 'new') {
                yield put(
                    SessionEndCustomerConnectionProcessRequestAction({
                        id: personId,
                    })
                )

                // Nous permet d'éviter d'être redirigé vers la PublicHome après avoir submit le form pour renseigner ses infos
                // Comme ça on est direct redirect vers PrivateHome après la fin de l'inscription et plus le double redirect
                const result = yield take([
                    SessionEndCustomerConnectionProcessSuccessAction.type,
                    SessionEndCustomerConnectionProcessFailureAction.type
                ]);

                if (result.apiCallResult.errored) {
                    console.error('Creating person failed');
                    return;
                }
            }

            // La gestion des edit address se fait ici car la création des addresses profileForm.onSubmit
            if (context.on === 'edit') {
                effects.push(
                    put(createAddressRequest(homeAddressPayload)),
                    put(createAddressRequest(billingAddressPayload))
                )

                if (dto.secondaryAddress) {
                    effects.push(
                        put(createAddressRequest(secondaryAddressPayload))
                    );
                }
            }

            effects.push(
                put({ ...CoreNotify.success(translations.changesSaved) }),
            );

            if (context.on === 'new') {
                effects.push(
                    put({ ...CoreNavigationDemand({ route: routes().PrivateHome }) })
                );
            }

            yield all(effects);
        }
    )

    yield takeLatest(
        profileFormSlice.actions.submitFailure.type,
        function* () {
            // yield put({ ...CoreNotify.failure("An error happened") })
        }
    )
}

/**
 * Utilitaire pour créer une request pour les addresses
 * @param address
 */
function createAddressRequest(address: Address & { city: string, personId: string }) {
    // Sélection de l'action à appliquer aux adresses en fonction du contexte
    // si pas de homeAddress.id il y a de forte chance qu'il faille faire une création
    const addressActionToCall = (!address.id) ? 'createAddress' : 'updateAddress';

    // Une fois l'action trouvée, on get la property requête qui sera utilisée avec une payload pour créer l'action
    return UiProfileFormState.actions[addressActionToCall].request(address as any);
}
