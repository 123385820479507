import {ApiCall} from "../../../index";
import {coreConfiguration} from "../../../../../internal/coreConfiguration";
import {apiPaginationToPagination, Pagination} from "../../common/modeling";
import {apiVehicleDriverToDriver, VehicleDriver} from "./modeling/driver";

const path = "/vehicle/driver/search";

type Payload = {
    city: string,
    limit?: number,
    offset?: number,
    personId?: number | string,
    id?: number | string,
    updatedSince?: string,
    namesContaining?: string,
};

export const customerContractsPostVehicleDriverSearch = (payload: Payload): ApiCall<Pagination<VehicleDriver>> => ({
    url: coreConfiguration.api.xts.customerContractsUrl + path,
    method: 'POST',
    body: payload,
    formater: apiPaginationToPagination(apiVehicleDriverToDriver),
    tokenRepository: 'xts',
});
