import {newAction} from "../../../../core-react/src/utils/redux/actionBuilder";
import {FranceConnectConfirmationStateModel} from "../index";

type ActionPayload = {
    type: string,
};

type ActionCreatorPayload = void;

export default newAction<ActionPayload, FranceConnectConfirmationStateModel, ActionCreatorPayload>({
    type: "/france-connect/confirmation/undefined-parameters",
    create: (type, payload) => ({
        type
    }),
    reducer: (action, state) => ({
        ...state,
        error: "Undefined France Connect parameters"
    })
});
