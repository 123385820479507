import {RightCreationFormState} from "../index";
import {newRequestActions} from "../../../../core-react/src/utils/redux/actionBuilder";
import {businesscustomerCityProfilesSearch} from "../../../../core-react/src/modules/api/utils/xts/city/postProfileSearch";

type RequestActionCreatorPayload = {
    city: string,
    parentRightId?: string,
    wishedStartDate?: string,
}

const actions = newRequestActions<RequestActionCreatorPayload, RightCreationFormState, any>(
    "/fetch_available_profiles",
    businesscustomerCityProfilesSearch,
    (action, state) => ({
        ...state,
        options: {
            ...state.options,
            profilesLoading: true
        }
    }),
    (action, state) => ({
        ...state,
        options: {
            ...state.options,
            profiles: action.apiCallResult.data.items,
            profilesLoading: false,
            profilesLoaded: true,
        }
    }),
    (action, state) => state,
);

export const FetchProfilesRequestAction = actions.request;
export const FetchProfilesSuccessAction = actions.success;
export const FetchProfilesFailureAction = actions.failure;
