/**
 * Created by priano on 06/10/2020 at 16:45.
 */
import {useDispatch, useSelector} from "react-redux";
import {UiProfileFormState} from "./index";
import {currentCity} from "../../../../configuration/cities/_city";
import {AddressSuggestion} from "../../../../core-react/src/modules/api/utils/xts/customer-contracts/modeling/address-suggestion";
import {profileFormSlice, ProfileFormState} from "../../../modules/profile-form";
import {BusinessCityConfigurationState} from "../../configuration/businessCustomer";

const useAddressSuggestions = () => useSelector(UiProfileFormState.selectors.addressSuggestions);

export default {
    useAddressSuggestions,

    useAutocompleteHints() {
        const dispatch = useDispatch();

        return (address: string | any) => {
            dispatch(
                UiProfileFormState.actions.autocompleteHints.request({
                    city: currentCity().id,
                    address: address
                })
            )
        }
    },

    useGetAddressDetail() {
        const dispatch = useDispatch();

        return ({name, origin, token}: AddressSuggestion, fieldName: string) => {
            dispatch(
                UiProfileFormState.actions.getAddressDetail.request({
                    city: currentCity().id,
                    name,
                    origin,
                    token,
                    fieldName,
                })
            )
        }
    },

    useSetAddressDetails() {
        const dispatch = useDispatch();

        return (address: string, fieldName: string) => {
            dispatch(UiProfileFormState.actions.setAddressDetails({
                address,
                fieldName,
            }))
        }

    },

    useHasError(fieldName: string) {
        const form: ProfileFormState = useSelector(profileFormSlice.select);
        const hasError: boolean = ((form.status.fields as any)[fieldName] && (form.status.fields as any)[fieldName].errors.length > 0);
        const errors = hasError && (form.status.fields as any)[fieldName].errors;

        return [ hasError, errors ];
    },

    useHasErrors(fieldNames: string[]) {
        const form: ProfileFormState = useSelector(profileFormSlice.select);

        return fieldNames
            .map(
                (fieldName: string) => {
                    const hasError: boolean = ((form.status.fields as any)[fieldName] && (form.status.fields as any)[fieldName].errors.length > 0);
                    const errors = hasError && (form.status.fields as any)[fieldName].errors;

                    return { fieldName, hasError, errors };
                }
            )
            .filter(({ hasError }) => hasError)
        ;
    },

    useLanguages() {
        const configLanguages = useSelector(BusinessCityConfigurationState.selectors.languages) || [];

        const languagesMap: any = {
            en: {
                label: 'English',
                value: 'en'
            },
            fr: {
                label: 'Français',
                value: 'fr'
            },
            en_AU: {
                label: 'English',
                value: 'en_AU'
            },
            de: {
                label: 'Deutsch',
                value: 'de'
            },
            nl: {
                label: 'Dutch - Belgium',
                value: 'nl'
            },
        };

        return configLanguages.map((lang) => languagesMap[lang] || {
            label: lang,
            value: lang
        })
    }
}
